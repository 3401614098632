import { handleApi } from "../helpers/utility";
import axios from 'axios';
import axiosClientSearch from "../helpers/axiosClientSearch";

 const BASE = 'find/';

export async function searchData(data , limit = 10, offset = 0) {
  return handleApi(axiosClientSearch.get(`${BASE}assets/?q=${data}&limit=${limit}&offset=${offset}`));
}

export async function getTopCategory() {
  return handleApi(axiosClientSearch.get(`${BASE}assets/top_categories/`));
}

export async function googleResults(data, limit = 3, offset = 0){
  return handleApi(axiosClientSearch.get(`${BASE}assets/google/?q=${data}&limit=${limit}&offset=${offset}`));
}

export async function bingResults(data , limit = 3, offset = 0) {
  return handleApi(axiosClientSearch.get(`${BASE}assets/bing/?q=${data}&limit=${limit}&offset=${offset}`));
}

export async function createComment() {
  return handleApi(axios.post(''));
}

export async function getComment() {
  return handleApi(axios.get(''));
}