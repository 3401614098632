import React, { useEffect, useState, useCallback  } from 'react';
import { Container, Pagination, DropdownButton, InputGroup, Card, Modal, FormControl, Dropdown, Form, Button, Navbar, Tooltip, OverlayTrigger, Image, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../../assets/images/logo.png";
// import CreatableOption from '../../shared/select/CreatableOption';
import * as bookmarkService from '../../services/bookmark';
import * as searchService from '../../services/search';
import * as alertAction from '../../actions/alert';
import * as loaderAction from '../../actions/loader'
import DropDownTree from '../../shared/DropDownTree';
import { useDispatch } from 'react-redux';
import { useQuery, history, checkSearches, Paginate, Storage } from '../../helpers';
import { Link } from 'react-router-dom';
import RenewMembershipPopup from '../../shared/RenewMembershipPopup';
import GoogleSearch from './GoogleSearch';
import BingSearch from './BingSearch';
import CommentsPopUp from './CommentsPopUp';
import TooltipHover from '../../shared/TooltipHover';
import FolderTreeComponent from './FolderTreeComponent';

const Search = () => {

   const dispatch = useDispatch();
   const query = useQuery();
   const [isVisible, setIsVisible] = useState(false);
   const [limit, setLimit] = useState(10);
   const [offset, setOffset] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [paginationData, setPaginationData] = useState({});
   const [ assetData , setAssetData] = useState([]);
   const [search, setSearch] = useState('');
   const [topCategory, setTopCategory] = useState([]);
   const [searchArr, setSearchArr] = useState([]);
   const [memberFolder, setMemberFolder] = useState('');
   const [title, setTitle] = useState('');
   const [startIndex , setStartIndex] = useState('');
   const [height , setHeight ] = useState(0);
   const [noResult , setNoResult] = useState('');
   const [openPopUp , setOpenPopUp] = useState(false);
   const [selectedItem, setSelectedItem] = useState('');
   const [showFolderTree, setShowFolderTree] = useState(false);
   const [obj, setObj] = useState({});
   const [selectedId, setSelectedId] = useState(0);

   useEffect(() => {
      if(searchArr && searchArr.length > 0){
         const arr = searchArr.map((item) => {
            return({[item._source.id]: {show: false, id: item._source.id}});
         });
         setObj(...arr);
      }
   }, [searchArr]);

   // const [paginationDataGoogle, setPaginationDataGoogle] = useState({});
   // const [noResultGoogle , setNoResultGoogle] = useState('');
   const [searchArrGoogle, setSearchArrGoogle] = useState([]);

   const [searchArrBing, setSearchArrBing] = useState([]);

   const searchData = query.get('q');

   const ads = [' 300x250 - Advertisement Banner', ' 300x250 - Advertisement Banner', ' 300x250 - Advertisement Banner'];

   const getHeight = useCallback(() => {
      setTimeout(() => {
         let outerDiv = document.getElementById('outer-div');
         if(outerDiv !== null) setHeight(outerDiv.clientHeight);
      },100)
      
   },[])
   
   const getAllData = useCallback(() => {
      if(Storage.getSession() !== null && Storage.getSession().id){
        bookmarkService.getAllData(Storage.getSession().id).then(([response , error]) => {
          if(response){
            setAssetData(response);
          }else if(error){
            dispatch(alertAction.error(error.response));
          }
        })
      }
    },[dispatch])

    useEffect(() => {
      getAllData();
    },[getAllData])

    useEffect(() => {
      if(paginationData !== undefined && paginationData.startIndex !== undefined){
         setStartIndex(paginationData.startIndex);
      }
    },[paginationData])


   const removeGeneral = useCallback((response) => {
      const arr = [] , result = [];
      response.filter(obj => arr.push(obj.split('-')));
      arr.filter(obj => obj[0] !== 'General ' ? result.push(obj[0].trim()) : result.push(obj[1].trim()));
      return result;
   }, [])

   useEffect(() => {
      searchService.getTopCategory().then(([response, error]) => {
         if (response) {
            setTopCategory(removeGeneral(response));
         } else if (error) {
            dispatch(alertAction.error(error.response));
         }
      })
   }, [dispatch, removeGeneral])


   const saveAsset = useCallback((value) => {
     
      if (memberFolder !== '') {
   
         const data = {
            title: title,
            asset : value , 
            member_folder : memberFolder
         }
   
         bookmarkService.addAsset(data).then(([response, error]) => {
            if (response) {
               dispatch(alertAction.success('Added link to My assets'));
               handleToggle(selectedId);
            } else if (error) {
               dispatch(alertAction.error(error.response));
            }
         })
      } else {
         if (memberFolder === '') dispatch(alertAction.error('Please Select the folder.'));
      }
   }, [memberFolder , dispatch , title])

   const dropDownValue = useCallback((e) => {
      setMemberFolder(Number(e.value));
   }, [])


   const getData = useCallback((data) => {
      dispatch(loaderAction.loader(true));
      const values = checkSearches(data);
      setIsVisible(!values);
      if(values) {
         searchService.searchData(data, limit, offset).then(([response, error]) => {
            dispatch(loaderAction.loader(false));
            setNoResult('');
            if (response.hits && response.hits.hits.length > 0) {
               window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
               setSearchArr(response.hits.hits);
               setPaginationData(Paginate(response.hits.total.value, currentPage, limit));
            }else if(response.membership_plan === false){
               setIsVisible(!response.membership_plan);
               Storage.removeSearchCount();
            }else if(response.hits.hits.length === 0){
               setNoResult('No results found. Please broaden your search criteria.');
               setSearchArr([]);
               setPaginationData({})
            } else if (error) {
               dispatch(alertAction.error(error.response));
            }
            
         })
           const extLimit = 3;
        // fetching google search results 
         searchService.googleResults(data,extLimit, offset).then(([response, error]) => {
            dispatch(loaderAction.loader(false));
            setNoResult('');
            if (response && response.items && response.items.length > 0) {
               setSearchArrGoogle(response.items);
               // setPaginationData(Paginate(response.items.total.value, currentPage));
            }else if(response && response.items && response.items.length === 0){
               setNoResult('No results found. Please broaden your search criteria.');
               setSearchArrGoogle([]);
               // setPaginationData({})
            } else if (error) {
               dispatch(alertAction.error(error.response));
            }
            
          });
   
          // fetching bing search results
          searchService.bingResults(data, extLimit, offset).then(([response, error]) => {
            if (response.value && response.value.length > 0) {
               setSearchArrBing(response.value);
               // setPaginationData(Paginate(response.items.total.value, currentPage));
            }else if(response.value && response.value.length === 0){
               setNoResult('No results found. Please broaden your search criteria.');
               searchArrBing([]);
               // setPaginationData({})
            } else if (error) {
               dispatch(alertAction.error(error.response));
            }
          });
      }
      // fetching qrat search results
      

   }, [dispatch , limit, offset , currentPage ])

   const renderNoResultsBlock = () => {
      return (
         <div>
                        <Row >
                        <Col md={2} xs={2} className="text-center">
                           <span className="d-block p-2 mt-4 score-box bg-secondary">
                              <p className="s-number text-white"></p>
                           </span>
                        </Col>
                        <Col md={10} xs={10}>
                           <h5 className='py-4'>{noResult}</h5>
                        </Col>
                        </Row>
                     </div>
      )
   }

   const closePopUp = useCallback(() => {
      setOpenPopUp(false);
    })

   const renderPopUp = (selectedResult) => {
      setSelectedItem(selectedResult);
      setOpenPopUp(true);
   }
   
   const searchDataByEnter = useCallback((e) => {
      if (e.which === 13 || e.key === 'Enter') {
         setCurrentPage(1);
         setOffset(0);
         history.push(`/search?q=${search}`)
      }
   }, [search])

   const searchOnClick = useCallback((e) => {
      setCurrentPage(1);
         setOffset(0);
         history.push(`/search?q=${search}`)
   }, [search])


   useEffect(() => {
      if (searchData !== '' && searchData !== null) {
         setSearch(searchData);
         getData(searchData);
         
      }else {
         history.push('/home');
      }
      return () => {
         setSearch('');
      }
   }, [searchData , getData , offset ]);

   const gotoPage = useCallback((page, step) => {
      if (step === 'FIRST') {
         setCurrentPage(page);
         setLimit(10);
         setOffset(0)
      } else if (step === 'PREV') {
         setCurrentPage(page);
         setLimit(10);
         setOffset(offset - limit);
      } else if (step === 'NEXT') {
         setCurrentPage(page);
         setLimit(10);
         setOffset(offset + limit);
      } else if(step === 'NORMAL'){
         setCurrentPage(page);
         setLimit(10);
         setOffset(limit * (page - 1));
         if(page === 1) setOffset(0);
      }
   }, [limit, offset])

   const renderTooltip = useCallback((props) => {
      return(
      <Tooltip {...props}>
          Add to Bookmark
      </Tooltip>
      );
      
   })

   const handleToggle = useCallback((id) => {
      
      if(obj){
         setSelectedId(id);
         const obj1 = Object.values(obj).find((e) => e.show === true);
         if(obj1){
            if(obj1.id === id){
               setObj({...obj, [id]: {show: false, id} })
            }else if(id !== obj1.id){
               setObj({...obj, [id]: {show: true, id}, [obj1.id]: {show: false, id: obj1.id} })
            }
         }else{
            setObj({...obj, [id]: {show: true, id} })
         }
      }
      
   }, [obj])

   const renderModal = useCallback((item) => {
      return(
         <Modal show={obj[item._source.id].show} onHide={() => handleToggle(item._source.id)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title-vcenter">
                  Add To Bookmark
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form>
                  <Row>
                     <Col md={12}>
                        <Form.Group>
                           <Form.Label htmlFor="TextInput">Asset link</Form.Label>
                           <Form.Control id="TextInput" name='asset' readOnly onChange={() => null} value={item._source.url} />
                        </Form.Group>
                        <Form.Group>
                           <Form.Label htmlFor="titleInput">Title</Form.Label>
                           <Form.Control id="titleInput" name='title' onChange={(e) => setTitle(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                           <Form.Label htmlFor="Select">Folder <TooltipHover type='icon' placement='right' iconClass='text-info nav-icons text-dark' icon='plus' iconSize="sm" message={'Add folder'} handleEvent={() => setShowFolderTree(true)} /></Form.Label>
                           {showFolderTree ? <FolderTreeComponent folderData={assetData} fetchData={() => getAllData()} show={showFolderTree} hide={() => setShowFolderTree(false)} /> : ''}
                           <DropDownTree getValues={(e) => dropDownValue(e)} data={assetData} />
                        </Form.Group>
                     </Col>
                     {/* <Col md={12} className="text-right">
                        <Button drop='true' variant="primary" className="mr-2" size="sm" onClick={(e) => saveAsset(item._source.id)} >Done</Button>
                     </Col> */}
                  </Row>
               </Form>
            </Modal.Body>
            <Modal.Footer>
               <Button drop='true' variant="primary" className="mr-2" size="sm" onClick={(e) => saveAsset(item._source.id)} >Done</Button>
            </Modal.Footer>
         </Modal>
      )
   })

   useEffect(() => {
      const item = searchArr.find((e) => e._source.id === selectedId);
      if(item){
         renderModal(item);
      } 
   }, [obj])

   return (

      <>
      
         <RenewMembershipPopup show={isVisible} />
         <Container>
         
            <Row  className="my-5 position-relative">
               {getHeight()}
               <span className="border-left-tool position-absolute d-block" style={noResult === '' ? {height : height - 85 +'px'} : {height : 220+'px'}}></span>
               <Col id="outer-div" md={8}   style={{height:'100%'}}>
                  <Row>
                     <Col md={2} xs={2} className="text-center">
                        <Navbar.Brand to="/home" as={Link}>
                           <Image src={logo} width="90px" rounded />
                        </Navbar.Brand>
                     </Col>
                     <Col md={10} xs={10} className="mb-1 px-0" >
                        <InputGroup >
                           <FormControl
                              className="border-right-0"
                              size="lg"
                              placeholder="Search ..."
                              aria-label="search"
                              aria-describedby="basic-addon1"
                              name="search"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              onKeyPressCapture={(e) => searchDataByEnter(e)}
                           />
                           <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1" className="bg-white border-left-0" onClick={() => searchOnClick()}>
                                 <FontAwesomeIcon className="text-info nav-icons" icon="search" size="sm" />
                              </InputGroup.Text>
                           </InputGroup.Prepend>
                        </InputGroup>
                  
                        <Row className="mx-0">
                           <Col md={12} className="pt-1 px-0 d-flex flex-wrap">
                              {topCategory.map((item, index) => index < 5 ? (
                                 <p className="text-secondary d-inline-block pr-2" key={'catagories' + index}>
                                    <Link className="text-secondary" to={`/search?q=${item}`}>{item}</Link>
                                 </p>
                              ) : '')}
                              {topCategory.length > 5 ?
                                 <>
                                    <DropdownButton className='more-button-search pt-1' title="More">
                                       {topCategory.map((item, index) => index >= 5 ? (
                                          <Dropdown.Item to={`/search?q=${item}`} as={Link} key={'cato' + index}>{item}</Dropdown.Item>
                                       ) : '')}
                                    </DropdownButton>
                                 </> : ''}

                           </Col>
                        </Row>
                     </Col>
                  </Row>

                  {searchArr.length > 0 ? searchArr.map((item, index) => (
                     <Row className="mb-2 Q-serach-data" key={'seachData' + index}>
                          <Col md={2} xs={2} sm={2} className="text-center">
                           <span className="d-block p-2 mt-4 score-box bg-secondary">
                              <p className="s-number text-white">{startIndex !== undefined ?   startIndex + index  + 1 : ''}</p>
                           </span>
                          </Col>                        
                          <Col md={10} xs={10} sm={10} className="text-left px-0">
                           <Card>
                              <Card.Header className="border-bottom-0 mb-0 pb-0 bg-white">

                               {Storage.getSession() !== null ? 
                                 <>
                                  <OverlayTrigger
                                       placement="right"
                                       delay={{ show: 250, hide: 400 }}
                                       overlay={renderTooltip}
                                    >
                                       <FontAwesomeIcon className="text-info nav-icons text-warning bookmark-box-btn position-absolute" icon='bookmark' size="sm" onClick={() => handleToggle(item._source.id)}/> 
                                    </OverlayTrigger>
                                    {obj[item._source.id] && obj[item._source.id].show ? renderModal(item) : ''}
                                 </>
                                 : ''}
                                 <p className="mb-0  d-block text-truncate w-95"><a className="text-info " rel="noopener noreferrer" target="_blank" href={item._source.url}>{item._source.url}</a></p>
                                 <h5 className="mb-0"><a href={item.link} rel="noopener noreferrer" target="_blank">{item._source.specific_topic}</a></h5>
                              </Card.Header>
                              {/* <Card.Body className="py-2">
                                 <Card.Text>
                                    {item.description}
                                 </Card.Text>
                              </Card.Body> */}
                              <Card.Footer className="text-muted bg-white p-1 border-top-0">
                              {Storage.getSession() !== null ? <span className="chat-box-btn position-absolute">
                              {/* <FontAwesomeIcon className="text-info nav-icons text-dark ml-3" icon='comment-dots' size="lg" onClick={() => renderPopUp(item)}/> */}
                              <TooltipHover type='icon' placement='right' iconClass='text-info nav-icons text-dark ml-3' icon='comment-dots' iconSize="lg" message={'Add comment for an asset'} handleEvent={() => renderPopUp(item)} />
                              </span> : ''}
                              </Card.Footer>
                           </Card> 
                        </Col>
                        {/* <Col md={1} xs={10} className="mb-1 px-0" >
                        <FontAwesomeIcon className="text-info nav-icons text-dark ml-3" icon='comment-dots' size="lg" onClick={() => renderPopUp(item)}/>
                        </Col> */}
                     </Row>
                  )) : renderNoResultsBlock()
                  }
                  <GoogleSearch searchArrGoogle={searchArrGoogle} noResult={noResult} renderNoResultsBlock={renderNoResultsBlock()}/>
                  <BingSearch searchArrBing={searchArrBing} renderNoResultsBlock={renderNoResultsBlock()}/>
                  
                  <Row className="justify-content-center">
                     <Col md={8} xs={8}>
                        {paginationData.totalItems !== undefined && paginationData.totalItems > 10 ?
                           <>
                              <Pagination>
                                 <Pagination.First disabled={currentPage === paginationData.startPage} onClick={() => gotoPage(paginationData.startPage, 'FIRST')} />
                                 <Pagination.Prev disabled={currentPage === paginationData.startPage} onClick={() => gotoPage(paginationData.currentPage - 1, 'PREV')} />
                                 {paginationData.pages.map((obj, index) => (
                                    <Pagination.Item active={currentPage === obj} key={'pages' + index} onClick={() => gotoPage(obj, 'NORMAL')}>{obj}</Pagination.Item>
                                 ))}
                                 <Pagination.Next disabled={currentPage === paginationData.endPage} onClick={() => gotoPage(paginationData.currentPage + 1, 'NEXT')} />
                                 <Pagination.Last disabled={currentPage === paginationData.endPage} onClick={() => gotoPage(paginationData.endPage, 'NORMAL')} />
                              </Pagination>
                           </>
                           : ''}
                     </Col>
                  </Row>
               </Col>
               <Col md={4}>
                  <Row className="no-gutter">
                     {ads.map((item, index) => (
                        <Col md={12} className="text-center mb-2" key={'ads' + index}>
                           <span className="qrataads-300-250 bg-light">
                              {item}
                           </span>
                        </Col>
                     ))}
                  </Row>
               </Col>
            </Row>
            {openPopUp ? <CommentsPopUp openPopUp={openPopUp} data={selectedItem} hide={closePopUp}/> : ''}
         </Container>
      </>
   )
}
export default Search;