import React, { useEffect, useState,  useRef } from "react";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import FormBlock from "./FormBlock";
import { useBoolean } from "../hooks/basic";
import { convertFullDate } from "../helpers";
import AccordionForm from "./AccordionForm";

const ModalForm = ({ header, title, show, onHide, data, fields, comment, button }) => {

  const [formState, setFormState] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [validated, setValidated] = useBoolean(false);
  const prevState = useRef(data);

  useEffect(() => {
    if (fields) setFormFields(fields);
    setFormState(data);

  }, [data, fields]);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.name]: e.value
    });
  }


  const saveData = (event) => {
    setValidated(false);
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      onHide(formState);
    }
  }

  const closeModal = () => {
    setFormState(prevState.current);
    onHide(false);
  }

  const CommentModal = ({ commentList }) => {
    return (
      <>
        {commentList.map((item, index) => (
          <Row key={index} className="no-gutters border border-top-0 border-right-0 border-left-0 mb-2 py-3">
            <Col md={12}>
              <Row className="no-gutters px-2">
                <Col md={12} className="font-weight-bold">
                  {item.first_name + ' ' + item.last_name}
                </Col>
                <Col md={12}>
                  {item.comment}
                </Col>
                <Col md={12}>
                  <small> {convertFullDate(item.created_on)}</small>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    )
  }

  const commentJSON = [
    { name: 'Comments', open: false, type: 'component', component: <CommentModal commentList={comment ? comment : []} /> }
  ]

  return (
    <Modal show={show} onHide={closeModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Form noValidate validated={validated} onSubmit={saveData}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {header ? <h5>{header.name}</h5> : null}
          <FormBlock fields={formFields} getData={(e) => handleChange(e)} formData={formState} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>Cancel</Button>
          <Button variant="primary" type="submit" >{button !== '' && button !== undefined ? button : 'Save'}</Button>
        </Modal.Footer>
        {comment && comment.length > 0 ? <AccordionForm data={commentJSON} /> : ''}
      </Form>
    </Modal>
  );
}

export default ModalForm;