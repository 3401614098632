import React , {useEffect , useState ,  useCallback} from 'react';
import * as paymentService from '../../services/payment';
import * as loaderActions from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import { convertArrayToObject, history, Storage } from '../../helpers';
import { useDispatch } from 'react-redux';

const Processing = () => {

  const dispatch = useDispatch();
  const [pageCount , setPageCount] = useState(0);

  const paypalConfirmation = useCallback(() => {
    dispatch(loaderActions.loader(true));
    const data = {
      token : Storage.getMembership()?.token,
      order_id : Storage.getMembership()?.order_id
    }
    paymentService.paymentConfirmation(data).then(([response , error]) => {
      if(response) {
         setPageCount(1);
         const result = convertArrayToObject(response);
         const transactionId = result.purchase_units ? result.purchase_units[0]?.payments?.captures[0]?.id : null;
         if(result.status === 'COMPLETED') history.push('/payment-confirm/transactionId='+transactionId);
         Storage.setPaypalResp(result)
         if(result.name === "UNPROCESSABLE_ENTITY") history.push('/payment-cancel?order_id='+ data.order_id);
      }else if(error){
        history.push('/payment-cancel?order_id='+ data.order_id)
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  },[dispatch])

  useEffect(() => {
    if(pageCount === 0){
      paypalConfirmation();
    }
  },[paypalConfirmation , pageCount]);

  return (
      <>
          <div className='mx-auto p-5 text-center'>
            <h1>Please Wait...</h1>
            <span>Don't click on back button or refresh .</span>
          </div>
      </>
  )
}
export default Processing;