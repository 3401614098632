import {  Loader } from "../helpers/actionTypes";

const initState = {
  loading : false
};

export function loader(state = initState, action) {
  
  switch (action.type) {
    case Loader.LOADER:
      return {
        ...state,
        loading : action.loading
      };
    default:
      return state
  }
}