import React, { useEffect, useState } from 'react';
import { Button, Card, Col, ListGroup, Row, Jumbotron } from 'react-bootstrap';
import { convertArrayToObject } from '../../helpers';
import * as memberService from '../../services/member';
import { useDispatch } from 'react-redux';
import * as loaderActions from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import { Storage } from '../../helpers/storage';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as paymentService from '../../services/payment';
// import { PaymentStatus } from '../../helpers/constants';

const PaymentConfirmation = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const [payPackage, setPayPackage] = useState({});
    const [paypalData, setPaypalData] = useState({});

    const getCurrentPackage = useCallback((userId) => {
        dispatch(loaderActions.loader(true));
        memberService.getMemberPackageById(userId).then(([response, error]) => {
            if (response) {
                const data = response.member_package_list.results.filter(obj => obj.id === +id);
                setPayPackage(data[0]);
            } else if (error) {
                alertActions.error(error.response);
            }
            dispatch(loaderActions.loader(false));
        })
    }, [dispatch, id]);


    const getPayPalAccessToken = useCallback(() => {
        dispatch(loaderActions.loader(true));
        paymentService.getPaypalToken().then(([result, error]) => {
            if (result) {
                let data =  convertArrayToObject(result)
                setPaypalData(data);
            } else if (error) {
                dispatch(alertActions.error('Service Unavailable . Please try after sometime .'));
            }
            dispatch(loaderActions.loader(false));
        })
    }, [dispatch]);

    useEffect(() => {
        if (Storage.getSession().id) {
            getCurrentPackage(Storage.getSession().id);
            getPayPalAccessToken();
        }
    }, [getCurrentPackage, getPayPalAccessToken]);

    const payAmount = useCallback(() => {
        if (paypalData.access_token && payPackage.order_id) {
            let dt = {token:paypalData.access_token, order_id:payPackage.order_id, id:payPackage.id}
            Storage.setMembership(JSON.stringify(dt));
            const refLink = `${process.env.REACT_APP_PAYPAL_URL}checkoutnow?token=` + payPackage.order_id
            window.location.href = refLink;
        }
    }, [paypalData.access_token, payPackage.order_id, payPackage.id])

    return (
        <div>
            <Row className="no-gutter justify-content-center p-4">
                <Col md={6}>
                    <Card className="w-100" >
                        <Card.Body className="P-0">
                            <Row >
                                <Col md={8}>
                                    <h3 className="text-primary  mb-0">Payment Confirmation</h3>
                                </Col>
                                <Col md={4}>
                                    <span className="d-block text-muted pt-2 text-right">Invoice detail</span>
                                </Col>
                            </Row>
                            <hr />
                            <Jumbotron className=" p-3 mb-0">
                                <Row className="text-left">
                                    <Col xs={6}>
                                        <label className="font-weight-bold text-dark mb-0"> Order ID  </label>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <p className="mb-0"> {payPackage.order_id ? payPackage.order_id : ""}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <label className="font-weight-bold text-dark mb-0"> Package Name  </label>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <p className="mb-0 text-warning font-weight-bold">{payPackage.package ? payPackage.package.name : ""}</p>
                                    </Col>
                                    <Col xs={7}>
                                        <label className="font-weight-bold text-dark mb-0"> Duration </label>
                                    </Col>
                                    <Col xs={5} className="text-right">

                                        <p className="mb-0">{payPackage.duration ? payPackage.duration + " Days" : ""}</p>
                                    </Col>
                                </Row>
                            </Jumbotron>
                            <Row className="p-3">
                                <Col md={12}>
                                    <ListGroup variant="flush" className="mb-0">
                                        <ListGroup.Item className="pl-0" ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{payPackage.package ? payPackage.package.overlay_ads : ""}</b></ListGroup.Item>
                                    </ListGroup>
                                    <ListGroup variant="flush" className="mb-0">
                                        <ListGroup.Item className="pl-0" ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{payPackage.package ? payPackage.package.invite_limit : ""}</b>  invite in {Math.round(payPackage.duration / 30)} months</ListGroup.Item>
                                    </ListGroup>
                                    <ListGroup variant="flush" className="mb-0">
                                        <ListGroup.Item className="pl-0" ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>∞ </b> Folders to save Assets</ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="text-left p-3">
                                <Col xs={7}>
                                    <label className="font-weight-bold text-dark mb-0"> Amount </label>
                                </Col>
                                <Col xs={5} className="text-right">

                                    <p className="mb-0 font-weight-bold text-dark">{payPackage.amount ? "$" + payPackage.amount : ""}</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                <Col md={4}>

                                    <Button className="w-100 my-4 px-5 " disabled={paypalData.access_token ? false : true} variant="primary" onClick={() => payAmount()}>Pay</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default PaymentConfirmation;