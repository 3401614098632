import React , { useState, useCallback } from 'react';
import * as assetsDiscussionService from '../../services/assetsDiscussion';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import TooltipHover from '../../shared/TooltipHover';
import { useDispatch } from 'react-redux';
import AsyncAppTable from '../../shared/AsyncAppTable';
import { useBoolean } from '../../hooks/basic';
import AssetDiscussionModal from './AssetDiscussionModal';
// import { Storage } from "../../helpers";

// const AssetDiscussionListing = () => {
const AssetDiscussionListing = ({search}) => {

const dispatch = useDispatch();
const [assetsData , setAssetsData] = useState([]);
const [loading , setLoading ] = useState(false);
const [pageCount, setPageCount] = useState(0);
const [isVisible, showModal, hideModal] = useBoolean(false);
const [editData , setEditData] = useState('');
const [resultCount, setResultCount] = useState(0);
const [callFetch, setCallFetch] = useState(false);

 const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField='' }) => {
  setLoading(true);
  setTimeout(() => {
      let offset = pageSize * pageIndex
      dispatch(loaderAction.loader(true));
      // assetsDiscussionService.assetsListing(pageSize, offset).then(([response , error]) => {
      assetsDiscussionService.assetsListing(pageSize, offset, search, sortedField).then(([response , error]) => {
        if(response){
          setAssetsData(response.results);
          setResultCount(response.count)
          setPageCount(Math.ceil(response.count / pageSize))
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
      setCallFetch(false);
  }, 100)
// }, [dispatch])
}, [dispatch, search])

const openModal = useCallback((data) => {
  if(data) setEditData(data);
  showModal();
}, [showModal]);


const closeModal = useCallback(() => {
  setEditData('')
  // fetchData(Storage.getSession().id, 10 ,0);
  setCallFetch(true);
  hideModal();
},[hideModal])

const assetsDataColumns = [
  // {
  //   Header: "Asset Id",
  //   accessor: "id",
  //   sortType: "basic",
  //   // filtering:true,
  // },
  {
    Header: "Comments Count",
    accessor: "discussions",
    sortType: "basic",
    // filtering:true
  },
  {
    Header: "Specific Topic",
    accessor: "specific_topic",
    sortType: "basic",
    // filtering:true
  },
  {
    Header: "Action",
    accessor: "action",
    sortingOff: true,
    className: 'text-center',
    Cell: (cell) => (
      <div>
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default'  message='View Comments ' handleEvent={() => openModal(cell.row.original)} />
      </div>
    ),
  }
];

  return (
    <>
    {/* <AssetDiscussionModal data={editData} title='Asset Discussion' isVisible={isVisible} hide={closeModal} refreshData={() => fetchData(Storage.getSession().id, 10 ,0)} /> */}
    <AssetDiscussionModal data={editData} title='Asset Discussion' isVisible={isVisible} hide={closeModal} />
    <AsyncAppTable
        columns={assetsDataColumns}
        data={assetsData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount} 
        filtering={true}
        callFetch={callFetch}
        />
    </>
  )
}
export default AssetDiscussionListing;