import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { users } from '../reducers/users';
import { auth } from '../reducers/auth';
import { alert } from '../reducers/alert';
import { loader } from '../reducers/loader';
import { membership } from '../reducers/membership';

export const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    users,
    alert,
    loader,
    membership
});
