import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { Container, Col, Row, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Storage } from '../../helpers';
// import { useQuery } from '../../helpers';
import * as memberService from '../../services/member';

const PaymentCancel = ({history}) => {

   const ppStatus = {
      'DECLINED': 5,
      'PENDING': 4
   }

//   const query = useQuery()
  const [id, setId] = useState()

  const updatePackage = useCallback(() => {
   if (Storage.getSession() && Storage.getMembership() && Storage.getPaypalResp()) {
      let status = ppStatus[Storage.getPaypalResp()?.status]
      if (status) {
         let data = {
            status
         }
         memberService.updateMemberPackage(Storage.getMembership().id ,data)
      };
   }
}, [ppStatus])

  useEffect(() => {
    if(Storage.getMembership()){
      setId(Storage.getMembership()?.id)
      Storage.removeMembership();
    }
    updatePackage()
  },[id, updatePackage])
  
  const reTry = useCallback(() => {
      if (id) history.push(`/paymentConfirmation/package=${id}`)
      else history.push('/membership')
   }, [history,id])
  
  return (
    <>
    
    <Container className="pb-5" id='print-area'>
       <Row className="text-center py-3 mt-4">
          <Col md={12} >
             <FontAwesomeIcon size="lg" className="icon-green my-3 text-danger success-icon" icon="exclamation-circle" />
             <h1>Payment Failed</h1>
          </Col>
       </Row>
       <Row className="justify-content-center">

          <Col md={12} className="text-center d-print-none">
             <Button className="my-2 px-5 " onClick={reTry} variant="primary">Retry</Button>
             <Button className="my-2 px-5 ml-3" onClick={() => history.push('/')} variant="primary">Cancel</Button>
          </Col>
       </Row>
    </Container>
 </>
  )
}
export default PaymentCancel;