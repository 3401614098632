import React, { useState, useCallback, useRef} from 'react';
import { Container, Row, Col, Alert, Button,Jumbotron,Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Storage } from '../../helpers';
import * as memberServices from '../../services/member';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';

const MemberPledge = ({ history }) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        pledge_signature: '',
    });
    const [checked, setChecked] = useState(false);
    const prevState = useRef(formData);

    const handleChange = useCallback((e) => {
        setFormData({
          ...formData,
          [e.name]: e.value
        });
      }, [formData])

      const handleSelect  = (e) => {
        if (e.target.checked) {
          setChecked(true);
        }else{
            setChecked(false);
        }
      }

    const submitData = useCallback(() => {
        dispatch(loaderActions.loader(true));
        const form = new FormData();
        form.append('pledge_signature', formData.pledge_signature);
        memberServices.submitMemberPledge(form).then(([response, error]) => {
          if (response) {
            setFormData(prevState.current);
            setValidated(false);
            dispatch(alertActions.success('Member Pledge Submitted Successfullly'));
            setTimeout(()=> {
              history.push('/home');
            },[1000])
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderActions.loader(false));
        })
      }, [dispatch, formData.pledge_signature, history])
    
      const handleSubmit = useCallback((event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        if (form.checkValidity()) {
            if(!checked){
                dispatch(alertActions.info("Please agree to the terms & conditions"));
            }else{
                setValidated(true);
                submitData();
            }
        }
      }, [submitData])

   return (
      <>
          <Jumbotron className="d-flex align-items-center min-vh-100 mb-0 bg-grey-gradient">
         <Container className="my-5 p-1">

            <Row className="bg-white p-3  shadow-custom shadow">
               <Col md={12}>
                  <h2>Qrata’s Member Pledge</h2>
                  <p> By agreeing to become a Member of Qrata’s ecosystem you are acknowledging the fact that you realize that our platform and Superior Database build out is a work in progress.  We will have few researched topics in the beginning but <b>we will be adding more and more</b> to continually grow and improve our Superior Database.  Rather than complaining about what is not in our Superior Database we would appreciate your help as a Member in supporting our progress by: 
                  </p>
                
                 <ol className="pl-3">
                     <li>
                     Not only reviewing `the topics that we have researched and suggesting better results therefore improving our Superior Database through <b>"Challenges"</b> ; but also
                     </li>
                     <li>
                     Suggesting new Need-2-Know content that people around the world Need-2-Know to improve their lives through new <b>"Topic Suggestions"</b>
                     </li>
                     <li>
                     I realize that building the world's Superior Database is a never ending process and that I am taking part in the <b> "Not Enough Rated Assets Beta Test"</b>.     
                     </li>
                 </ol>
                
              <p>We can handle constructive criticism and look forward to your ideas and suggestions with the use of our software and other features, we just want you to provide feedback to us in a professional and adult manner.</p>
            <p>While agreeing to be a Member of Qrata you agree to not be a nuisance and agree to being a positive Member of our ecosystem and not being a troll to us or other members because if you are we can revoke your Member’s only access to specific and/or all existing and future features of our ecosystem or your Membership in its entirety at any time for reasons deemed necessary in Qrata's sole opinion.</p>

               </Col>
               <Col md={12}>
                   <Form noValidate validated={validated} onSubmit={handleSubmit}>
               <Form.Group as={Row} className="mb-3">
                                    
                                    <Col sm="12" className="d-flex align-items-center">
                                        <Form.Check className="d-inline-block mt-1 mr-1" size="lg"  required inline type="checkbox"  aria-label="radio 1"  defaultChecked={checked} onChange={(e) => handleSelect(e)}/>I agree to be a positive Qrata Member and to not be a nuisance or a troll knowing that the Superior Database does not yet have enough rated Assets in it
                                    </Col>
                                   
                                </Form.Group>
                                <Col md={4}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label>
                                    Type your name as a signature to this statement
    </Form.Label>
                                    
                                    <Form.Control type="text" id="basic-firstName" placeholder=""  name='pledge_signature' value={formData.pledge_signature} onChange={(e) => handleChange(e.target)} required/>

                                    
                                </Form.Group>
                                </Col>
                                <Col sm="12">
                                <Form.Group as={Row} className="mb-3">
                                    <Button  className="btn-green" variant="primary" type="submit" disabled={checked === false || formData.pledge_signature === ''}>
                                            Submit
                                        </Button>
                                        </Form.Group>
                                    </Col>
                                </Form>
               </Col>
            </Row>
         </Container>
         </Jumbotron>
      </>
   )
}
export default MemberPledge;