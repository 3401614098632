import React, { useState } from 'react';
import { Form, Col, Container, Row, ToggleButton } from 'react-bootstrap';
import { QueriesStatus } from '../../helpers/constants';
import SearchField from '../../shared/SearchField';
import MessageListing from './MessageListing';


const MessageMain = () => {
    const [search , setSearch] = useState('');
    const [status , setStatus] = useState(1);


    return (
        <>
        <Container fluid className="px-0">
            <Row className="py-3">
                <Col md={5} className="mb-md-0 mb-3"><h2>Messages</h2></Col>
                <Col md={3} className="mb-md-0 mb-2">
                    <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
                    {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
                    )}
                    </Form.Control>
                </Col>
                <SearchField md={4} action={setSearch} />
            </Row>
            
            <MessageListing search={search} status={status} />
        </Container>
        </>
    )
}
export default MessageMain;