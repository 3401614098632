import React , { useState , useEffect ,useCallback } from 'react';
import { Card , Col, Row, Table } from 'react-bootstrap';
import { Storage } from '../../helpers';
import * as userService from '../../services/user';
import { dateFormat } from '../../helpers/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipHover from '../../shared/TooltipHover';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';

const Logs = () => {

  const dispatch = useDispatch();
  const [logData , setLogData] = useState([]);


  const getAllLogs = useCallback(() => {
    dispatch(loaderActions.loader(true));
    userService.getLog(Storage.getSession().id).then(([response , error]) => {
      if(response){
        setLogData(response.results)
      }else if(error){
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  },[dispatch])

  useEffect(() => {

    getAllLogs();
    
    return () => {
      setLogData([]);   
    }
  }, [getAllLogs])

  
  const deleteLog = useCallback((id) => {
    dispatch(loaderActions.loader(true));
    userService.deleteLog(id).then(([response , error]) => {
      dispatch(alertActions.success('Deleted Successfully.'));
      getAllLogs();
      if(error){
        dispatch(alertActions.error(error.response.data));
      }
      dispatch(loaderActions.loader(false));
    })
  },[getAllLogs , dispatch])

  return (
    <Card.Body className="px-5">
    <Row>
    <Col md={12}>
        <Row className="py-1">
        <Col md={6}> <h2 >Login History</h2></Col>
         
        </Row>
        <Table responsive="sm">
          <thead>
            <tr>
              <th className="border-bottom-0">Ip</th>
              <th className="border-bottom-0">Browser</th>
              <th className="border-bottom-0">Platform</th>
              <th className="border-bottom-0">Date</th>
              <th className="border-bottom-0">Status</th>
              <th className="border-bottom-0 ">Action</th>
            </tr>
          </thead>
          <tbody>
            {logData.length > 0 ? logData.map((item,index) => (
              <tr  key={'LOGS'+index}>
                <td>{item.ip}</td>
                <td>{item.browser}</td>
                <td>{item.os}</td>
                <td>{dateFormat(item.login_time , "DD MMM YYYY HH:mm")}</td>
                <td className='text-center'>{item.active ? <FontAwesomeIcon className='text-success' icon='dot-circle' /> : <FontAwesomeIcon className='text-danger' icon='dot-circle' />}</td>
                <td >
                <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Log' handleEvent={() => deleteLog(item.id)}/>
                </td>
              </tr>
            )) : 
            <>
            <tr>
              <td className='text-center' colSpan='5'>No Log Data Found.</td>
              </tr>
              </>}
          </tbody>
        </Table>
      </Col>
      </Row>
      </Card.Body>
  )
}
export default Logs;