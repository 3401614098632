import React, { useRef, useState, useCallback } from "react";
import { Container, Row, Col,Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import TooltipHover from "../../shared/TooltipHover";
import * as topicRequestService from '../../services/topicRequest';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import AsyncAppTable from "../../shared/AsyncAppTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoolean } from "../../hooks/basic";
import TopicRequestModal from "./TopicRequestModal";
import { dateOnlyFormat } from "../../helpers";
import { QueriesStatus } from "../../helpers/constants";
import SearchField from '../../shared/SearchField';
import SubTable from "../../shared/SubTable";



const TopicRequest = () => {

  const dispatch = useDispatch();
  const fetchIdRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [editData, setEditData] = useState('');
  const [resultCount, setResultCount] = useState()
  const [search , setSearch] = useState('');
  const [status , setStatus] = useState(1);
  const [callFetch, setCallFetch] = useState(false);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField='', filter }) => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const limit = pageSize;
        let offset = pageIndex * pageSize;
        dispatch(loaderAction.loader(true));
        topicRequestService.topicRequestListing('',  limit, offset, sortedField, filter, search, status).then(([response, error]) => {
          if (response) {
            setResultCount(response.count)
            setListingData(response.results);
            setPageCount(Math.ceil(response.count / pageSize))
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
        setCallFetch(false);
      }
    
    }, 1000)
    return () => {
      setListingData([]);
    }
  }, [dispatch, search, status])

  const Parent = ({row}) => {
    const subColumns = [
      {
        Header: "Ticket Id",
        accessor: "ticket",
        sortType: "basic",
        filtering:true,
      },
      {
        Header: "Name",
        accessor: "user__first_name",
        sortType: "basic",
        className: 'text-capitalize',
        filtering:true,
        Cell: (cell) => (
          <div>
            {cell.row.original.first_name || cell.row.original.last_name ? cell.row.original.first_name + ' ' + cell.row.original.last_name : 'Guest user'}
          </div>
        )
      },
      {
        Header: "Email ID",
        accessor: "email",
        id: "user__email",
        sortType: "basic",
        filtering:true,
        className: 'text-capitalize'
      },
      {
        Header: "Specific Topic",
        accessor: "specific_topic",
        sortType: "basic",
        filtering:true,
      },
      {
        Header: "Created On",
        accessor: "created_on",
        sortType: "basic",
        Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
      },
      {
        Header: "File",
        sortingOff: true,
        className: 'text-center',
        Cell: (cell) => (
          <div>
            {cell.row.original.file ? <FontAwesomeIcon className='cursor-pointer' onClick={() => openlink(cell.row.original.file[0]?.file_url)} icon='download' /> : 'No file'}
          </div>
        )
      },
      {
        Header: "Action",
        accessor: "action",
        sortingOff: true,
        className: 'text-center',
        Cell: (cell) => (
          <div>
            <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default' message='View Message' handleEvent={() => openModal(cell.row.original)} />
          </div>
        ),
      },
    ]; 
    return <><SubTable columns={subColumns} data={[row.original.parent]} /></>;
  }

  const openlink = (link) => {
    window.open(link, '_blank');
  }

  const openModal = useCallback((data) => {
    if (data) setEditData(data);
    showModal();
  }, [showModal]);


  const closeModal = useCallback(() => {
    setEditData('');
    hideModal();
  }, [hideModal])

  const renderRowSubComponent = useCallback(({ row  }) => (
    <>
      <Parent
      row={row} />
    </>
  ), [])

  const columns = [
    {
      Header: "#",
      accessor: "id",
      id: 'expander', 
      sortingOff: true,
      Cell: ({ row, rows, toggleRowExpanded }) => (
        row.original.parent?(
        <span {...row.getToggleRowExpandedProps} onClick={() => {
          const expandedRow = rows.find(row => row.isExpanded);
  
          if (expandedRow) {
            const isSubItemOfRow = Boolean(
              expandedRow && row.id.split(".")[0] === expandedRow.id
            );
  
            if (isSubItemOfRow) {
              const expandedSubItem = expandedRow.subRows.find(
                subRow => subRow.isExpanded
              );
  
              if (expandedSubItem) {
                const isClickedOnExpandedSubItem =
                  expandedSubItem.id === row.id;
                if (!isClickedOnExpandedSubItem) {
                  toggleRowExpanded(expandedSubItem.id, false);
                }
              }
            } else {
              toggleRowExpanded(expandedRow.id, false);
            }
          }
          row.toggleRowExpanded();
        }
      }>
          {row.isExpanded ? <span aria-label='img' role='img' > <FontAwesomeIcon className="text-mute  nav-icons" icon="caret-down" size="lg" /></span> :
          <span aria-label='img' role='img' ><FontAwesomeIcon className="text-mute  nav-icons" icon="caret-right" size="lg" /></span>}
        </span>):""
      )
    },
    {
      Header: "Ticket Id",
      accessor: "ticket",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Name",
      accessor: "user__first_name",
      sortType: "basic",
      className: 'text-capitalize',
      filtering:true,
      Cell: (cell) => (
        <div>
          {cell.row.original.first_name || cell.row.original.last_name ? cell.row.original.first_name + ' ' + cell.row.original.last_name : 'Guest user'}
        </div>
      )
    },
    {
      Header: "Email ID",
      accessor: "email",
      id: "user__email",
      sortType: "basic",
      filtering:true,
      className: 'text-capitalize'
    },
    {
      Header: "Specific Topic",
      accessor: "specific_topic",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },
    {
      Header: "File",
      sortingOff: true,
      className: 'text-center',
      Cell: (cell) => (
        <div>
          {cell.row.original.file ? <FontAwesomeIcon className='cursor-pointer' onClick={() => openlink(cell.row.original.file[0].file_url)} icon='download' /> : 'No file'}
        </div>
      )
    },
    {
      Header: "Action",
      accessor: "action",
      sortingOff: true,
      className: 'text-center',
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default' message='View Message' handleEvent={() => openModal(cell.row.original)} />
        </div>
      ),
    },
  ];

  return (
    <>
    {isVisible ?
      <TopicRequestModal data={editData} title='Topic Request Reply' isVisible={isVisible} hide={closeModal} refreshData={() => setCallFetch(true)} />
      :
      <Container fluid>
        <Row className="py-3">
          <Col md={5}>
          <h2>Topic Requests</h2>
          </Col>
          <Col md={3}>
              <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
              {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
              )}
              </Form.Control>
          </Col>
          <SearchField md={4} action={setSearch}/>
      </Row>
       
        <AsyncAppTable
          columns={columns}
          data={listingData}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          resultCount={resultCount} 
          filtering={true}
          subComponent={renderRowSubComponent}
          callFetch={callFetch}
          />
      </Container>}
    </>
  );
}

export default TopicRequest;