import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserRole } from '../../helpers/constants';
import { Storage } from '../../helpers/storage';

// restricted = false meaning public route accessible evene after login. Like About Us, Contact Us or other footer links
// restricted = true meaning restricted route can't be accessed after login. like foprgot password, reset etc

export const PublicRoute = ({component: Component, restricted, ...rest}) => (
        <Route {...rest} render={props => (
          (Storage.getSession() !== null && restricted) ?  Storage.getSession().group.includes(UserRole.MEMBER.id) ? <Redirect to="/home" /> : <Redirect to="/login" /> : <Component {...props} />
        )} />   
    );