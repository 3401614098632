import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'query/';

export async function createRequest(data) {
  return await handleApi(axiosClient.post(`${BASE}create/` , data));
}

export async function getContactEnums() {
  return await handleApi(axiosClient.get(`${BASE}query_enums/`));
}

export async function getDataById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get/`));
}

export async function getSupportEnums() {
  return await handleApi(axiosClient.get(`${BASE}problem_enums/`));
}

export async function getListing(queryType , limit , offset , enums='' , search='' , user = '') {
  return await handleApi(axiosClient.get(`${BASE}${queryType}/list/?${enums !== ''?'qenums='+enums+'&':''}${user !== ''?'user='+user+'&':''}${search !== ''?'qticket='+search+'&':''}limit=${limit}&offset=${offset}`));
}

export async function createReply(data) {
  return await handleApi(axiosClient.post(`${BASE}create_reply/` , data));
}

export async function ticketReply(ticket) {
  return await handleApi(axiosClient.get(`${BASE}${ticket}/ticket_reply/`));
}

export async function deleteTicket(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}