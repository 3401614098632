
import React, { useState, useEffect, useCallback } from "react";
import * as paymentService from '../../services/payment';
import { Container, Form, ListGroup, Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import * as loaderActions from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import { useDispatch } from "react-redux";
import * as membershipService from '../../services/membership';
import * as memberService from '../../services/member';
import { convertArrayToObject, Storage , uuidv4} from "../../helpers";
import { PaymentStatus } from "../../helpers/constants";


const PaymentCheckout = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const [membership, setMembership] = useState({});
  const [amount, setAmount] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [paypalData, setPaypalData] = useState({});
  const [timePeriod, setTimeperiod] = useState(1);

  useEffect(() => {
    if (id) {
      dispatch(loaderActions.loader(true));
      membershipService.getPackageById(id).then(([response, error]) => {
        if (response) {
          setMembership(response);
          setMonthlyPrice(response.monthly_amt)
        } else if (error) {
          console.log(error.response);
        }
        dispatch(loaderActions.loader(false));
      })

    }
    return () => {
      setMembership([]);
    }
  }, [id, dispatch])


  const getPayPalAccessToken = useCallback(() => {
    dispatch(loaderActions.loader(true));
    paymentService.getPaypalToken().then(([result, error]) => {
      if (result) {
        setPaypalData(convertArrayToObject(result));
      } else if (error) {
        dispatch(alertActions.error('Service Unavailable . Please try after sometime .'));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch]);

  const payAmount = useCallback(() => {
    if(paypalData.access_token){
      dispatch(loaderActions.loader(true));
      const ref_id = uuidv4();
      const data = {
        "token": paypalData.access_token,
        "value": `${amount === 0 ? membership.monthly_amt : amount}.00`,
        "ref_id" : ref_id
      }
      paymentService.paymentCheckout(data).then(([result, error]) => {
        if (result) {
          const results = convertArrayToObject(result);
          const packageData = {
            "user": Storage.getSession().id,
            "package": membership.id,
            "package_length": timePeriod === 365 ? 365 : 30 * timePeriod,
            "amount" : amount === 0 ? membership.monthly_amt : amount ,
            "order_id" : results.id,
            "ref_id": ref_id
          }
          if(results.id){
            memberService.savePackage(packageData).then(([response, error]) => {
              if(response){
                response.token = paypalData.access_token;
                response.type = PaymentStatus.UPGRADED.value;
                Storage.setMembership(JSON.stringify(response));
                window.location.href = results.links[1].href;
              }else if(error){
                dispatch(alertActions.error(error.response));
              }
            });
          }else{
            dispatch(alertActions.error("Unknow error."));
          }
          
        } else if (error) {
          if (Storage.getMembership()) {
            Storage.removeMembership();
          }
          dispatch(alertActions.error(error.response));
        }
  
        dispatch(loaderActions.loader(false));
      })
    }else{
      dispatch(alertActions.error('Please referesh the page to payment.'))
    }
  },[amount , dispatch , membership.id , membership.monthly_amt , paypalData.access_token , timePeriod])

  useEffect(() => {
    getPayPalAccessToken();
  }, [getPayPalAccessToken])

  const selectPackage = useCallback((period, type) => {
    let monthly = 0;
    if (type === 'MONTHLY') {
      if ( period === 12 ){
        monthly = membership.yearly_amt;
        setMonthlyPrice(monthly);
        setTimeperiod(period);
      }
      else{
      monthly = membership.monthly_amt * period;
      setMonthlyPrice(monthly);
      setTimeperiod(period);
      }
    } else if (type === 'YEARLY') {
      monthly = period;
      setTimeperiod(365);
    }
    setAmount(monthly);
  }, [membership.monthly_amt , membership.yearly_amt])

  return (
    <>
      <Container className="pb-5">
        <Row className="text-center py-5">
          <Col md={12} >
            <h2>Membership Detail</h2>
          </Col>
        </Row>
        <Row className=" pb-3">
          <Col md={12}>
            <ul className="list-unstyled">
              <li> <h4>You've added the Qrata {membership.name ? membership.name : ''} membership Plan.</h4></li>
            </ul>
          </Col>
        </Row>
        <Row className="text-center">
          <Col md={8}>
            <Row>
              <Col md={12} className="d-inline-flex mb-3">
                <Card className="w-100" >
                  <Card.Body className="text-left">
                    <Card.Title className=" text-uppercase p-3 mb-l0">
                      <Row>
                        <Col md="5">
                          <h2 className="text-warning"> {membership.name ? membership.name : ''}</h2>
                          <span className="d-block text-muted text-capitalize"><small>{membership.yearly_amt ? membership.yearly_amt : '0'}/yr Paid Yearly in advance</small></span>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label></Form.Label>
                            <Form.Control as="select" onChange={(e) => selectPackage(+e.target.value, 'MONTHLY')}>
                              <option value={1}>1 Month</option>
                              <option value={3}>3 Month</option>
                              <option value={6}>6 Month</option>
                              <option value={12}>12 Month</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="7 text-right">
                          <span className="price-tag text-capitalize">${monthlyPrice}</span>
                        </Col>
                      </Row>
                    </Card.Title>
                    <ListGroup variant="flush" className="mb-4">
                      <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{membership.overlay_ads}</b> on the overlay</ListGroup.Item>
                    </ListGroup>
                    <ListGroup variant="flush" className="mb-4">
                      <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{membership.page_ads}</b>  on the landing page</ListGroup.Item>
                    </ListGroup>
                    <ListGroup variant="flush" className="mb-4">
                      <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{membership.invite_limit}</b> invite in 6 months</ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12}>
                <Card className="w-100" >
                  <Card.Body className="text-left">
                    <Card.Title className=" text-Capitilized p-3 mb-0">
                      <Row>
                        <Col md="12">
                          <p>Lifetime Memberships that are only available for a limited time.</p>
                          <Row>
                            <Col md={8}>
                              <Form.Group >
                                <ListGroup variant="flush" className="mb-4">
                                  <ListGroup.Item><Form.Check size="lg" inline type="radio" checked={membership.lifetime_amt === amount} onChange={() => selectPackage(membership.lifetime_amt, 'YEARLY')} aria-label="radio 1" /><small>Lifetime Membership</small></ListGroup.Item>
                                </ListGroup>
                              </Form.Group>
                            </Col>
                            <Col md={4} className="text-right">
                              <span className="price-tag text-capitalize">${membership.lifetime_amt ? membership.lifetime_amt : '500'}</span>
                            </Col>
                          </Row>

                        </Col>

                      </Row>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12} className="text-right">
                <Button className="my-4 px-5 " disabled={paypalData.access_token ? false : true} variant="primary" onClick={() => payAmount()}>Pay</Button>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="no-gutter">
              <Col md={12} className="text-center">
                <span className="qrataads-300-250 bg-light my-3">
                  300x250 - Advertisement Banner
                </span>
              </Col>
              <Col md={12} className="text-center">
                <span className="qrataads-300-250 bg-light my-3">
                  300x250  - Advertisement Banner
                </span>
              </Col>
              <Col md={12} className="text-center">
                <span className="qrataads-300-250 bg-light my-3">
                  300x250  - Advertisement Banner
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default PaymentCheckout;
