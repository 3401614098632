import React, { useState, useCallback, useEffect } from 'react';
import { Button, Card, Col, Row  } from 'react-bootstrap';
import { Storage } from '../../helpers';
import * as memberService from '../../services/member';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import * as alertActions from '../../actions/alert';
import TooltipHover from '../../shared/TooltipHover';
import * as userService from '../../services/user';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailsList from './EmailsList';

const Account = () => {

  const dispatch = useDispatch();
  const [phoneCode , setPhoneCode] = useState(99);
  const [countryList , setCountryList] = useState([]);
  const [userData, setUserData] = useState({
    id: '',
    first_name : '',
    last_name : '' ,
    former_first_name: '',
    former_last_name: '', 
    username: '',
    email : '',
    phone: '',
    referral_code : '',
    updateUsername : false
  });
  const [editData , setEditData] = useState(false);
  const [validUsername , setValidUsername] = useState(false);
  const [addEmail, setAddEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [openEmailListPopup, setOpenEmailListPopup] = useState(false);

  useEffect(() => {
    memberService.getCountryList().then(([response , error]) => {
      if(response){
        setCountryList(response.results);
      }else if(error){
        dispatch(alertActions.error(error.response));
      }
    })
  },[dispatch])

  // Get member data by ID
  const getMemberById = useCallback((id) => {
    dispatch(loaderActions.loader(true));
    memberService.getMemberById(id).then(([response, error]) => {
      if (response) {
        setUserData({
          id: response.results[0].user_id,
          first_name : response.results[0].first_name,
          last_name : response.results[0].last_name , 
          former_first_name: response.results[0].former_first_name,
          former_last_name: response.results[0].former_last_name,
          username : response.results[0].username ,
          email : response.results[0].email , 
          phone: response.results[0].member_phone.phone !== undefined ? response.results[0].member_phone.phone : '', 
          updateUsername: response.results[0].update_username,
          referral_code : response.results[0].referral_code
        });
        setPhoneCode(response.results[0].member_phone.country_code !== undefined ? response.results[0].member_phone.country_code : '99')
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch])

  useEffect(() => {
    if (Storage.getSession().id) {
      getMemberById(Storage.getSession().id);
    }
    return () => {
      setUserData({})
    }
  }, [getMemberById])


  // COPY to Clipboard
  const copyToClipboard = useCallback(() => {
      const text = document.getElementById('referral_code').innerText;
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.setAttribute('value', text);
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      dispatch(alertActions.success('Copy Successfully.'));
  },[dispatch])

  const cleanContactNo = (str) => {
    return str.replace(/[^0-9+]/g, "");
  };

  // Update Member FirstName , LastName and Username
  const UpdateMemberData = useCallback(() => {
    const data = {
      first_name : userData.first_name,
      last_name : userData.last_name,
      former_first_name: userData.former_first_name,
      former_last_name: userData.former_last_name,
      username: userData.username,
      country_code: phoneCode,
      phone : cleanContactNo(userData.phone)
    }
    userService.updateUser(userData.id, data).then(([response , error]) => {
      if(response){
        setEditData(false);
        dispatch(alertActions.success('Update Successfully.'));
      }else if(error){
        dispatch(alertActions.error(error.response));
      }
    })
  },[dispatch,phoneCode, userData.id , userData.first_name , userData.phone, userData.last_name , userData.username, userData.former_first_name, userData.former_last_name])

  const checkUsername = useCallback((e) => {
    const { name , value} = e.target;
    memberService.checkMember(name , value).then(([response , error]) => {
      if(response){
        setValidUsername(false);
      }else if(error){
        setValidUsername(true);
        dispatch(alertActions.error(error.response));
      }
    })
  },[dispatch])

  const handleChange = useCallback((e) => {
    const { name , value } = e.target;
    setUserData({
      ...userData, 
      [name] : value
    })
   },[userData])

   const handleEmailChange = useCallback((e) => {
     const {name, value} = e.target;
     setNewEmail(value);
   })

   const CountrySelect = ({countries}) => {
     return(
       <select className='countryCode' onChange={(e) => setPhoneCode(e.target.value)}>
         <option style={{display:'none'}} key={'countryCodeDefault' +1}> +{phoneCode}</option>
         {countries.map((item,index) => 
          <option  key={'countryCode' +index} value={item.phoneCode}>{item.name}   {'+'+item.phoneCode}</option>
          )}
       </select>
     )
   }

  const saveEmail = useCallback(() => {
    dispatch(loaderActions.loader(true));
    const form = new FormData();
    form.append('email', newEmail);
    userService.saveNewEmail(form).then(([response, error]) => {
      if (response) {
        dispatch(alertActions.success('Email Added Successfully'));
        setAddEmail(false);
      } else if (error) {
        let msg
        if (error.response?.data) {
          msg = error.response?.data[Object.keys(error.response?.data)[0]]
          msg = typeof msg === 'object'? "Email already exists" : msg
        } else {
          msg = "Something went wrong!"
        }
        // if (typeof error.response === string)
        dispatch(alertActions.error(msg));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch, newEmail])

  const onClose = useCallback(() => {
    setOpenEmailListPopup(false);
  })

  return (
    <>
      <h2 className="pb-3">Contact Info</h2>
      {userData.email ?
        <>
          <Card>
            <Card.Body className="px-5">
              <Row>
                <Col xs={10} md={11}>
                  <label className="text-dark font-weight-bold">Name</label>
                  {editData ? 
                  <>
                  <div>
                  <input className='mr-2 border-top-0 border-left-0 border-right-0 outline-0 border-light outline-focus-0' type='text' placeholder='First Name' name='first_name' value={userData.first_name ? userData.first_name : ''} onChange={(e) => handleChange(e)} /> 
                  <input className='border-top-0 border-left-0 border-right-0 outline-0 border-light outline-focus-0' type='text' placeholder='Last Name' name='last_name' value={userData.last_name ? userData.last_name : ''} onChange={(e) => handleChange(e)} />
                  </div>
                  </>
                  : 
                  <p>{userData.first_name ? userData.first_name + ' ' + userData.last_name : ''}</p> }
                </Col>
                <Col xs={10} md={11}>
                  <label className="text-dark font-weight-bold">Former Name</label>
                  {editData ? 
                  <>
                  <div>
                  <input className='mr-2 border-top-0 border-left-0 border-right-0 outline-0 border-light outline-focus-0' type='text' placeholder='Former First Name' name='former_first_name' value={userData.former_first_name ? userData.former_first_name : ''} onChange={(e) => handleChange(e)} /> 
                  <input className='border-top-0 border-left-0 border-right-0 outline-0 border-light outline-focus-0' type='text' placeholder='Former Last Name' name='former_last_name' value={userData.former_last_name ? userData.former_last_name : ''} onChange={(e) => handleChange(e)} />
                  </div>
                  </>
                  : 
                  <p>{userData.former_first_name ? userData.former_first_name + ' ' + userData.former_last_name : ''}</p> }
                </Col>
                <Col xs={2} md={1}>
                  {editData ? 
                  <Button disabled={validUsername} onClick={() => UpdateMemberData(userData)}>Save</Button>
                  :
                  <Button onClick={() => setEditData(true)}>Edit</Button>
                  }
                </Col>
                <Col md={12}>
                  <label className="text-dark font-weight-bold">Username <TooltipHover type='icon' btnSize='sm' iconClass='text-danger ml-2 ' variant='link' icon='info-circle'  message={'You can update username only once.'} handleEvent={() => null} /></label>
                  {editData && userData.updateUsername ? 
                 
                  <input className=' d-block border-top-0 border-left-0 border-right-0 outline-0 border-light outline-focus-0' type='text' placeholder='Username' name='username' value={userData.username ? userData.username : ''} onBlur={(e)=> checkUsername(e)} onChange={(e) => handleChange(e)} />
                  : <p>{userData ? userData.username : ''}  </p> }
                </Col>
                <Col xs={10} md={10}>
                  <label className="text-dark font-weight-bold">Email Address</label>
                  <p>{userData ? userData.email : ''}</p>
                </Col>
                <Col xs={2} md={2}>
                  {!addEmail ? 
                  <FontAwesomeIcon icon='plus-square' size="lg" className='text-dark bg-white' onClick={() => setAddEmail(true)} /> : ''}
                  <FontAwesomeIcon icon='check-circle' size="lg" className='default ml-1' onClick={() => setOpenEmailListPopup(true)} />
                </Col>
                {
                  addEmail ? 
                  <>
                  <Col xs={10} md={8}>
                  <label className="text-dark font-weight-bold">Add Email Address</label>
                  <div>
                  <input className='mr-2 border-top-0 border-left-0 border-right-0 outline-0 border-light outline-focus-0' type='email' name='newEmail' value={newEmail} onChange={(e) => handleEmailChange(e)} /> 
                  </div>
                  </Col>
                  <Col xs={2} md={4}>
                  <Button onClick={saveEmail}>Save</Button>
                  <Button variant="danger ml-1" onClick={() => setAddEmail(false)}>Cancel</Button>
                  </Col>
                  </> : ''
                }
                <Col md={12}>
                  <label className="text-dark font-weight-bold">Phone Number</label>
                  {editData ? 
                  <div className='d-flex mb-3'><CountrySelect countries={countryList} /><InputMask mask={`(999) 999-9999`} className=' d-block border-top-0 border-left-0 border-right-0 outline-0 border-light outline-focus-0' type='tel'  name='phone' value={userData.phone ? userData.phone : ''}  onChange={(e) => handleChange(e)} /></div>
                  :  <p>{userData ? '+'+phoneCode+' '+userData.phone : ''}</p> }
                </Col>
                <Col md={12}>
                  <label className="text-dark font-weight-bold">Referral Code</label>
                  <p id='referral_code' >{userData ? userData.referral_code : ''} 
                  <TooltipHover type='icon' btnSize='sm' variant='link' iconClass='ml-2 cursor-pointer' icon='copy' message='Copy' handleEvent={() => copyToClipboard()}/>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </> : ''}
        {openEmailListPopup ? <EmailsList isVisible={openEmailListPopup} hide={onClose}/> : ''}
    </>
  )
}
export default Account;