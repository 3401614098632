import React, { useState, useRef, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Storage } from '../../helpers';
import Captcha from '../../shared/Captcha';
import FormBlock from '../../shared/FormBlock';
import * as challengeService from '../../services/challenges';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import AsyncSelect from '../../shared/select/AsyncSelect';

const ChallengesForm = () => {

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    asset: '',
    superior_url: '',
    message: '',
    attachment: '',
    user: Storage.getSession() ? Storage.getSession().id : '',
  });


  const validateCaptcha = useRef();
  const prevState = useRef(formData);


  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData]);


  const formFields = [
    // [{ label: 'Select Asset', type: 'multi-select', required: true, placeholder: '---Select---', name: 'asset', options: [], sm: 12, md: 12, lg: 12, xl: 12, callbackData : () => null }],
    [{ label: 'Superior Url', type: 'url', required: true, placeholder: 'Superior Url', name: 'superior_url', sm: 12, md: 12, lg: 12, xl: 12, }],
    [{ label: 'Message', type: 'textarea', required: true, name: 'message', placeholder: 'Message...', sm: 12, md: 12, lg: 12, xl: 12, }],
    [{ label: 'Attachments(for example , Resume ,  Screenshots)', type: 'file', fileFormat: '.pdf,.jpg,.png,.jpeg', placeholder: 'Attachment', name: 'attachment', sm: 12, md: 12, lg: 12, xl: 12, }]
  ]

  const saveCreateData = useCallback(() => {
    if(formData.asset !== ''){
      dispatch(loaderActions.loader(true));
      const form = new FormData();
      form.append('user', formData.user);
      form.append('asset', formData.asset);
      form.append('superior_url', formData.superior_url);
      form.append('message', formData.message);
      if (formData.attachment) {
        form.append('attachment', formData.attachment, formData.attachment.name);
      } else {
        form.append('attachment', '');
      }
      challengeService.createTopic(form).then(([response, error]) => {
        if (response) {
          dispatch(alertActions.success('Send Successfully'));
          setFormData(prevState.current);
          setValidated(false);
        } else if (error) {
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderActions.loader(false));
      })
    }else{
      dispatch(alertActions.error('Please select the asset .'));
    }
    
  }, [dispatch, formData.user, formData.superior_url, formData.message, formData.attachment , formData.asset])


  const Submit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() && validateCaptcha.current.validated()) {
      saveCreateData();
    }
  }, [saveCreateData])

  const handleChangeAsset = useCallback((data) => {
    setFormData({
      ...formData,
      asset: data.value
    })
  }, [formData])

  return (
    <Row >
      <Col>
        <Form noValidate validated={validated} onSubmit={Submit}>
          <Form.Group className="text-dark" controlId='ASSET'>
            <Form.Label className="text-white" >Asset</Form.Label>
            <AsyncSelect   name='asset' value={formData.asset} asyncMethod={(e) => challengeService.getAsset(e)} getData={(e) => handleChangeAsset(e)} />
          </Form.Group>
          <FormBlock fields={formFields} size={12} getData={(e) => handleChange(e)} formData={formData} />
          <Captcha  ref={validateCaptcha} />
          <Button type='submit' >Submit</Button>
        </Form>
      </Col>
    </Row>
  )
}
export default ChallengesForm;