import React, { useCallback  } from "react";
import { Container } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { Storage } from "../../helpers";
import ModalForm from "../../shared/ModalForm";
import * as memberPackageService from '../../services/member';
import * as alertActions from '../../actions/alert';

const InviteMember = ({  isVisible, hide , getMembers , getPackage }) => {

  const dispatch = useDispatch();
  const userData = {
    name: '',
    email: '',
    user : Storage.getSession().id
  };


  const closeModal = useCallback((data) => {
    hide();
    if(data){
      memberPackageService.inviteMember(data).then(([response , error]) => {
        if(response){
          dispatch(alertActions.success('Invited Successfully.'));
          getMembers();
          getPackage();
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
      })
      }
      
  }, [ hide  , dispatch , getMembers , getPackage ]);


  const fileds = [
    [{ label: 'Name', name: 'name', type: 'text', required: true, placeholder: 'Name', sm: 12, md: 12, lg: 12, xl: 12 },
    { label: 'Email Address', name: 'email', type: 'email', required: true, placeholder: 'Email Address', sm: 12, md: 12, lg: 12, xl: 12 }],
  ]
  
  return (
    <>
      <Container fluid>
        {isVisible ?
          <ModalForm
            show={isVisible}
            onHide={(e) => closeModal(e)}
            data={userData}
            fields={fileds}
            title={'Invite User'}
            button={'Send Invite'}
          />
          : null}
      </Container>
    </>
  );
}

export default InviteMember;