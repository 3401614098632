import React, { useState, useCallback, useEffect , useRef } from 'react';
import loginimg from '../../assets/images/login-img.svg';
import logo from '../../assets/images/qratalogo.png';
import { Container, Row, Col, Form, Button, Jumbotron, InputGroup, Alert, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as memberPackageServer from '../../services/member';
import { useParams } from 'react-router-dom';
import { REGEXES } from '../../helpers/constants';
import * as loaderService from '../../actions/loader';
import { useDispatch, useSelector } from 'react-redux';
import * as alertService from '../../actions/alert';
import AlertAndLoader from '../../shared/AlertAndLoader';
import * as authActions from '../../actions/auth';
import * as userService from '../../services/user';
import { Storage } from '../../helpers/storage';

function CreateMemberPassword({ history }) {

  const dispatch = useDispatch();
  
  const [inputs, setInputs] = useState({ invited_code: '' , password: '', confirmPassword: '' });
  const [errorMessage, setErrorMessage] = useState();
  const [validRegex1, setValidRegex1] = useState();
  const [invalidRegex1, setInvalidRegex1] = useState();
  const [successMessage , setSuccessMessage] = useState('');
  const [ isLoading , setIsLoading] = useState(false);
  const { password, confirmPassword } = inputs;
  const { token } = useParams();
  const [showPasswordObj, setShowPasswordObj] = useState({});

  const prevState = useRef(inputs);

  useEffect(() => {
    if(token){
      setInputs({ invite_code: token , password: '', confirmPassword: '' });
    }
  }, [token]);


  const validateForm = useCallback(() => {
    let retVal = true;
    let setMessage = '';
    if (!password && !confirmPassword) {
      setMessage = 'Password & Confirm Password are required fields';
      retVal = false;
    } else if (password && !confirmPassword) {
      setMessage = 'Confirm Password is a required field';
      retVal = false;
    } else if (!password && confirmPassword) {
      setMessage = 'Password is a required field';
      retVal = false;
    } else if (password && confirmPassword && password !== confirmPassword) {
      setMessage = 'Password & Confirm Password does not match';
      retVal = false;
    } else if (invalidRegex1) {
      setMessage = 'Password & Confirm Password does not match the required password pattern';
      retVal = false;
    }
    setErrorMessage(setMessage);
    return retVal;
  },[confirmPassword, password , invalidRegex1]);

  // on textfield change
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
    setErrorMessage('');
    const validRegex = REGEXES.PASSWORD.test(value);
    if (value && name === 'password') {
      setValidRegex1(validRegex);
      setInvalidRegex1(!validRegex);
    } else if (!value && name === 'password') {
      setValidRegex1();
      setInvalidRegex1();
    }
  }

  const callLogin = useCallback((userName) => {
    dispatch(loaderService.loader(true));
    dispatch(authActions.login({username: userName, password: password}));
    dispatch(loaderService.loader(false));

}, [dispatch, password]);

  const authenticated = useSelector(state => state.auth.authenticated);
  const loginData = useSelector(state => state.auth.loginData);

  const getLog = useCallback(() => {
    if(Storage.getSession()){
        userService.getLog(Storage.getSession().id).then(([response , error ]) => {
            if(response){
                Storage.setLog(response.results[response.count - 1].id);
            }else if(error){
                dispatch(alertService.error(error.response));
            }
        })
    }
},[dispatch])

useEffect(() => {
    if (authenticated && loginData) {
        Storage.setSession(loginData);
        getLog();
        if(loginData.permissions.includes('qrata.front_office_access')){
            if(loginData.membership_plan) Storage.removeSearchCount();
            Storage.removeLoginState();
            localStorage.setItem("isExistingUser", true)
            //history.push('/home');
            history.push('/MemberPledge');
        }else {
        Storage.removeAll();
        dispatch(alertService.error('Invalid Credentials.'));
        }
    }
}, [authenticated, loginData, history , getLog , dispatch]);

  // on form submit
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      dispatch(loaderService.loader(true));
      memberPackageServer.verifyMember(inputs).then(([response, error]) => {
        if (response) {
          setInputs(prevState.current);
          setIsLoading(false);
          dispatch(loaderService.loader(false));
          dispatch(alertService.success('Your password has been reset successfully.'));
          setSuccessMessage('Ready to login...');
          callLogin(response.username);
          // setTimeout(()=> {
          //    history.push('/login');
          // },[4000])
        } else if (error) {
          setIsLoading(false);
          dispatch(loaderService.loader(false));
          setErrorMessage(error.response);
        }
      });
    }
  }, [inputs, history , validateForm , dispatch ]);

  const handleShowPassword = (name) => {
    setShowPasswordObj({
      ...showPasswordObj,
      [name]: !showPasswordObj[name]
    })
  }

  return (
    <>
    
    <AlertAndLoader />
    <Jumbotron className="d-flex align-items-center min-vh-100 mb-0">
      <Container>
        <Row>
          <Col md={6} className="d-md-block d-lg-block d-none " >
            <h1><span className="text-green">World's Most</span> Reliable Knowledge - <span className="text-green">Guaranteed</span></h1>
            <img width="100%" height="100%" src={loginimg} alt="er" />
          </Col>
          <Col md={6} sm={12} className="bg-light align-self-center p-5 rounded shadow  bg-white" >
            <Row className="text-center no-gutters my-4">
              <Col md={12}>
                <img className="mb-4" width="180px" src={logo} alt="er" />
              </Col>
              <Col md={12}>
                {/*  <img width="180px"  src={logo} alt="er" />  */}
                <h5 className="login-title">Please Create Your Password</h5>
              </Col>

            </Row>
            <Form noValidate onSubmit={handleSubmit}>

              {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
              {successMessage ? <Alert variant="success">{successMessage}</Alert> : null}
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-ctrlPassword">
                    <FontAwesomeIcon className="icon-green" icon="key" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type={showPasswordObj.password === true ? "text" : "password"} autoComplete='new-password' placeholder="Password" name="password" value={password} onChange={handleChange} isValid={validRegex1} isInvalid={invalidRegex1} />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-ctrlPassword">
                    <FontAwesomeIcon icon={showPasswordObj.password === true ? 'eye-slash' : 'eye'} size="lg" onClick={() => handleShowPassword("password")}/>
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">Password must be 6 to 14 charaters long. Must Include lowercase and uppercase alphabetic characters, numbers and special characters</Form.Control.Feedback>
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-ctrlPassword">
                    <FontAwesomeIcon className="icon-green" icon="key" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type={showPasswordObj.confirmPassword === true ? 'text' : "password"} autoComplete='re-new-password' placeholder="Confirm Password" name="confirmPassword" value={confirmPassword} onChange={handleChange} />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-ctrlPassword">
                    <FontAwesomeIcon icon={showPasswordObj.confirmPassword === true ? 'eye-slash' : 'eye'} size="lg" onClick={() => handleShowPassword("confirmPassword")}/>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <Row className=" my-4">
                <Col md={8} className="text-left"/>
                <Col md={4} className="text-right">
                  <Button className="btn-green" variant="primary" type="submit">
                    {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
    </>
  );
}

export default CreateMemberPassword;