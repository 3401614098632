import React, { useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HasPermission } from '../HasPermission';

const DateField = ({ label, id, permission, md, sm, lg, xs, xl, disabled, value, placeholder, name, handleEvent, isClearable }) => {
    const [startDate, setStartDate] = useState('');

    useEffect(() => {
        if (value) { setStartDate(new Date(value)) } else { setStartDate(new Date()) };
    }, [value])

    const handleChange = (date) => {
        const data = { name: name, value: new Date(date).toISOString() };
        handleEvent(data);
    }
    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        {label ? <Form.Label className="d-block">{label}</Form.Label> : ''}
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={(date) => handleChange(date)}
                            dateFormat={placeholder}
                            showMonthYearPicker
                            disabledKeyboardNavigation
                            isClearable={isClearable}
                            disabled={disabled}
                        />
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

DateField.propTypes = {
    id: PropTypes.string,
    isClearable: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    permission: PropTypes.any,
    opts: PropTypes.any,
    md: PropTypes.any,
    sm: PropTypes.any,
    lg: PropTypes.any,
    xs: PropTypes.any,
    xl: PropTypes.any,
    handleEvent: PropTypes.func.isRequired
};

DateField.defaultProps = {
    id: '',
    name: '',
    value: '',
    isClearable: true,
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: 'dd/MM/yyyy',
    permission: '',
    label: '',
    opts: []
};
export default DateField;