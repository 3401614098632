import axiosClient from '../helpers/axiosClient';
import { Storage } from '../helpers/storage';

import { ACCOUNT } from '../helpers/actionTypes'
import { createAction } from '../actions/actionBase';

export function login(data) {
    return createAction({
        type: ACCOUNT.LOGIN,
        action: () => axiosClient.post('login/', data)
    });
}

export function logout() {
    return createAction({
        type: ACCOUNT.LOGOUT,
        action: () => userLogout()
    });
}

const userLogout = () => {
    Storage.removeAll();
    window.location.reload();
};

export function updateSession(data) {
    return createAction({
        type: ACCOUNT.UPDATE_SESSION,
        action: () => Storage.updateSession(data)
      });
  }