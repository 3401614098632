import React from 'react';
import { Container, Row, Col, ListGroup, InputGroup, FormControl, Card } from 'react-bootstrap';
// import news from "../../assets/images/news/news-1.jpg";
import travel from "../../assets/images/news/travel.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const News = () => {
   return (
      <>
         <Container className="my-5">
            <Row className="my-3">
               <Col md={12}>
                  <h2>Qrata News & Updates</h2>
               </Col>
            </Row>
            <Row>
               <Col xs={12} md={6}>
                  <InputGroup className="mb-3">
                     <FormControl
                        className="border-right-0"
                        size="lg"
                        placeholder="Search news..."
                        aria-label="search"
                        aria-describedby="basic-addon1"
                     />
                     <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1" className="bg-white border-left-0">
                           <FontAwesomeIcon className="text-info nav-icons" icon="search" size="sm" />
                        </InputGroup.Text>
                     </InputGroup.Prepend>
                  </InputGroup>
               </Col>
            </Row>
            <Row>
               <Col md={9}>
                  <Row>
                     <Col md={4} className="mb-4">
                        <Card>
                           <Card.Img variant="top" src={travel} />
                           <Card.Header className="text-right py-1 border-0"><small>24 Nov, 2020</small></Card.Header>
                           <Card.Body className="py-3">
                              <Card.Title>
                                 <Card.Link className="text-dark" href="/newsdetail">How is Qrata going to accomplish this task?</Card.Link>
                              </Card.Title>
                              <Card.Text>
                              The Internet is ginormous and content is being added to it at an ever growing at an exponential rate.
               </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col md={4} className="mb-4">
                        <Card>
                           {/* <Card.Img variant="top" src={travel} /> */}
                           <iframe title='youtube-link' width="100%" height="150px" src="https://www.youtube.com/embed/TYbJ95G2_80" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                           <Card.Header className="text-right py-1  border-0"><small>24 Nov, 2020</small></Card.Header>
                           <Card.Body className="py-3">
                              <Card.Title>
                                 <Card.Link className="text-dark" href="#"> The world’s Curated Superior Database!</Card.Link>
                              </Card.Title>
                              <Card.Text>
                              Fake news, fake videos, lies and bad or outdated information on the Internet is a disease that is spreading .. 
               </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col md={4} className="mb-4">
                        <Card>
                        <iframe title='youtube-link' width="100%" height="150px" src="https://www.youtube.com/embed/TYbJ95G2_80" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                           <Card.Header className="text-right py-1  border-0"><small>24 Nov, 2020</small></Card.Header>
                           <Card.Body className="py-3">
                              <Card.Title>
                                 <Card.Link className="text-dark" href="#">Qrata’s philosophy </Card.Link>
                              </Card.Title>
                              <Card.Text>
                              While we do cover many topics that are opinionated our goal with these is to present the most logical and truthful opinions 
               </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                     <Col md={4} className="mb-4">
                        <Card>
                        <iframe title='youtube-link' width="100%" height="150px" src="https://www.youtube.com/embed/TYbJ95G2_80" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                           <Card.Header className="text-right py-1  border-0"><small>14 Nov, 2020</small></Card.Header>
                           <Card.Body className="py-3">
                              <Card.Title>
                                 <Card.Link className="text-dark" href="#">Experience, Education & Learning</Card.Link>
                              </Card.Title>
                              <Card.Text>
                                 Certain underlying medical conditions can take a toll on your body and complicate health risks. Diabetes is one of them...
               </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
            

                  </Row>
               </Col>
               <Col md={3} className="p-md-0">
                  <h5>Latest News</h5>
                  <ListGroup variant="flush">
                     <ListGroup.Item className="bg-transparent pl-0 ">How is Qrata going to accomplish this task?</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0">The world’s Curated Superior Database!</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0"> Qrata’s philosophy is that there is no need for outdated</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0">Experience, Education & Learning</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0">Business, Commerce & Finance</ListGroup.Item>
                  </ListGroup>
                  <Row className="no-gutter">
                     <Col md={12} className="text-center">
                        <span className="qrataads-300-250 bg-light my-3">
                           300x250 - Advertisement Banner
         </span>
                     </Col>
                     <Col md={12} className="text-center">
                        <span className="qrataads-300-250 bg-light my-3">
                           300x250  - Advertisement Banner
         </span>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Container>
      </>
   )
}
export default News;