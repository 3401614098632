import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

// const BASE = 'criteria/';

export async function getPaypalToken() {
  return await handleApi(axiosClient.get(`paypal/`));
}

export async function paymentCheckout(data) {
  return await handleApi(axiosClient.post(`paypal_checkout/` , data));
}

export async function paymentConfirmation(data) {
  return await handleApi(axiosClient.post(`paypal_pay_details/` , data));
}
