import React, { useState , useEffect , useCallback} from 'react';
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import * as bookmarkService from '../services/bookmark';
import * as alertAction from '../actions/alert';
import * as loaderAction from '../actions/loader';
import { useDispatch } from 'react-redux';

const ReactTree = ({openModal , getData , data , getAsset}) => {

  const dispatch = useDispatch();
  const [expandable, setExpandable] = useState(false);
  // const [expandedKeys, setExpandedKeys] = useState([])

  const editData = [{
    id: "0-0",
    title: "root",
    children: data
  }];


  const [id , setId] = useState('');

  const onExpand = (keys) => {
    setExpandable(false);
  };


  const loop = (data) =>
    data.map((item) => {
      if (item.children && item.children.length) {
        return (
          <TreeNode key={item.id}  data-value={item.id} id={'node'+item.id} title={item.title}>
            {loop(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.id}  data-value={item.id} id={'node'+item.id} title={item.title} />;
    });

  // const switcherIcon = (obj) => {
  //   if (obj.isLeaf) {
  //     return "";
  //   }
  //   return (
  //     <i className="rc-tree-switcher-icon">
  //       <svg
  //         viewBox="0 0 1024 1024"
  //         width="1em"
  //         height="1em"
  //         fill="currentColor"
  //         aria-hidden="true"
  //       >
  //         <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
  //       </svg>
  //     </i>
  //   );
  // }


  const createMenu = useCallback((evnt) => {
    let message = document.createElement('div');
    message.id = 'menu';
    message.style.left = evnt.pageX + 20 + "px";
    message.style.top = evnt.pageY + "px";
    message.innerHTML = '<li id="newFolder">Add New Folder</li><li id="removeFolder">Remove Folder</li><li id="renameFolder">Rename Folder</li>'
    document.body.append(message);
 },[])

  const contextMenu = useCallback(({ event, node }) => {
    setId('');
     const getMenu = document.getElementById('menu');
     if (getMenu === null ) {
      createMenu(event);
     }else if(getMenu !== null) {
       getMenu.remove();
       createMenu(event);
     }
  },[createMenu])

  const removeFolder = useCallback(() => {
    if(id !== ''){
      dispatch(loaderAction.loader(true));
      bookmarkService.deleteFolder(id).then(([response , error]) => {
         setId('');
          dispatch(alertAction.success('Remove Folder Successfully.'));
           getData();
         if(error){
          dispatch(alertAction.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  },[id, dispatch , getData])


  useEffect(() => {
    document.onclick = function(e) {
      const getId = e.target.id;
      if(getId === 'newFolder')  openModal(id , 'create');
      if(getId === 'removeFolder') removeFolder();
      if(getId === 'renameFolder') openModal(id , 'rename');
      const getMenu = document.getElementById('menu');
      if(getMenu) getMenu.remove();
    }
  }, [removeFolder, openModal , id])

  useEffect (() => {
    setId('')
    document.oncontextmenu = function (e)
    {
      const dataValue = e.target.parentElement.parentNode.attributes.getNamedItem("data-value");
      if(dataValue !== null){
        setId(dataValue.value);
      }
    }
  },[])


  return (
    <>
      <Tree
        onExpand={(e) => onExpand(e)}
        autoExpandParent={expandable}
        onRightClick={contextMenu}
        onSelect={(e) => getAsset ? getAsset(e[0]) : null}
        // draggable
        // onDragStart={this.onDragStart}
        // onDragEnter={this.onDragEnter}
        // onDrop={this.onDrop}
        //switcherIcon={switcherIcon}
      >
        {loop(editData)}
      </Tree>
    </>
  )
}
export default ReactTree;