import React, { useState, useRef, useEffect, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as alertActions from '../actions/alert';

const Captcha = forwardRef((props, ref) => {

  const dispatch = useDispatch();
  const captchaID = useRef(null);
  const cpatchaTextBox = useRef('');
  const [code, setCode] = useState('');

  // Generate Captcha 
  const createCaptcha = useCallback(() => {

    captchaID.current.innerHTML = '';
    const charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    let lengthOtp = 6;
    const captcha = [];
    for (var i = 0; i < lengthOtp; i++) {

      let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) === -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    let canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 110;
    canv.height = 38;
    let ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    setCode(captcha.join(''));

  captchaID.current.appendChild(canv);
  }, [])

  // Validate Captcha
  // const validated = useCallback(() => {
  //   if (cpatchaTextBox.current.value === code) {
  //     console.log('True');
  //   } else {
  //     dispatch(alertActions.error('Invalid Captcha Code .'));
  //     console.log('False');
  //     createCaptcha();
  //   }
  // }, [code, createCaptcha, dispatch])

  
  useEffect(() => {
    createCaptcha();
  }, [createCaptcha])

  useImperativeHandle(ref, () => ({

    validated() {
      if (cpatchaTextBox.current.value === code) {
        cpatchaTextBox.current.value = '';
        createCaptcha();
        return true;
      } else {
        dispatch(alertActions.error('Invalid Captcha Code .'));
        return false;
      }
    }
  }));

  return (
    <>
      <Row className={props.styleCaptcha !== '' && props.styleCaptcha !== undefined ? props.styleCaptcha : ' mb-3 px-3 no-gutters'} >
        <Col md={6} sm={12} xs={6} >
         <Row>
          <div className="captcha-number py-3 col-md-8 mb-1 text-center "  ref={captchaID}></div>
          {props.login ? 
          <input type="text"  required placeholder="Enter Captcha"   ref={cpatchaTextBox} />
          :
          <Form.Control className="border-0 fs-20 col-md-12" type="text"  required placeholder="Enter Captcha"   ref={cpatchaTextBox} />}
         </Row>
        </Col>
      </Row>
    </>
  )
})
export default Captcha;