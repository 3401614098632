import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";


const RenewMembershipPopup = ({show,pending}) => {

  return (
    <Modal show={show} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
     <Modal.Header>
          <Modal.Title>Membership Expired</Modal.Title>
        </Modal.Header>
        { pending ? 
        <Modal.Body>Your membership is in pending stage after approval you must be renewed to use our services.</Modal.Body>
        :
        <Modal.Body>Your membership has expired and must be renewed to use our services.</Modal.Body>

        }
        <Modal.Footer>
        <Link to="/membership">
          <Button variant="danger">
            Renew Membership
          </Button>
        </Link>
        </Modal.Footer>
    </Modal>
  );
}

export default RenewMembershipPopup;