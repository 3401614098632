import React from 'react';
import { Button, Modal } from "react-bootstrap";
import useConfirm from '../hooks/useConfirm.jsx';

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();
     return ( <Modal
        show={confirmState.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{textAlign: 'center'}}>
          <h4>{confirmState?.text && confirmState.text}</h4>
          <br/>
          <Button variant="primary" onClick={onConfirm}>Yes</Button>
          &nbsp;&nbsp;
          <Button variant="secondary" onClick={onCancel}>No</Button>
        </Modal.Body>

      </Modal>)
};
export default ConfirmDialog;