import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

export async function login(data) {
  return await handleApi(axiosClient.post('login/', data));
}

export async function passwordForgot(data) {
  return await handleApi(axiosClient.post('invite/forget_password/', data));
}
