import React, { useState, useCallback, useRef } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as assetsDiscussionService from '../../services/assetsDiscussion';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { Storage } from '../../helpers';
import FileField from '../../shared/formComponent/FileField';

const CommentsPopUp = ({openPopUp, data, hide}) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        comment: '',
        user: Storage.getSession().id,
        attachment: undefined
    });
    const prevState = useRef(formData);
    
    const handleChange = useCallback((e) => {
        setFormData({
          ...formData,
          [e.name]: e.value
        });
      }, [formData])
    
      const saveCreateData = useCallback(() => {
        dispatch(loaderActions.loader(true));
        const form = new FormData();
        form.append('comment', formData.comment);
        if (formData.attachment) {
          form.append('attachment', formData.attachment)
       };
        assetsDiscussionService.createComment(Number(data._id), form).then(([response, error]) => {
          if (response) {
            setFormData(prevState.current);
            setValidated(false);
            dispatch(alertActions.success('Comment Added Successfully'));
            closeModal();
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderActions.loader(false));
        })
      }, [dispatch, data._id, formData.comment, formData.attachment])
    
      const saveData = useCallback((event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
          saveCreateData();
        }
      }, [saveCreateData])

      const closeModal = useCallback(() => {
        hide();
      }, [hide]); 

        return (
           <Modal show={openPopUp} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
           <Form noValidate validated={validated} onSubmit={saveData}>
             <Modal.Header closeButton>
               <Modal.Title id="contained-modal-title-vcenter">
                 Add Comment
               </Modal.Title>
             </Modal.Header>
                <Modal.Body>
              <Row className="w-100 no-gutters">
                  <Col md={10} xs={9}>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Control type='textarea'  className="rounded-0" required placeholder='Reply type here...' name='comment' value={formData.comment} onChange={(e) => handleChange(e.target)} rows={1} />
                      <span className="attachment-icon text-right">
                    <FileField key="attachment" icon="download" value={formData.attachment} id="attachment" className="p-0" type="file"   fileFormat=".doc,.docx,.pdf,.jpg,.png,.jpeg" handleEvent={(e) => handleChange(e)} placeholder={"Select File"} name='attachment' />
                </span>
                    </Form.Group>
                  </Col>
               
                  <Col md={2} xs={3}>
                    <Button variant="primary" className="w-100 rounded-0" type="submit" ><FontAwesomeIcon size="lg" className="icon-green mr-2" icon="paper-plane" />Send</Button>
                  </Col>
                </Row>
                </Modal.Body>
                </Form>
                </Modal>
        )
}

export default CommentsPopUp;