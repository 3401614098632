import React, { useState, useCallback, useRef } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useBoolean } from '../../hooks/basic'
import ModalForm from '../../shared/ModalForm';
import ReactTree from '../../shared/ReactTree';
import * as bookmarkService from '../../services/bookmark';
import { Storage } from '../../helpers';
import * as alertAction from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { useDispatch } from 'react-redux';
import TooltipHover from '../../shared/TooltipHover';

const FolderTreeComponent = ({folderData, fetchData, show, hide}) => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState({ folder: '' });
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [folderId, setFolderId] = useState('');
  const [titleModal ,  setTitleModal] = useState('');
  const [type , setType] = useState('create');
  const prevState = useRef(editData);

  // FORM FIELDS
  const fields = [
    [{ label: 'Folder Name', name: 'folder', value: editData.folder, type: 'text', required: true, placeholder: 'Folder Name', sm: 12, md: 12, lg: 12, xl: 12 }],
  ];

  const getAssetDataById = useCallback((id) => {
      bookmarkService.getFolderById(id).then(([response , error ]) => {
        if(response){
          setEditData({
            folder : response.title
          })
          showModal();
        }else if(error){
          dispatch(alertAction.error(error.response));
        }
      })
  },[dispatch , showModal])

  const openModal = useCallback((id , type) => {
    setType(type);
    if (id !== '0-0' && type === 'create') {
      setTitleModal('Create Bookmark Folder');
      setFolderId(id);
      showModal();
    }else if(id !== '0-0' && type === 'rename'){
      setTitleModal('Rename Bookmark Folder');
       getAssetDataById(id);
       setFolderId(id);
    }else{
      setTitleModal('Create Bookmark Folder');
      showModal();
    }
    
  }, [showModal , getAssetDataById]);

  const addFolder = useCallback((e) => {
    if (e.folder !== '') {
      const data = {
        title: e.folder,
        member: Storage.getSession().id,
        parent: folderId !== '' ? folderId : null
      }
      dispatch(loaderAction.loader(true));
      bookmarkService.createFolder(data).then(([response, error]) => {
        if (response) {
          dispatch(alertAction.success('Added New Folder.'));
          setFolderId('');
          fetchData();
          hide();
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, folderId, fetchData])

  const renameFolderName = useCallback((e) => {
    if (e.folder !== '') {
      const data = {
        title: e.folder,
      }
      dispatch(loaderAction.loader(true));
      bookmarkService.updateFolder(folderId ,data).then(([response, error]) => {
        if (response) {
          dispatch(alertAction.success('Updated Successfully.'));
          setFolderId('');
          fetchData();
          hide();
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch,folderId, fetchData ])


  const closeModal = useCallback((e) => {
    if (e && type === 'create') {
      addFolder(e);
    }else if((e && type === 'rename') ){
      renameFolderName(e)
    }
    setEditData(prevState.current);
    hideModal();
  }, [hideModal, type , addFolder , renameFolderName])

  return (
    <>
      {isVisible ?
        <ModalForm
          show={isVisible}
          onHide={(e) => closeModal(e)}
          data={editData}
          fields={fields}
          title={titleModal}
          button={'Save'}
        /> : null}
        <Modal show={show} onHide={() => hide()} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Folder {folderData.length === 0 ? <TooltipHover type='icon' iconClass='ml-1' variant='link' icon='info-circle' message='Please Right-click on root to add a folder' handleEvent={() => null} /> : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={12}>
                    <ReactTree 
                    openModal={(e , type) => openModal(e , type)} 
                    getData={() => fetchData()} 
                    data={folderData} 
                    />
                </Col>
            </Row>
        </Modal.Body>
        </Modal>
    </>

  )
}
export default FolderTreeComponent;