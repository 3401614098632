import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';

const ChangePassword = () => {
  return (
    <>
    <Header />
    <h2>Change Password</h2>
    <Footer />
    </>
  )
}
export default ChangePassword;