import React from 'react';
import { Nav, Container, Navbar, Image, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink , Link } from 'react-router-dom';
import logo from "../../assets/images/logo.png";

const Footer = () => {
  return (

    <Container fluid className="bg-dark py-5 d-print-none">
      <Container>
        <Row>
          <Col md={6} className="text-left mb-md-0 mb-3">
            <Navbar.Brand to="/home" as={Link}> <Image src={logo} width="90px" rounded /></Navbar.Brand>
          </Col>
          <Col md={6} className="text-right">
            <Button href="#" variant="outline-light" className="outline-primary mr-md-4"> <FontAwesomeIcon size="sm" className="icon-green" icon="download" /> Plugin</Button>
            <strong className="text-white font-weight-bold"><small> Follow Qrata</small></strong>
            <span className=" d-inline-block text-white mx-3">|</span>
            <span className=" d-inline-block mr-2"><FontAwesomeIcon size="lg" className='text-white' icon={['fab', 'facebook-square']} /></span>
            <span className=" d-inline-block mr-2"><FontAwesomeIcon size="lg" className='text-white' icon={['fab', 'twitter-square']} /></span>
            <span className=" d-inline-block mr-2"><FontAwesomeIcon size="lg" className='text-white' icon={['fab', 'instagram-square']} /></span>
          </Col>
        </Row>
        <hr></hr>
        <Row className=" text-center no-gutters">
        <Col md={6} className="text-left">
          <Nav.Link href="#features"> © 2012 - 20 Qrata LP All Rights Reserved.</Nav.Link>
         </Col>
         <Col md={6} className="text-right">
         <Nav className="float-right text-light">
              <Nav.Link className="text-light" as={NavLink}  to="/support">FAQ</Nav.Link>
              <Nav.Link  className="text-light" as={NavLink} to="/comingsoon">Live Chat</Nav.Link>
              <Nav.Link className="text-light" as={NavLink}  to="/privacy-policy">Privacy Policy</Nav.Link>
              <Nav.Link className="text-light" as={NavLink}  to="/comingsoon">Terms and conditions</Nav.Link>
            </Nav>
         </Col>
        </Row>
      </Container>
    </Container>

  )
}
export default Footer;