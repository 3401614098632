import React, { useState, useCallback } from 'react';
import { Container, Row, Col, Form, Button, Jumbotron,  Alert,  } from 'react-bootstrap';
import logo from '../../assets/images/qratalogo.png';
import { Link } from 'react-router-dom';
import * as memberService from '../../services/member';
import * as alertActions from '../../actions/alert';
import * as loadeActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { history } from '../../helpers';
import AlertAndLoader from '../../shared/AlertAndLoader';

const RegisterPage = (props) => {

    const dispatch = useDispatch();
    // const membership = useSelector(state => state.membership);
    // const authenticated = useSelector(state => state.auth.authenticated);
    // const loginData = useSelector(state => state.auth.loginData);
    const [validated, setValidated] = useState(false);
    const [success, setSuccess] = useState(false);
    const [invalidUsername, setInvalidUsername] = useState(false);
    const [errorUsername, setErrorUsername] = useState('Username is required.');
    const [agree, setAgree] = useState(false);
    const [registerData, setRegisterData] = useState({
        "email": '',
        "first_name": '',
        "last_name": '',
        "username": '',
        "referral_code": ''
    })

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setRegisterData({
            ...registerData,
            [name]: value
        })
    }, [registerData])

    // const autoLogin = useCallback(() => {
    //     if (membership !== undefined && membership.id !== '') {
    //         const inputs = {
    //             username: registerData.username,
    //             password: registerData.password
    //         }
    //         dispatch(authActions.login(inputs))
    //         // history.push('/checkout/package='+membership.id);
    //     } else {
    //         history.push('/login');
    //     }
    // }, [membership, dispatch, registerData])

    // useEffect(() => {
    //     if (authenticated && loginData) {
    //         Storage.setSession(loginData);
    //         if (membership !== undefined && membership.id !== '') {
    //             history.push('/checkout/package=' + membership.id);
    //         } else {
    //             history.push('/dash');
    //         }
    //     }
    // }, [authenticated, loginData, membership]);



    const addMember = useCallback(() => {
        dispatch(loadeActions.loader(true));
        memberService.registerMember(registerData).then(([response, error]) => {
            if (response) {
                dispatch(alertActions.success('Register Successfully.'));
                setSuccess(true);
                localStorage.setItem('isExistingUser', true)
                setTimeout(() => {
                    history.push('/login');
                }, [4000])
                //autoLogin();
            } else if (error) {
                dispatch(alertActions.error(error.response));
            }
            dispatch(loadeActions.loader(false));
        })
    }, [registerData, dispatch])



    const handleSubmit = useCallback((event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            addMember();
        }
    }, [addMember]);

    const checkExist = (e) => {
        setErrorUsername('Username is required.');
        setInvalidUsername(false);
        const { name, value } = e.target;
        memberService.checkMember(name, value).then(([response, error]) => {
            if (response) {

            } else if (error) {
                console.log(error.response.data.error);
                dispatch(alertActions.error(error.response.data.error));
                if(typeof error.response.data.error !== 'string'){
                    if (name === 'username') {
                        setErrorUsername(error.response.data.error);
                        setInvalidUsername(true);
                    } else if (name === 'email') {
                        dispatch(alertActions.error(error.response.data.error));
                    }
                }
            }
        })
    }

    return (
        <>
            <AlertAndLoader />
            <Jumbotron className="d-flex align-items-center min-vh-100 mb-0 bg-grey-gradient">
                <Container>

                <Row className="justify-content-center">
                        <Col md={7}>
                            <a href="/home">   <img className="mb-2" width="300px" src={logo} alt="er" /></a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                    
                    <Col md={7} sm={12} className="bg-dark align-self-center px-5 py-2 text-white rounded shadow-custom shadow  " >
                            <Row className="text-center no-gutters my-4">
                      
                                <Col md={12}>

                                    {/* <h5 className="login-title"></h5> */}
                                </Col>
                            </Row>
                            {success ?
                                <>
                                    <Alert variant='success'>
                                        Register Successfully.Check your email for verification.
                                    </Alert>
                                </> : ''}
                            <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
                            
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        First Name
    </Form.Label>
                                    <Col sm="9">
                                    <Form.Control type="text" id="basic-firstName" placeholder="First Name" autoComplete='first_name' value={registerData.first_name} required name='first_name' onChange={(e) => handleChange(e)} />
                                    <Form.Control.Feedback type="invalid">First Name is required.</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                      Last  Name
    </Form.Label>
                                    <Col sm="9">
                                    <Form.Control type="text" id="basic-lastName" placeholder="Last Name" autoComplete='last_name' value={registerData.last_name} required name='last_name' onChange={(e) => handleChange(e)} />
                                    <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        User Name
    </Form.Label>
                                    <Col sm="9">
                                    <Form.Control type="text" id="basicUsername" placeholder="Enter username" autoComplete='username' onBlur={(e) => checkExist(e)} isInvalid={invalidUsername} value={registerData.username} required name="username" onChange={(e) => handleChange(e)} />
                                    <Form.Control.Feedback type="invalid">{errorUsername}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                       Email Address
    </Form.Label>
                                    <Col sm="9">
                                    <Form.Control type="email" id="basic-email" placeholder="Email Address" onBlur={(e) => checkExist(e)}  value={registerData.email} required name='email' onChange={(e) => handleChange(e)} />
                                    <Form.Control.Feedback type="invalid">Email Id is required.</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                    Referral Code 
    </Form.Label>
                                    <Col sm="9">
                                    <Form.Control type="text" id='basic-Referal' required placeholder="Referral Code" name='referral_code' value={registerData.referral_code} onChange={(e) => handleChange(e)} />
                                    <Form.Control.Feedback type="invalid">Referral Code is required</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                    </Form.Label>
                                    <Col sm="9" className="d-flex align-items-center">
                                        <Form.Check className="d-inline-block mt-1 mr-1" size="lg"  onChange={() => setAgree(!agree)} inline type="checkbox"  aria-label="radio 1" /><small>I agree the Term & Condition of this website.</small>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label column sm="3">

                                    </Form.Label>
                                    <Col sm="9">
                                    <Button disabled={!agree} className="btn-green" variant="primary" type="submit">
                                            Register
                                        </Button>
                                    </Col>
                                </Form.Group>
                         
                            </Form>
                            <Row className=" my-4">
                                <Col md={12} className="text-center">
                                    Already have an account?<Link to='/login' className="text-primary font-weight-bold pl-1 px-0 py-0">Login</Link>
                                </Col>
                                <Col md={12} className="text-center">
                                Have some questions ? <Link to='/contact' className="text-primary font-weight-bold pl-1 px-0 py-0">Contact us</Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </>
    ) 
}
export default RegisterPage;