import React, { } from 'react';
import { Container, Row, Col, Form, Button, Jumbotron, } from 'react-bootstrap';

import logo from '../../assets/images/qratalogo.png';

const InquiryPage = (props) => {


    return (
        <>

            <Jumbotron className="d-flex align-items-center min-vh-100 mb-0 bg-grey-gradient">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={7}>
                            <a href="/home">   <img className="mb-2" width="300px" src={logo} alt="er" /></a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={7} sm={12} className="bg-dark align-self-center px-5 py-2 text-white rounded shadow-custom shadow  " >
                            <Row className="text-center no-gutters my-4">
                                <Col md={12} className="text-left">
                                    {/* <h5 className="login-title"></h5> */}
                                </Col>
                            </Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        First Name
    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        Last Name
    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        Phone (optional)
    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder="" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        Email
    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="email" placeholder="" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        Volunteering to
    </Form.Label>
                                    <Col sm="9">
                                        <select className="form-control">
                                            <option>Apply as a Expert</option>
                                            <option>General Question</option>
                                        </select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formFile" className="mb-0">
                                    <Form.Label column sm="3">
                                        Credentials
    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="file" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                    </Form.Label>
                                    <Col sm="9" className="d-flex align-items-center">
                                        <Form.Check className="d-inline-block mt-1" size="lg" type="checkbox" aria-label="radio 1" /><small>I agree the Term & Condition of this website.</small>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label column sm="3">

                                    </Form.Label>
                                    <Col sm="9">
                                        <Button className="btn-green" variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </>
    )
}
export default InquiryPage;