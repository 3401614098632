import { capitalizeFirstLetter, handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'member/';


export async function updateMemberData(id , data) {
  return  handleApi(axiosClient.patch(`${BASE}${id}/` , data));
}

export async function registerMember(data) {
  return  handleApi(axiosClient.post(`${BASE}create/` , data));
}

export async function getMemberById(id) {
  return  handleApi(axiosClient.get(`${BASE}?user_id=${id}`));
}

export async function checkMember(name, value) {
return  handleApi(axiosClient.get(`${BASE}check_info/?${name}=${value}`));
}

export async function verifyMember(data) {
  return  handleApi(axiosClient.post(`${BASE}verify_member/`, data));
}

export async function createPassword(data) {
  return  handleApi(axiosClient.post(`${BASE}verify_email/`, data));
}

export async function savePackage(data) {
  return  handleApi(axiosClient.post(`memberpackage/create/`, data));
}

export async function getMemberPackageById(id, pageSize=10, pageIndex=0) {
  return  handleApi(axiosClient.get(`memberpackage/${id}/get/?limit=${pageSize}&offset=${pageIndex*pageSize}`));
}

export async function updateMemberPackage(id , data){
  return  handleApi(axiosClient.patch(`memberpackage/${id}/` , data));
}

export async function deleteMemberPackage(id){
  return  handleApi(axiosClient.delete(`memberpackage/${id}/`));
}

export async function inviteMember(data) {
  return  handleApi(axiosClient.post(`memberpackage/invite/`, data));
}

export async function getMemberListById(id , type) {
  return  handleApi(axiosClient.get(`memberpackage/${id}/invited_list/?invited_type=${type}`));
}

export async function deleteMember(id) {
  return  handleApi(axiosClient.get(`memberpackage/${id}/delete_invite/`));
}

export async function resendInvite(id) {
  return  handleApi(axiosClient.get(`memberpackage/${id}/resend_email/`));
}

export async function createMember(data) {
  return  handleApi(axiosClient.post(`memberpackage/create_member/`, data));
}

export async function updateMember(data) {
  return  handleApi(axiosClient.post(`memberpackage/update/`, data));
}

export async function changePassword(data) {
  return  handleApi(axiosClient.post(`memberpackage/change_password/`, data));
}

export async function memberListing(active , limit , offset) {
  return  handleApi(axiosClient.get(`memberpackage/list/?active=${capitalizeFirstLetter(active)}&limit=${limit}&offset=${offset}`));
}

export async function saveAsPdf(mode , data) {
  return  handleApi(axiosClient.post(`paypal_generate_pdf/?mode=${mode}`, data));
}

// GET COUNTRY LIST
export async function getCountryList() {
  return  handleApi(axiosClient.get(`/country/`));
}


export async function submitMemberPledge(data){
  return await handleApi(axiosClient.post(`${BASE}member_pledge/`, data));
}