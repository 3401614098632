import React, { useState, useCallback, useEffect } from 'react';
import { Spinner } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import * as userService from '../../services/user';
import { useDispatch } from 'react-redux';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import Header from '../layout/header';
import { Redirect } from "react-router-dom";

function VerifyEmail() {

  const dispatch = useDispatch();

  const [ loading , setLoading] = useState(false);
  const { token } = useParams();
  const [redirect, setRedirect] = useState(false);

  const verify = useCallback(() => {
    const form = new FormData();
    form.append('token', token);
    setLoading(true);
    setTimeout(() => {
        dispatch(loaderAction.loader(true));
        userService.verfyEmailWithToken(form).then(([response , error]) => {
          if(!error){
            dispatch(alertActions.success('Email Verified Successfully'));
            setRedirect(true);
          }else if(error){
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
    }, 1000)
  }, [dispatch, token])

  useEffect(() => {
    if(token){
       verify();
    }
  }, [token, verify]);

  return (
        <>
            <Header />
                <h5 className="text-center mt-5">{loading ? <><Spinner animation="border" role="status"/><span className='ml-1'>Verifying...</span></> : ''}</h5>
                {redirect ? <Redirect to="/home" /> : ''}
        </>
    )

}

export default VerifyEmail;