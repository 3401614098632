
import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'query/';
export async function messageListing(user = '', pageSize, pageNumber, status, search='', filter, excludedQuery) {
    return await handleApi(axiosClient.get(`${BASE}1/list/?limit=${pageSize}&offset=${pageNumber}&user=${user}&excludedQuery=${excludedQuery}&status=${status}&${search !== ''?'search='+search :''}&${filter}`));
  }

export async function createComment(id, data) {
    return await handleApi(axiosClient.post(`${BASE}${id}/create_comment/` , data));
  }

export async function getMessageEnums() {
    return await handleApi(axiosClient.get(`${BASE}query_enums/`));
  }

export async function getComment(id,is_member_query ) {
    return await handleApi(axiosClient.get(`${BASE}${id}/comments/?is_member_query=${is_member_query}`));
  }
  