import {  Membership } from "../helpers/actionTypes";
import { Storage } from '../helpers/storage';

const initState = {
  id : Storage.getMembership() ? Number(Storage.getMembership()) : ''
};

export function membership(state = initState, action) {
  
  switch (action.type) {
    case Membership.PACKAGE:
      return {
        ...state,
        id : Number(action.id)
      };
    default:
      return state
  }
}