import { useState , useEffect} from "react";

export function useBoolean(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);
  return [value, setTrue, setFalse];
}

export function useToggle(initialValue) {
  const [value, setValue] = useState(initialValue);

  const toggleValue = () => setValue(!value);
  return [value, toggleValue];
}

export default (ms = 0) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const timer = setTimeout(() => {
      if (!unmounted) {
        setReady(true);
      }
    }, ms);

    return () => {
      unmounted = true;
      clearTimeout(timer);
    };
  }, [ms]);

  return ready;
};