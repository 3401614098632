import React , { useEffect , useState , useRef , useCallback } from 'react';
import * as messageService from '../../services/message';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipHover from '../../shared/TooltipHover';
import { useDispatch } from 'react-redux';
import AsyncAppTable from '../../shared/AsyncAppTable';
import { useBoolean } from '../../hooks/basic';
import MessageModal from './MessageModal';
import { Storage } from "../../helpers";

const MessageListing = ({search , status}) => {

const dispatch = useDispatch();
const [messageData , setMessageData] = useState([]);
const [messageEnums , setMessageEnums ] = useState([]);
const fetchIdRef = useRef(0);
const [loading , setLoading ] = useState(false);
const [pageCount, setPageCount] = useState(0);
const [isVisible, showModal, hideModal] = useBoolean(false);
const [editData , setEditData] = useState('');
const [resultCount, setResultCount] = useState(0);
const [value , setValue ] = useState('');
const [callFetch, setCallFetch] = useState(false);

useEffect(() => {
  messageService.getMessageEnums().then(([response , error]) => {
    if(response){
      setMessageEnums(response);
    }else if(error){
      dispatch(alertActions.error(error.response));
    }
  })
},[dispatch])

const displayMessageQuery = (value) => {
  if(messageEnums.length > 0) {
    const arr = messageEnums.filter((obj) => obj.value === value );
    return arr[0].label;
  }
}

 const fetchData = React.useCallback(({ pageSize, pageIndex, filter }) => {
   const fetchId = ++fetchIdRef.current;
  setLoading(true);
  setTimeout(() => {
    const excludedQuery = [1,5];
    let offset = pageIndex * pageSize;
    if (fetchId === fetchIdRef.current) {
      dispatch(loaderAction.loader(true));
      messageService.messageListing( Storage.getSession().id, pageSize, offset, status, search, filter, excludedQuery).then(([response , error]) => {
        if(response){
          // let result = response.results.filter((obj) => obj.query !== 1 && obj.query !== 5);
          setMessageData(response.results);
          setResultCount(response.count)
          setPageCount(Math.ceil(response.count / pageSize))
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
      setCallFetch(false);
    }
  }, 100)
}, [dispatch , search, status])

const openModal = useCallback((data) => {
  if(data) setEditData(data);
  showModal();
}, [showModal]);


const closeModal = useCallback(() => {
  setEditData('');
  // fetchData(Storage.getSession().id, 10 ,0);
  setCallFetch(true);
  hideModal();
},[hideModal])

const selectColumnFilterContact = (column) => {

  const filterHandler = (e) => {
    setValue(e.target.value);
    column.setFilterDate({...column.filterData, [column.column.id]:Number(e.target.value)})
  }

  return <select 
  className="form-control bg-transparent text-white mb-2 border-secondary"
     name={column.column.id} onChange={(e) => filterHandler(e)} selected={value} value={Number(value)}>
      <option value="">All</option>
      {messageEnums.map((item, index) => item.value ? (
        <option value={Number(item.value)} key={'message' + index} >{item.label}</option>
      ): ' ')}
  </select> 
}

const messageColumns = [
  {
    Header: "Ticket Id",
    accessor: "query_ticket",
    sortType: "basic",
    filtering:true,
  },
  {
    Header: "Subject",
    accessor: "subject",
    sortType: "basic",
    className: 'text-capitalize',
    filtering:true
  },
  {
    Header: "Details",
    accessor: "details",
    sortType: "basic",
    filtering:true,
    className: 'text-capitalize'
  },
  {
    Header: "Type",
    sortType: "basic",
    accessor: "query",
    filtering:true,
    Filter: selectColumnFilterContact,
    Cell: (cell) => ( 
      <div>
        {displayMessageQuery(cell.row.original.query)}
      </div>
    )
  },
  {
    Header: "Action",
    accessor: "action",
    sortingOff: true,
    className: 'text-center',
    Cell: (cell) => (
      <div>
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default'  message='View Ticket ' handleEvent={() => openModal(cell.row.original)} />
      </div>
    ),
  }
];

  return (
    <>
    <MessageModal data={editData} title='Messages' isVisible={isVisible} hide={closeModal} refreshData={() => setCallFetch(true)} />
    <AsyncAppTable
        columns={messageColumns}
        data={messageData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount} 
        filtering={true} 
        callFetch={callFetch}
        />
    </>
  )
}
export default MessageListing;