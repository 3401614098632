export const REACT_SELECT_BASIC_CUSTOM_STYLE = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#12493d',
        padding: 10,
        background: state.isSelected ? '#12493d' : '#fff',
        zIndex:12,
    }),
    input: () => ({
        paddingTop: 5,
        width: 220,
        zIndex:12,
    }),
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? '#ced4da' : '#ced4da',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #ced4da',
        }
    }),

     menu: provided => ({ ...provided, zIndex: 9999 }),
   
    multiValue: (base, state) => ({
        ...base,
        background: '#12493d',
        color: 'white',
        height: 24,
        borderRadius: 8,
        fontSize: 12,
        paddingBottom: 0,
        paddingRight: 7,
        paddingLeft: 14,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex:12,
    }),
    multiValueRemove: (base, state) => ({
        color: 'white',
        background: 'transparent',
    }),
    multiValueLabel: (base, state) => ({
        color: 'white',
        background: 'transparent',
    }),
    
}