export const FORM_REQUEST_HEADERS = {
    'Content-Type': ''
};


export const UserRole = {
    ALL: { id: -1, title: 'All Groups' },
    SUPERADMIN: { id: 2, title: 'Super Admin' },
    ADMIN: { id: 3, title: 'Admin' },
    EDITOR: { id: 4, title: 'Editor' },
    MEMBER : { id : 5 , title : 'Member'},
    EXPERT: { id: 1, title: 'Expert' },
    getRole: id => Object.values(UserRole).find(a => (a.id === Number(id)))
}

export const REGEXES = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,14}$/
};

export const SupportType = {
    TECHNICAL: { id: 1, title: 'Technical', value: 1 },
    PAYMENT: { id: 2, title: 'Payment', value: 2 },
    MEMBERSHIP: { id: 3, title: 'Membership', value: 3 },
    getType: id => Object.values(SupportType).find(a => (a.id === Number(id)))
};

export const QueryType = {
    CONTACT: { id: 1, title: 'Contact', value: 1 },
    SUPPORT: { id: 2, title: 'Support', value: 2 },
    getQueryType: id => Object.values(QueryType).find(a => (a.id === Number(id)))
};

export const Status = { 
    APPROVED : { name: 'APPROVED' , value : 1  , label : 'Approved' },
    UNDER_REVIEW  : { name: 'UNDER REVIEW' , value : 2 , label : 'Under Review'},
    REJECTED : { name: 'REJECTED' , value : 3  , label : 'Rejected' },
    DRAFT : { name: 'DRAFT' , value : 4 , label: 'Draft'},
    getStatus: id => Object.values(Status).find(a => (a.value === Number(id)))
};

export const QueriesStatus = {
    NEW : { name: 'NEW' , value : 1 , label: 'New'},
    ACTIVE  : { name: 'ACTIVE' , value : 2 , label : 'Active'},
    CLOSED : { name: 'CLOSED' , value : 3  , label : 'Closed' },
    getAssetStatus: id => Object.values(QueriesStatus).find(a => (a.value === Number(id)))
}

export const PaymentStatus = {
    PENDING : { name: 'PENDING' , value : 1 , label: 'Pending'},
    APPROVED  : { name: 'APPROVED' , value : 2 , label : 'Approved'},
    UPGRADED  : { name: 'UPGRADED' , value : 3 , label : 'Upgraded'},
    getPaymentStatus: id => Object.values(PaymentStatus).find(a => (a.value === Number(id)))
}

export const DateConsts = {
    DATE_FORMAT: 'M/D/YY',
    TIME_FORMAT: 'h:mm A',
    DATETIME_FORMAT: 'MM/DD/YY h:mm A',
    DB_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    DB_DATE_FORMAT: 'YYYY-MM-DD',
    DB_TIME_FORMAT: 'HH:mm:ss',
    UI_DATETIME_FORMAT: 'MM/DD/YYYY hh:mm A',
    UI_DATE_FORMAT: 'MM/DD/YYYY',
    UI_TIME_FORMAT: 'hh:mm A',
    DATE_ONLY_FORMAT: 'DD MMM YYYY',
    DATE_AND_TIME_FORMAT: 'DD MMM YYYY HH:mm',
    MONTH_YEAR_FORMAT: 'MMM YYYY'
  };

