import React, { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Accordion, Card, InputGroup, FormControl } from "react-bootstrap";
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import * as faqServices from '../../services/faq';
import { useDispatch } from 'react-redux';

const Faq = () => {
  const dispatch = useDispatch();
  const [keyword , setKeyword] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [filterData , setFilterData] = useState('');
  
  const getAllFaq = useCallback(() => {
    dispatch(loaderActions.loader(true));
    faqServices.getActiveFaqs().then(([response, error]) => {
      if (response) {
        setFaqs(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  },[dispatch])

  useEffect(() => {
    getAllFaq();
  },[getAllFaq])

  useEffect(() => {
    if(keyword.length > 2){
      const filtered = faqs.filter(faq => {
        return faq.question.toLowerCase().includes(keyword.toLowerCase())
       })
       setFilterData(filtered);
    }else if(keyword.length === 0){
        setFilterData(faqs);
    }
  },[keyword , getAllFaq, faqs ])

  return (
    <>
      <Container >
        <Row className="justify-content-center" >
          <Col md={12}>
            <h2>FAQ</h2>
          </Col>
          <Col md={12}>
          <InputGroup className="mb-3">
              <FormControl
                type='search'
                className="border-right-0"
                size="lg"
                placeholder="Enter a question,keyword or topic ..."
                aria-label="search"
                aria-describedby="basic-addon1"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1" className="bg-white border-left-0"> <FontAwesomeIcon className="text-info nav-icons" icon="search" size="sm" /></InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
        </Row>
    <Row className="text-left" >
    <Col md={12}>
    <Accordion defaultActiveKey="0">
          {filterData.length > 0
            ? filterData.map((item, index) => (
                <Card key={'faq'+ index}>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      className="text-decoration-none text-dark"
                      variant="link"
                      eventKey={index+1}
                    >
                      <b className="mr-2">{(index+1)}.</b>{item.question}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={index+1}>
                    <Card.Body dangerouslySetInnerHTML={{ __html: item.answer }} />
                  </Accordion.Collapse>
                </Card>
              ))
            : "No Faq"}
        </Accordion>
      </Col>

    </Row>

      </Container>
    </>
  );
};
export default Faq;
