import React from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Storage } from '../../helpers';
const About = () => {
   return (
      <>
         <Container className="my-5">
            <Row className="position-relative">
               <Col md={12} className="text-left">
                  <Alert className="py-4" variant="success">
                     <Alert.Heading> <h2>Welcome to Qrata. The world’s Curated Superior Database!</h2></Alert.Heading>
                     <p>Fake news, fake videos, lies and bad or outdated information on the Internet is a disease that is spreading and is getting worse each day. Qrata’s solution is a database of only the best and most reliable Need-2-Know information that you can always trust - guaranteed.</p>
                  </Alert>
               </Col>
            </Row>
            <Row className="my-3">
               {Storage.getSession() === null ?
               <>
               <Col md={5}>
                  <Row>
                     <Col md={12} className="mb-4">
                        <Link to='/register'>
                           <Button className="w-100 py-4 rounded-0" variant="secondary">
                              <FontAwesomeIcon size="lg" className="icon-green mr-2" icon="user-circle" />
                              I'd like to become a Member
                           </Button>
                        </Link>
                     </Col>
                     <Col md={12} className="mb-4">
                        <Link to='/login'>
                           <Button className="w-100 py-4 rounded-0" variant="warning">
                              <FontAwesomeIcon size="lg" className="icon-green mr-2" icon="user-lock" />
                              I'm already a Member, Log Me In
                           </Button>
                        </Link>
                     </Col>
                     <Col md={12} className="mb-4">
                        <Link to='/about'>
                           <Button className="w-100 py-4 rounded-0" variant="info">
                              <FontAwesomeIcon size="lg" className="icon-green mr-2" icon="book-reader" />
                           I'm curious and would like to learn more
                        </Button>
                        </Link>
                     </Col>
                  </Row>
               </Col>
               </>
                : ''}
               <Col md={Storage.getSession()  ? 12 : 7}>
                  <Row>
                     <Col md={12} className="text-center pt-0 ">
                        <iframe title='youtube-link' width="100%" height="300px" src="https://www.youtube.com/embed/TYbJ95G2_80" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row>
               <Col md={12}>
                  <h2>Qrata’s Mission Statement</h2>
                  <p> “To become one of the most trusted companies in the world”.
                  Qrata’s vision is to create the world’s premier Superior Database to help everyone connected to the Internet worldwide navigate the Internet overload and be the
                  trusted filter that the Internet needs more and more each day.
                  </p>
                  <p>Qrata curates and filters out all the useless junk and outdated garbage leaving you with simply the best, most trustworthy Need-2-Know answers. Some people call it the cliff notes to the
                  Internet.
                   </p>
                  <h2>Why do this?</h2>
                  <p> We are fed up wasting time, money and we were determined to find a better and faster way. Considering someone somewhere has the answer that you
                  are looking for we are pledging to get you to the most trusted and truthful information that we can - fast.
                    </p>
                  <p>Just load our software, it will run in the background on any device, any Operating System and use any Search Engine that you would like. When we can give you a
                  shortcut to the best most trusted answers in our Superior Database we will. If the topic hasn’t been researched and placed into our Superior Database yet just
                  continue your search the same way that you always have. No harm, no foul.
                  </p>
                  <p>Qrata’s philosophy is that there is no need for outdated, untruthful, falsehoods. While we do cover many topics that are opinionated our goal with these is to present
                  the most logical and truthful opinions so that our Members can make their own informed decisions on what is best for themselves and their families to do or believe
                  moving forward. Let’s face it one size does not fit all, but it can fit most if the facts are there to back it up
                  </p>
                  <h2>How is Qrata going to accomplish this task?</h2>
                  <p> The Internet is ginormous and content is being added to it at an ever growing at an exponential rate.
                  Qrata is building the world’s smartest database that is filtered, unbiased, curated, & trustworthy thanks to the hard work and support of our experts that are
                  transparent and available for vetting yourself. By providing our Qrata Members with only the best answers to build knowledge and trust in Need-2-Know topics that
                  should increase your knowledge and improve your life.
                   </p>
                  <h2>How are you prioritizing the buildout of the Superior Database Topics?</h2>
                  <p>
                     We have prioritized topics based on the most requested non-opinionated or minimally opinionated Need-2-Know topics and plan to follow this as our roadmap but we
                     also want our members to know that they have some say in our prioritization of the Superior Database buildout and if we hear enough suggestions on a given topic
                     from enough members we will no doubt move it up the priority research list.</p>
                  <p>But aren’t some facts changing or evolving might be a better word?
                  Yes, we agree with this statement as human knowledge and science advances but our goal at all times is to give our Member’s the most recent agreed upon knowledge
                  from leading experts in Need-2-Know topics to save time and improve their lives and those around them that they care about.
                      </p>

               </Col>
            </Row>
         </Container>
      </>
   )
}
export default About;