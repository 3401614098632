import React from 'react';
import {  Container, Image, Row, Col,  } from 'react-bootstrap';
import cs from "../../assets/images/vc.svg";
const ComingSoon = () => {
  return (
    <>
      {/* <Header /> */}
      <Container>
        <Row className="my-5 position-relative">
          <Col md={12} className="text-center py-4">
            <Image src={cs} width="500" rounded />
            <h2>VERSION 2.0 - COMING SOON</h2>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  )
}
export default ComingSoon;