import React , { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DropDownTree({getValues , data}) {

  const [show , setShow] = useState(false);
  const [value , setValue] = useState('');

  const getDropDownValue = (e) => {
   const value =  e.target.attributes.getNamedItem("data-value").value;
   const name =  e.target.attributes.getNamedItem("data-name").value;
   if(name && value) {
    const data = { name : 'member_folder' , value} ;
    getValues(data);
    setValue(name);
   }
  }

  const RepeatOption = ({ value  }) => {
    return (
     <>
        {value.map((item, index) => (
          <ul className='inner-data' key={"innerData" + item.id}>
          <li data-value={item.id} data-name={item.title} onClick={(e) => getDropDownValue(e)}><FontAwesomeIcon className='ml-2' icon='folder' /> {item.title}</li>
          {item.children !== null ? <RepeatOption key={'component'+index} value={item.children} /> : null}
          </ul>
        ))}
      </>
    );
  };

  return (
      <div className='dropdown-container ' onClick={() => setShow(!show)}>
        <div className='heading'><span className='selectedValue'>{value === '' ? 'Select' : value }</span> <FontAwesomeIcon className='float-right mr-1 mt-1 cursor-pointer' icon='angle-down' /></div>
        <div className='dropdown-wrapper overflow-auto' style={show ? {display : 'block'} : {}}>
        {data.map((item, index) => (
        <ul className='dropDown-list' key={'StartDiv'+index} >
            <li data-value={item.id} data-name={item.title} onClick={(e) => getDropDownValue(e)}  > <FontAwesomeIcon className='ml-2' icon='folder' /> {item.title}</li>
            {item.children !== null ? <RepeatOption key={'component'+index}  value={item.children} /> : null}
            </ul>
        ))}
        </div>
      </div>
  );
}
