import React, { useState, useEffect, useCallback } from 'react';
import { Jumbotron, InputGroup, FormControl, Card, Col, Row, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import area from "../../assets/images/home/area.jpg";
import major from "../../assets/images/home/major.jpg";
import expertsec from "../../assets/images/home/expert-sec.png";
import anywhere from "../../assets/images/home/anywhere.png";
import branch from "../../assets/images/home/branch.jpg";
import { Link } from 'react-router-dom';
import { history, Storage } from '../../helpers';
import * as homeService from '../../services/home';
import { useDispatch } from 'react-redux';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import qratavideo from "../../assets/images/qratavideo.mp4"

const Home = () => {

  const isExistingUser = localStorage.getItem("isExistingUser");
  const dispatch = useDispatch();
  const [catalogData, setCatalogData] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!isExistingUser) {
      history.push('/register');
    } else {
      dispatch(loaderActions.loader(true));
      homeService.getCatalog().then(([response, error]) => {
      if (response) {
        setCatalogData(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
        history.push('/login');
      }
      dispatch(loaderActions.loader(false));
      
    })
    }
    return () => {
      setCatalogData([]);
    }
  }, [dispatch , isExistingUser])

  // const slider = [
  //   { token: 10 },
  //   { token: 40 },
  //   { token: 80 },
  //   { token: 50 }
  // ];

  const footerBtn = [
    { link: '/register', icon: 'user-circle', variant: 'warning', text: "I'd like to become a Member" },
    { link: '/login', icon: 'user-lock', variant: 'secondary', text: "I'm already a Member, Log Me In" },
    { link: '/about', icon: 'book-reader', variant: 'info', text: "I'm curious and would like to learn more" }
  ]

  const ads = ['300x250 - Advertisement Banner', '300x250 - Advertisement Banner', '300x250 - Advertisement Banner'];

  const getData = useCallback(() => {
    history.push(`/search/?q=${search}`);
  }, [search])

  const searchDataByEnter = useCallback((e) => {
    if (e.which === 13 || e.key === 'Enter') {
      getData();
    }
  }, [getData])

  return (
    <>
      {/* <Carousel indicators={false}> */}
      {/* {slider.map((item, index) => (
          <Carousel.Item interval={1000} key={'slider' + index}>
            <Alert variant="success" className="mb-0 text-center">
              <Container>
                <FontAwesomeIcon className="text-info nav-icons" icon="exclamation" size="sm" />
                 You have earned   {item.token} token  since your last login like.
                {/* <Alert.Link href="#"> {item.token} </Alert.Link> */}
      {/* </Container>
            </Alert>
          // </Carousel.Item> */}
      {/* ))} */}
      {/* </Carousel> */}

      <Jumbotron className="text-center home-banner mb-0"  >
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <h1 className="text-white">World's Most Reliable Knowledge - Guaranteed</h1>
            <p className="text-white mb-3">
              Compare all key values for page ranking
              </p>
            <InputGroup className="mb-1">
              <FormControl
                className="border-right-0 rounded-0"
                size="lg"
                value={search}
                placeholder="Search ..."
                aria-label="search"
                aria-describedby="basic-addon1"
                onChange={(e) => setSearch(e.target.value)}
                onKeyPressCapture={(e) => searchDataByEnter(e)}
              />
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1" onClick={() => getData()} className="bg-white rounded-0 border-left-0"> <FontAwesomeIcon className="text-info nav-icons" icon="search" size="sm" /></InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col md={12} className="text-center">
            <span className="qrataads-728-90 bg-light my-2">
              728x90 - Advertisement Banner
            </span>
          </Col>
        </Row>
        <Row>
        <Col md={12} className="text-center mt-5">
        <div className="arrow bounce">
     <a href="#about-experts">   <FontAwesomeIcon className="text-info nav-icons" icon="arrow-down" size="lg" /></a>
     </div>
</Col>


        </Row>
      </Jumbotron>
      <section className="about-exp bg-white" id="about-experts">
        <Row>
          <Col md={6} className="d-flex align-items-center justify-content-center pl-5 ">
            <div className="inner-wrap-box">
              <h2>Content curated by Experts </h2>
              <p>Qrata curates and filters out all the useless junk and outdated garbage leaving you with simply the best, most trustworthy Need-2-Know answers. Some people call it the cliff notes to the Internet.</p>
            </div>
          </Col>
          <Col md={6}>
            <img src={expertsec} className="search-banner-sec" alt="search-g" />
          </Col>
        </Row>
      </section>
      <section className="about-exp mb-0">
        <Row>
          <Col md={6}>
            <img src={anywhere} className="search-banner-sec" alt="search-g" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-center pr-5 ">
            <div className="inner-wrap-box">
              <h2>Anytime,  Anywhere</h2>
              <p>Without changing your favourite Browsers or Search engine, use the Qrata Plugin to compare search results</p>
            </div>
          </Col>
        </Row>
      </section>
      <section className="about-exp  bg-white mb-0">
        <Row>
          <Col md={8} className="  ">
            <div className="inner-wrap-box">
              <video width="100%" controls>
                <source src={qratavideo} type="video/mp4" />
              </video>
              <h2>The biggest names that we were conditioned to trust - Might not be so trust worthy after all.</h2>
            </div>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={12} className="text-center" >
                <span className="qrataads-300-250 bg-light mb-2">
                  300x250 - Advertisement Banner
              </span>
              </Col>
              <Col md={12} className="text-center" >
                <span className="qrataads-300-250 bg-light mb-2">
                  300x250 - Advertisement Banner
              </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      <section className="qrata-login mb-5 d-flex align-items-center">
        <div className="inner-wrap-box w-100">
          <Row className="no-gutters justify-content-md-center w-100">
            <Col md={5} className="text-center mb-4">
              <div className="inner-wrap-box">
                <h1 className="text-white">Organize your search  </h1>
                <p className="text-white">Create folders and websites and bookmark them with your faviourte personalized names</p>
              </div>
            </Col>
          </Row>
          {Storage.getSession() === null ?
            <>
              <Row className="no-gutters justify-content-md-center w-100">
                {footerBtn.map((item, index) => (
                  <Col md={3} className="pr-2" key={'footerBtn' + index}>
                    <Link to={item.link}>
                      <Button className=" w-100 text-truncate py-4 rounded-0" variant={item.variant}><FontAwesomeIcon size="lg" className="icon-green mr-2" icon={item.icon} />{item.text}</Button>
                    </Link>
                  </Col>
                ))}
              </Row>
            </> : ''}
        </div>
      </section>
      <Container className="pb-5" >
        <h2 className="pb-5">Trending  categories  </h2>
        <Row className="text-center">
          {catalogData.length > 0 ? catalogData.map((item, index) => index < 3 ? (
            <Col md={4} key={'cards' + index}>
              <Card >
                <Card.Img variant="top" src={index === 0 ? branch : index === 1 ? area : index === 2 ? major : ''} />
                <Card.Body>
                  <Card.Title style={{ height: "24px", overflow: 'hidden' }}>{item.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ) : '') : ''}
        </Row>
        <Row className="no-gutter my-5">
          {ads.map((item, index) => (
            <Col md={4} className="text-center" key={'adss' + index}>
              <span className="qrataads-300-250 bg-light my-3">
                300x250 - Advertisement Banner
              </span>
            </Col>
          ))}
        </Row>
        <Row className="text-center">
          {catalogData.length > 0 ? catalogData.map((item, index) => index >= 3 ? (
            <Col md={4} key={'cards' + index}>
              <Card >
                <Card.Img variant="top" src={index === 3 ? branch : index === 4 ? area : index === 5 ? major : ''} />
                <Card.Body>
                  <Card.Title style={{ height: "24px", overflow: 'hidden' }}>{item.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ) : '') : ''}
        </Row>
        <Row className="no-gutter my-5">
          {ads.map((item, index) => (
            <Col md={4} className="text-center" key={'adss' + index}>
              <span className="qrataads-300-250 bg-light my-3">
                300x250 - Advertisement Banner
              </span>
            </Col>
          ))}
        </Row>

      </Container>
    </>
  )
}

export default Home;