import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
const PrivacyPolicy = () => {
return (
<>
<Container className="my-5">
   <Row className="my-3">
      <Col md={12}>
      <h2>Privacy Policy</h2>
      </Col>
   </Row>
   <Row>
      <Col md={12} className="font-italic">
      <span className="text-warning">WARNING:</span>  This policy probably needs an update to address the California Consumer Privacy Act (CCPA)
      </Col>
      <Col md={12} className="py-4 text-muted">
      <small>Effective Date: July 1, 2020</small>
      </Col>
      <Col md={12}>
      <p>Qrata Inc. and our partners, affiliates and subsidiaries (hereafter “We”) collect certain information through 
         our downloadable software, superior database and website, located at Qrata.com (our “Website”)(collectively the 
         software, database and Website are our “Technology”), including through the products and services provided at or 
         through our Website as well as our downloadable software. This page (this “Privacy Policy”) lays out our policies 
         and procedures surrounding the collection and handling of data about any living individual who can be identified
         from that data or from that data and other information either in our possession or likely to come into our possession 
         (“Personal Data”).
      </p>
      <p>This Privacy Policy applies only to our Technology and to the products and 
         services provided through our Technology. It does not apply to any third party 
         site or service linked to or accessible through our Technology, or recommended or referred by
         our Technology, through our products or services, or by our staff.  It also does not apply to any 
         other website, product, or service operated by our company or third parties, or to any of our offline 
         activities.
      </p>
      <p>By using our Technology, you agree to the collection and use of information described in this Privacy Policy.</p>
      </Col>
      <Col md={12}>
      <h4>A. Personal Data We Collect</h4>
      <p>
         We collect the following personal data from members who subscribe to our products or services or contact us through the
         contact forms using our Technology: first name, last name,
         e-mail address, telephone, credit card number, credit card billing address and __________.
      </p>
      <p>Whether you opt in to be tracked in detail or not We collect
         anonymous data on how our Technology is used: search term(s), number of page visits and duration, 
         type of browser, type of hardware, device(s) used, and __________ (the “Anonymous Data”).  
         If you opt in to be tracked in detail for compensation by us (based upon the current compensation 
         that We are offering that can be found here: 
      </p>
      <p><a href="https://Qrata.com/membership/termsofcompensation">(https://Qrata.com/membership/termsofcompensation)</a> We collect the above Anonymous 
         Data as well as other potentially personally traceable data like your Internet Protocol (IP) address, 
         age, sex, geographic location while using our Technology, hardware and software used with our Technology 
         and ________ any and all of which may be traceable back to you individually (collectively, “Usage Data”).
      </p>
      <p>
         We also use “cookies” to collect certain information from all users using our Website, including Website visitors 
         who don’t subscribe or buy anything through our Website. Data collected through cookies may include Usage 
         Data (whether or not personal) and Personal Data. A cookie is a string of data our system sends your computer 
         and then uses to identify your computer when you return to our Website.  You can set up most browsers to refuse
         all cookies or to notify you of any cookies you receive. Keep in mind that if you don’t accept cookies, you won’t be
         able to make as efficient use of our Website, and you may not be able to use some features at all.
      </p>
      <p>Our Technology is not intended to collect data from children or from anyone under 18 but 
         if you purchase a family membership from us or you provide your account credentials to anyone (knowingly or unknowingly) you are granting us permission (via your legal authority as their parent and or guardian that you verify upon acceptance of this agreement) to track a minor’s usage upon the same opt in or opt out tracking as defined in this Section A. If you are aware of any minor’s information collected through our Technology 
         that should not have been, please contact us immediately at legaldept@qrata.com.
      </p>
      </Col>
      <Col md={12}>
      <h4>B. Our Use of Personal Data</h4>
      <p>
         We use your Personal Data to create your account, to communicate with you about products and services you’ve purchased,
         to offer you additional products and services,
         to bill you, and to compensate you for any of the ways that We offer to compensate you under the <a href="/home">Compensation Terms</a>
         available to your level of membership status that may change at any time. We also use the information to the extent 
         necessary to enforce our Technology terms of service and to prevent imminent harm to persons or property.
      </p>
      <p>We use cookies so that our Technology can remember you (either anonymously or personally) and provide
         you with the information you’re most likely to need.
         We also use information gained through cookies to compile statistical information about use of our Technology, 
         such as the time users spend at the site and the pages they visit most often. We also use cookies ____________________.
      </p>
      <h6 className="font-italic"> Do Not Track Notice</h6>
      <p>Do Not Track (“DNT”) is a setting in a web browser that
         directs websites not to track your behavior. You can activate the DNT settings through most browsers.
      </p>
      <p>[We do not track users over time and across third party websites, so We do not respond to DNT signals.]</p>
      <p>[We track users over time and across third party websites as follows: ________________________. We _______ (do / do not) respond to DNT signals.] [We use information collected through tracking as follows: __________________________.]</p>
      <p>We have not authorized any third party to track consumers or visitors while they are on our Website, and We are aware of no such tracking</p>
      </Col>
      <Col md={12}>
      <h4>C. Retention of Personal Data</h4>
      <p>We retain your Personal Data so long as it serves the purposes 
         listed in this Privacy Policy, including to operate our services, send you 
         notices or other general messages
         (unless you’ve unsubscribed), to collect and monitor traffic and other
         usage statistics of our Technology, to resolve disputes, to enforce the terms of 
         our Technology’s contracts, and to comply with our legal obligations.
      </p>
      </Col>
      <Col md={12}>
      <h4>D. Transfer of Personal Data</h4>
      <p>We may store or process your data outside your city, province, state, or country. Data storage venues include, without limitation, the United States and the European Union. These and other data storage jurisdictions may operate under privacy and data protection laws different from those of your home jurisdiction. Your submission of data to us using any of our Technology and your consent to this Privacy Policy include consent to such storage and processing outside of your jurisdiction.</p>
      </Col>
      <Col md={12}>
      <h4>E. Contractor and Other Third Party Access to Personal Data</h4>
      <p>We reserve the right to give certain independent contractors access to Personal Data if you ever have opted in or opt in at any time in the future although We will probably never use this right.  All of these contractors will be required to sign contracts in which they promise to protect Personal Data using procedures reasonably similar to ours. (Users will not be third party beneficiaries of these contracts.) We also may disclose Personal Data to attorneys, collection agencies, or law enforcement authorities that provide a bona fide, enforceable court order to address potential AUP violations, other contract violations, illegal behavior or as may be required by law in certain jurisdictions.  Finally, We may share Personal Data in connection with a corporate transaction, like a merger or sale of our company, or a sale of all or substantially all of our assets or of the product or service line you received from us, or a bankruptcy.</p>
      <p>
         As noted above, We compile Anonymous Usage usage statistics from data collected through our Technology and cookies. We may publish those statistics or share them with third parties, but if We ever do, they won’t include Personal Data.
      </p>
      <p>Except as set forth in this Privacy Policy, We do not share Personal Information with third parties.</p>
      </Col>
      <Col md={12}>
      <h4>F. Protection of Personal Data</h4>
      <p>We employ the following data security tools to protect Personally Identifiable Information: ________________________________________________.  We take reasonable steps to select vendors who make very limited and legal use of your Personal Data if We cannot find a vendor that will not use any of your Personal Data. Unfortunately, even with these measures, We cannot guarantee the security of Personal Data. By using our Technology, you acknowledge and agree that We make no such guarantee, and that you use our Technology is at your own risk.</p>
      </Col>
      <Col md={12}>
      <h4>G. Accessing and Correcting Your Personal Data</h4>
      <p>You can access and change any Personal Data We store by changing the settings in the “My Account” section of your user account or by contacting us via e-mail, at legaldept@qrata.com.</p>
      </Col>
      <Col md={12}>
      <h4>H. Special Terms related to the European Economic Area</h4>
      <p>If you are in the European Economic Area (“EEA”), the provisions of this Section H apply to you, effective May 25, 2018.</p>
      <h6 className="font-italic mb-0"> Legal Basis for Processing</h6>
      <p>We may process your Personal Data because you have given 
         us permission to do so (e.g., by sending data through our contact 
         or order forms), because the processing is in our legitimate interests 
         and it’s not overridden by your rights, or because We need to process your Personal Data to comply with the law.
      </p>
      <h6 className="font-italic mb-0"> Your Rights Under the General Data Protection Regulation (“GDPR”)</h6>
      <p>If you wish to know what Personal Data We hold about you, to have us remove it, or otherwise to exercise your rights, please contact us at legaldept@qrata.com. 
         In some cases, you also have the following rights related to your Personal Data:
      </p>
      <ul className="pl-3">
         <li> The right to access, update, or delete your Personal Data.</li>
         <li> The right of rectification—to have your information altered if it is inaccurate or incomplete.</li>
         <li> The right to object to our processing of your Personal Data.</li>
         <li> The right of restriction—to request that We restrict how We process your Personal Data.</li>
         <li>The right to data portability—to receive a copy of the information We have on you in a structured, machine-readable, and commonly used format.</li>
         <li>The right to withdraw consent to our processing of your Personal Data.</li>
         <li>	The right to complain to an EEA data protection authority (a government agency) about our management of your Personal Data.</li>
      </ul>
      </Col>
      <Col md={12}>
      <h4>I. Amendment of This Privacy Policy</h4>
      <p>We may change this Privacy Policy at any time by posting a new version on this page or on a successor page. The new version will become effective on the date it’s posted, which will be listed at the top of the page as the new Effective Date.</p>
      </Col>
      <Col md={12}>
      <h4>J. Contacting Us</h4>
      <p>For questions about this Privacy Policy, please contact us at <a href="/contact">legaldept@qrata.com</a>.</p>
      </Col>
   </Row>
</Container>
</>
)
}
export default PrivacyPolicy;