import React , { useState }  from 'react';
import { Nav, Container, Navbar, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../../assets/images/qratalogo.png";
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../actions/auth';
import { isExpired } from '../../helpers/auth.helper';
import AlertAndLoader from '../../shared/AlertAndLoader';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import * as userService from '../../services/user';
import {  history, Storage } from '../../helpers';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import TooltipHover from '../../shared/TooltipHover';

const Header = (props) => {

  const dispatch = useDispatch();
  const { location } = props;
  const loginData = useSelector(state => state.auth.loginData);
  const [navExpanded , setNavExpanded] = useState(false);

  function handleLogout() {
      if(Storage.getLog()){
        dispatch(loaderActions.loader(true));
        const data = {
          active : false
        }
        Storage.removeToken();
        userService.updateLog(Storage.getLog() , data).then(([response , error]) => {
            Storage.removeLog();
            dispatch(loaderActions.loader(false));
             dispatch(authActions.logout());
           if(error){
            dispatch(loaderActions.loader(false));
            dispatch(alertActions.error(error.response));
          }
        })
      }
  }

  if (isExpired()) {
    handleLogout();
  }


  const closeNav = (link) =>  {
    setNavExpanded(false);
    history.push(link);
  }


  return (
    <>
      <AlertAndLoader />
      <Container id='container d-print-none'>
      </Container>
      <Navbar onToggle={() => setNavExpanded(!navExpanded)}
                expanded={navExpanded}
      
      collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
        <Container>
  
          <Navbar.Brand to="/home" as={NavLink}><Image src={logo} width="80px" rounded /></Navbar.Brand> 
          <Navbar.Toggle  style={{outline : '0'}}  />
          <Navbar.Collapse id="responsive-navbar-nav" >
            <Nav className="mr-auto ml-0" activeKey={location.pathname} >
              
              <Nav.Link  onClick={() => closeNav('/home')}>Home</Nav.Link>
              <Nav.Link onClick={() => closeNav('/about')}>About Us</Nav.Link>
         
              <Nav.Link   onClick={() => closeNav('/membership')}>Membership</Nav.Link>
              <Nav.Link onClick={() => closeNav('/comingsoon')}>Marketplace</Nav.Link>
              <Nav.Link  onClick={() => closeNav('/support')}>Support</Nav.Link>
              <Nav.Link  onClick={() => closeNav('/news')}>News</Nav.Link>
              <Nav.Link onClick={() => closeNav('/contact')}>Contact Us</Nav.Link>
            </Nav>
            
            <Button href="#" variant="warning" size='sm' className="outline-primary mr-4 d-print-none"> <FontAwesomeIcon size="sm" className="icon-green" icon="download" /> Plugin</Button> 
            <div className='py-2'>
            {loginData.id && loginData !== undefined ? <span className="text-primary font-weight-bold"> <Link className='text-primary' to="/dashboard"><FontAwesomeIcon size="lg" className="icon-green mr-2" icon="user-circle" /></Link> Hi! {loginData.first_name + ' ' + loginData.last_name}</span> : ''}
            {loginData.id && loginData !== undefined ? <span className="text-primary ml-3 link cursor-pointer" onClick={() => handleLogout()}>
             <TooltipHover type='icon' btnSize='lg' iconClass='icon-green ' placement='bottom' variant='link' icon='sign-out-alt'  message={'Log Out'} handleEvent={() => null} />
             </span> : <><Button to="/login" as={Link} variant="link" className="text-primary"> Login</Button><Button to="/register" as={Link} variant="link" className="text-primary"> Register</Button></>}
            </div>
            {/* <Button onClick={() => setShow(!show)} variant="link" className="text-primary"> <FontAwesomeIcon size="lg" className="icon-green" icon="bars" /></Button> */}
          </Navbar.Collapse>
        </Container>
        {/* {show ?
          <Row className="no-gutters">
            <Col md={12} className="bg-dark position-absolute w-100  " style={{ right: '0px', top: '56px', zIndex: 1200, }}>
              <hr className="mt-0" />
              <Container>
                <Row className="py-3 w-100">
                  <Col md={3}>
                    <ul className="list-unstyled text-white">
                      <li> <Nav.Link className="text-white" href="/comingsoon">Volunteer Application</Nav.Link></li>
                      <li><Nav.Link className="text-white" href="/comingsoon">Superior Answer (Asset) </Nav.Link></li>
                      <li> <Nav.Link className="text-white" href="/comingsoon">Messages</Nav.Link></li>
                      <li> <Nav.Link className="text-white" href="/comingsoon">Invites</Nav.Link></li>
                      <li> <Nav.Link className="text-white" href="/comingsoon">Wallet</Nav.Link></li>
                    </ul>
                  </Col>
                  <Col md={3}>
                    <ul className="list-unstyled">
                      <li> <Nav.Link className="text-white" href="/comingsoon">Superior Answer Rules</Nav.Link></li>
                      <li><Nav.Link className="text-white" href="/comingsoon">Tracking Details </Nav.Link></li>
                      <li> <Nav.Link className="text-white" href="/comingsoon">Superior Answers Reward </Nav.Link></li>
                      <li> <Nav.Link className="text-white" href="/comingsoon">Live Chat</Nav.Link></li>
                      <li> <Nav.Link className="text-white" href="/comingsoon">Support</Nav.Link></li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          : ""} */}
          
      </Navbar>
    </>
  )
}
export default withRouter(Header);