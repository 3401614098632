import React, { useEffect, useState, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as alertActions from "../actions/alert";
import { useDispatch } from "react-redux";
export const AlertComponent = ({ alert }) => {
  const [alertList, setAlertList] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (alert.message !== undefined && alert.type !== undefined) {
      setAlertList([...alertList, alert]);
      dispatch(alertActions.clear());
    }
  }, [alert, alertList, dispatch])


  useEffect(() => {
    if (alertList.length > 0) {
      const interval = setInterval(() => {
        const list = alertList.slice(1);
        setAlertList(list);
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [alertList]);


  const clearAlert = (id ) => {
    const alert = alertList.filter((obj) => obj.id !== id);
    setAlertList(alert);
  }

  const checkStatusCode = useCallback((code) => {
    let status = '';
    switch (code) {
      case 400:
        status = '400 Bad Request .';
        break;

      case 401:
        status = '401 Unauthorized Access.';
        break;

      case 403:
        status = '403 Forbidden';
        break;

      case 404:
        status = '404 Not Found .';
        break;

      case 405:
        status = '405 Method Not Allowed. ';
        break;

      case 500:
        status = '500 Internal Server Error . ';
        break;

      case 502:
        status = '500 Bad Gateway . ';
        break;

      case 503:
        status = '503 Service Unavailable .';
        break;

      default:
        console.log('');
        break;
    }

    return status;
  }, [])



  const renderAlert = (alert, index) => {
    let newError = ''
    if (alert.type === 'danger' && typeof alert.message.data === 'object' && alert.message.status === 401) {
      newError = checkStatusCode(alert.message.status);
    } else {
      if (alert.type === 'danger' && typeof alert.message.data === 'string') {
        newError = checkStatusCode(alert.message.status);
      } else if (alert.type === 'danger' && typeof alert.message.data === 'object') {
        newError = alert.message.data.error;
      } else if (alert.type === 'danger' && typeof alert.message === 'string') {
        newError = alert.message;
      }
    }

    let notification = '';
    if (alert.type) {
      switch (alert.type) {
        case 'success':
          notification = (<Alert key={index} variant="success" onClose={() => clearAlert(alert.id)}  dismissible><b> <FontAwesomeIcon icon="check" /> Success : </b> {alert.message}</Alert>);
          break;
        case 'info':
          notification = (<Alert key={index} variant="info" onClose={() => clearAlert(alert.id)}  dismissible><b> <FontAwesomeIcon icon="info-circle" /> Info : </b> {alert.message}</Alert>);
          break;

        case 'danger':
          notification = (<Alert key={index} variant="danger" onClose={() => clearAlert(alert.id)}  dismissible><b> <FontAwesomeIcon icon="exclamation-triangle" /> Error : </b> {newError}</Alert>);
          break;

        case 'warning':
          notification = (<Alert key={index} variant="warning" onClose={() => clearAlert(alert.id)} dismissible><b> <FontAwesomeIcon icon="exclamation-circle" /> Warning : </b> {alert.message}</Alert>);
          break;

        default:
          console.error('No type Found');
          break;
      }
      return notification;
    }
  }

  return alertList.map((item, index) => (renderAlert(item, index)))
}