import React, { useEffect, useState } from 'react';
import { Container, Form, ListGroup, Button, Card, Col, Row, Jumbotron } from 'react-bootstrap';
import { convertArrayToObject, history, uuidv4 } from '../../helpers';
import * as memberService from '../../services/member';
import * as membershipService from '../../services/membership';
import { useDispatch } from 'react-redux';
import * as loaderActions from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import { Storage } from '../../helpers/storage';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as paymentService from '../../services/payment';
import { PaymentStatus } from '../../helpers/constants';

const PackageUpgrade = () => {

    const { id } = useParams();
    const userData = Storage.getSession();
    const [currentPackage, setCurrentPackage] = useState({ });
    const [selectedPackage, setSelectedPackage] = useState({ });
    const [amount, setAmount] = useState(0);
    const [monthlyPrice, setMonthlyPrice] = useState(0);
    const [timePeriod, setTimeperiod] = useState(6);
    const [finalDiscount, setFinalDiscount] = useState(0);
    const [paypalData, setPaypalData] = useState({ });
    const dispatch = useDispatch();

    const getPayPalAccessToken = useCallback(() => {
        dispatch(loaderActions.loader(true));
        paymentService.getPaypalToken().then(([result, error]) => {
            if (result) {
                setPaypalData(convertArrayToObject(result));
            } else if (error) {
                dispatch(alertActions.error('Service Unavailable . Please try after sometime .'));
            }
            dispatch(loaderActions.loader(false));
        })
    }, [dispatch]);

    const getPackageData = useCallback(() => {
        if (id) {
            dispatch(loaderActions.loader(true));
            membershipService.getPackageById(id).then(([response, error]) => {
                if (response) {
                    setSelectedPackage(response);
                    setMonthlyPrice(response.monthly_amt * 6);
                    setAmount(response.monthly_amt * 6)
                } else if (error) {
                    history.push('/home');
                }
                dispatch(loaderActions.loader(false));
            })
        }
    }, [dispatch, id ]);

    const getCurrentPackage = useCallback((userId) => {
        dispatch(loaderActions.loader(true));
        memberService.getMemberPackageById(userId).then(([response, error]) => {
            if (response && response.current_package[0]) {
                setCurrentPackage(response.current_package ? response.current_package[0] : { });
                const discount = (response.current_package[0].amount / response.current_package[0].duration) * Math.abs(dateDiff(new Date(), new Date(response.current_package[0].package_expiry)).d);
                setFinalDiscount(discount);
            } else if (error) {
                alertActions.error(error.response);
            }
            dispatch(loaderActions.loader(false));
        })
    }, [dispatch])

    useEffect(() => {
        if (userData.id !== undefined && id !== null && Storage.getSession().member_package.package) {
            getPayPalAccessToken();
            getCurrentPackage(userData.id);
            getPackageData();
        }else {
            history.push('/home');
        }
        return () => {
            setCurrentPackage({ });
            setSelectedPackage({ });
        }
    }, [userData.id, id, getCurrentPackage, getPackageData, getPayPalAccessToken])

    const selectPackage = useCallback((period, type) => {
        let monthly = 0;
        if (type === 'MONTHLY') {
            if (period === 12) {
                monthly = selectedPackage.yearly_amt;
                setMonthlyPrice(monthly);
                setTimeperiod(period);
            }
            else {
                monthly = selectedPackage.monthly_amt * period;
                setMonthlyPrice(monthly);
                setTimeperiod(period);
            }
        } else if (type === 'YEARLY') {
            monthly = period;
            setTimeperiod(365);
        }
        setAmount(monthly);
    }, [selectedPackage.monthly_amt, selectedPackage.yearly_amt])


    const dateDiff = (str1, str2) => {
        var diff = Date.parse(str2) - Date.parse(str1);
        return isNaN(diff) ? NaN : {
            diff: diff,
            ms: Math.ceil(diff % 1000),
            s: Math.ceil(diff / 1000 % 60),
            m: Math.ceil(diff / 60000 % 60),
            h: Math.ceil(diff / 3600000 % 24),
            d: Math.ceil(diff / 86400000)
        };
    }

    const payAmount = useCallback((pay) => {
        if (paypalData.access_token && pay !== 0 && Math.sign(pay) !== -1){
            dispatch(loaderActions.loader(true));
            const ref_id = uuidv4();
            const data = {
                "token": paypalData.access_token,
                "value": `${pay}.00`,
                "ref_id": ref_id
            }
            paymentService.paymentCheckout(data).then(([result, error]) => {
                if (result) {
                    const results = convertArrayToObject(result);
                    const packageData = {
                        "user": Storage.getSession().id,
                        "package": selectedPackage.id,
                        "package_length": timePeriod === 365 ? 365 : 30 * timePeriod,
                        "amount": pay,
                        "order_id": results.id,
                        "ref_id": ref_id,
                        "old_package_id":currentPackage.id,
                        "status": PaymentStatus.PENDING.value
                    }
                    if (results.id) {
                        memberService.savePackage(packageData).then(([response, error]) => {
                            if (response) {
                                response.token = paypalData.access_token;
                                response.type = PaymentStatus.UPGRADED.value;
                                Storage.setMembership(JSON.stringify(response));
                                window.location.href = results.links[1].href;
                            } else if (error) {
                                dispatch(alertActions.error(error.response));
                            }
                        });
                    } else {
                        dispatch(alertActions.error("Unknow error."));
                    }

                    // const update = { 
                    //     "status": PaymentStatus.UPGRADED.value,
                    // }
                    // memberService.updateMemberPackage(currentPackage.id, update).then(([response, error]) => {
                    //     if (response) {
                        // const results = convertArrayToObject(result);
                        // const packageData = {
                        //     "user": Storage.getSession().id,
                        //     "package": selectedPackage.id,
                        //     "package_length": timePeriod === 365 ? 365 : 30 * timePeriod,
                        //     "amount": pay,
                        //     "order_id": results.id,
                        //     "ref_id": ref_id,
                        //     "old_package_id":currentPackage.id,
                        //     "status": PaymentStatus.PENDING.value
                        // }
                        // if (results.id) {
                        //     memberService.savePackage(packageData).then(([response, error]) => {
                        //         if (response) {
                        //             response.token = paypalData.access_token;
                        //             response.type = PaymentStatus.UPGRADED.value;
                        //             Storage.setMembership(JSON.stringify(response));
                        //             // window.location.href = results.links[1].href;
                        //         } else if (error) {
                        //             dispatch(alertActions.error(error.response));
                        //         }
                        //     });
                        // } else {
                        //     dispatch(alertActions.error("Unknow error."));
                        // }  
                    //     } else if (error) {
                    //         dispatch(alertActions.error('Please try after sometime.'))
                    //     }
                    // });
                } else if (error) {
                    if (Storage.getMembership()) {
                        Storage.removeMembership();
                    }
                    dispatch(alertActions.error(error.response));
                }

                dispatch(loaderActions.loader(false));
            })
        } else {
            if(pay === 0 || Math.sign(pay) === -1){
                if(Math.sign(pay) === -1){
                    dispatch(alertActions.error('You need to select the higher amount package for payment.'));
                    return;
                }else {
                    dispatch(alertActions.error('You Can\'t upgrade your package now.'))
                    return;
                }
            }else{
                dispatch(alertActions.error('Please referesh the page to payment.'))
            }
           
        }
    }, [dispatch, currentPackage,selectedPackage, paypalData.access_token, timePeriod])


    
    const options = [
        { label : 'Select' , value: 'Select'},
        { label : '1 month' , value: 1},
        { label : '3 month' , value: 3},
        { label : '6 month' , value: 6},
        { label : '12 month' , value: 12}
    ];

    return (
        <>
            <Container className="pb-5">
                <Row className="text-center py-5">
                    <Col md={12} >
                        <h2>Membership Upgrade Detail</h2>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col md={8}>
                        <Row>
                            <Col md={12} className="d-inline-flex mb-3">
                                <Card className="w-100" >
                                    <Card.Body className="text-left">
                                        <Card.Title className=" text-uppercase p-3 mb-l0">
                                            <Row>
                                                <Col md="5">
                                                    <h2 className="text-warning"> {selectedPackage.name ? selectedPackage.name : ''}</h2>
                                                    <span className="d-block text-muted text-capitalize"><small>{selectedPackage.yearly_amt ? selectedPackage.yearly_amt : '0'}/yr Paid Yearly in advance</small></span>
                                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                                        <Form.Label></Form.Label>
                                                        <Form.Control as="select" onChange={(e) => selectPackage(+e.target.value, 'MONTHLY')}>
                                                            {options.map((obj , index) => 
                                                                
                                                                (
                                                                      selectedPackage.monthly_amt * obj.value > currentPackage.amount ? <option key={index+'optionPayment'} value={obj.value}>{obj.label}</option> : ''
                                                                )
                                                                
                                                            )}
                                                            
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="7 text-right">
                                                    <span className="price-tag text-capitalize">${monthlyPrice}</span>
                                                </Col>
                                            </Row>
                                        </Card.Title>
                                        <ListGroup variant="flush" className="mb-0">
                                            <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{selectedPackage.overlay_ads}</b> on the overlay</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup variant="flush" className="mb-0">
                                            <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{selectedPackage.page_ads}</b>  on the landing page</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup variant="flush" className="mb-0">
                                            <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{selectedPackage.invite_limit}</b> invite in 6 months</ListGroup.Item>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={12}>
                                <Card className="w-100" >
                                    <Card.Body className="text-left">
                                        <Card.Title className=" text-Capitilized p-3 mb-0">
                                            <Row>
                                                <Col md="12">
                                                    <p>Lifetime Memberships that are only available for a limited time.</p>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Form.Group >
                                                                <ListGroup variant="flush" className="mb-4">
                                                                    <ListGroup.Item><Form.Check size="lg" inline checked={selectedPackage.lifetime_amt === amount} type="radio" onChange={() => selectPackage(selectedPackage.lifetime_amt, 'YEARLY')} aria-label="radio 1" /><small>Lifetime Membership</small></ListGroup.Item>
                                                                </ListGroup>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} className="text-right">
                                                            <span className="price-tag text-capitalize">${selectedPackage.lifetime_amt ? selectedPackage.lifetime_amt : '500'}</span>
                                                        </Col>
                                                    </Row>

                                                </Col>

                                            </Row>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Col>
                    <Col md={4}>
                        <Row className="no-gutter">
                            <Col md={12} >
                                <Card className="w-100" >
                                    <Card.Body className="P-0">
                                        <span className="d-block text-muted text-left">Invoice detail</span>
                                        <h6 className="text-primary text-left mt-2 mb-0">Current Membership Plan</h6>

                                        <hr />
                                        <Jumbotron className=" p-3 mb-0">

                                            <Row className="text-left">
                                                <Col xs={6}>
                                                    <label className="font-weight-bold text-dark mb-0"> {currentPackage.package ? currentPackage.package.name : ""}</label>

                                                </Col>

                                                <Col xs={6} className="text-right">
                                                    <p className="mb-0"> {currentPackage.amount ? "$" + currentPackage.amount : ""}</p>

                                                </Col>
                                                <Col xs={6}>

                                                    <label className="font-weight-bold text-dark mb-0"> Duration</label>

                                                </Col>
                                                <Col xs={6} className="text-right">

                                                    <p className="mb-0">{currentPackage.duration ? Math.round(currentPackage.duration / 30) + " Months" : ""}</p>
                                                </Col>
                                                <Col xs={7}>
                                                    <label className="font-weight-bold text-dark mb-0"> {currentPackage.package ? currentPackage.package.name + " Package Left " : ""}</label>

                                                </Col>
                                                <Col xs={5} className="text-right">

                                                    <p className="mb-0">{currentPackage.package_expiry ? Math.abs(dateDiff(new Date(), new Date(currentPackage.package_expiry)).d) + " Days" : ""}</p>
                                                </Col>
                                            </Row>


                                        </Jumbotron>

                                        <h6 className="text-primary text-left mt-3 mb-0">New Membership Plan</h6>

                                        <hr />

                                        <Jumbotron className=" p-3 mb-0 ">

                                            <Row className="text-left">
                                                <Col xs={6}>
                                                    <label className="font-weight-bold text-dark mb-0"> {selectedPackage.name ? selectedPackage.name : ""} </label>

                                                </Col>

                                                <Col xs={6} className="text-right">
                                                    <p className="mb-0">{amount === selectedPackage.lifetime_amt ? "Lifetime" : "$" + amount}</p>

                                                </Col>
                                                <Col xs={6}>

                                                    <label className="font-weight-bold text-dark mb-0"> Duration</label>

                                                </Col>
                                                <Col xs={6} className="text-right">

                                                    <p className="mb-0">{timePeriod === 365 ? 365 + ' Days' : timePeriod + " Months"}</p>
                                                </Col>

                                            </Row>


                                        </Jumbotron>



                                        <h6 className="text-primary text-left mt-3 mb-0">Discount</h6>

                                        <hr />

                                        <div className="d-flex justify-content-between">
                                            <div>{selectedPackage.name ? selectedPackage.name : ""} </div>
                                            <div>{amount === selectedPackage.lifetime_amt ? "Lifetime" : "$" + amount} </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Discount on Package</div>
                                            <div className="text-danger">- ${Math.round(finalDiscount)}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="text-dark font-weight-bold">Payable Amount</div>
                                            <div className="font-weigh text-darkt font-weight-bold"> ${Math.round(amount - finalDiscount)}</div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={12} className="text-right">
                                <Button className="w-100 my-4 px-5 " variant="primary" onClick={() => payAmount(Math.round(amount - finalDiscount))}>Pay</Button>
                            </Col>

                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="text-center">
                        <span className="qrataads-300-250 bg-light my-3">
                            300x250  - Advertisement Banner
                        </span>
                    </Col>
                    <Col md={4} className="text-center">
                        <span className="qrataads-300-250 bg-light my-3">
                            300x250  - Advertisement Banner
                        </span>
                    </Col>
                    <Col md={4} className="text-center">
                        <span className="qrataads-300-250 bg-light my-3">
                            300x250  - Advertisement Banner
                        </span>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PackageUpgrade;