import axios from 'axios';
import { Storage } from '.';
import { SEARCH_URL } from './network';

const baseURL = SEARCH_URL;

const createAxiosClientSearch = () => {
    const instance = axios.create({
        baseURL
    });

    instance.interceptors.request.use(config => {
        config.headers['Content-Type'] = 'application/json';
        config.headers.Accept = 'application/json,text/plain';
        // config.headers['Cache-Control'] = 'no-cache';

        try {
            if(Storage.getToken()) {
                config.headers.Authorization = `Bearer ${Storage.getToken()}`;
            }
            
            return config;
        } catch (err) {
            // return original config if JSON.parse fails
            return config;
        }
    });

    return instance;
};


export default createAxiosClientSearch();