import React, { useEffect }  from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Storage } from '../../helpers';
import Header from '../layout/header';
import Footer from '../layout/footer';
import PageNotFound from '../../shared/PageNotFound';
import RenewMembershipPopup from '../../shared/RenewMembershipPopup';
import { useBoolean } from '../../hooks/basic'

export const FrontendLayout = ({ component: Component, permission, ...rest }) => {
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [isVisibleError, showError, hideError] = useBoolean(false);
  const location = useLocation();
  const allowedPages = ['/membership', '/checkout/package=1', '/checkout/package=2', '/checkout/package=3', '/checkout/package=4', '/checkout/package=5', '/checkout/package=6', '/checkout/package=7', '/checkout/package=8', '/checkout/package=9' , '/payment-confirm','/payment-cancel']

  const pages = ['/home' , '/membership' , '/about' , '/support', '/contact' ,'/comingsoon' , '/search' , '/news' , '/faq', '/Inquiry'];
  const dashboard = ['/dashboard','/paymentConfirmation/package='+Storage?.getMembership()?.id];
  const check = window.location.href.indexOf("payment-confirm/transactionId=")

  useEffect(() => {
    if(Storage.getSession()){
      if(Storage.getSession().member_package.status===2 ){
        hideError()
        hideModal()
      }
      else if ((Storage.getSession().member_package.status===1 || Storage.getMembership()?.id) && Storage.getMembership() && !allowedPages.includes(location.pathname) &&  !dashboard.includes(location.pathname)  ){
        showError() //pending case
      }
      else if((Storage.getSession().membership_plan===false) && Storage.getMembership()===null && !allowedPages.includes(location.pathname) ){

        showModal() //expire plan
      }
      else if((Storage.getSession().membership_plan && Storage.getMembership()===null&& !allowedPages.includes(location.pathname) )){
        showModal()
      }
      else if(((Storage.getSession().membership_plan===false) && Storage.getMembership() && !dashboard.includes(location.pathname) && !allowedPages.includes(location.pathname))){
        if( Storage.getMembership() && !dashboard.includes(location.pathname) && !allowedPages.includes(location.pathname)){
          showError()
        }else if(!allowedPages.includes(location.pathname)){
          showModal()
        }
      }
      else if((Storage.getSession().membership_plan===false) && !allowedPages.includes(location.pathname) && !dashboard.includes(location.pathname)){
        showModal()
      }
      
      else{
        hideError()
        hideModal()
      }
    
  }
}, [location, showModal, hideModal, allowedPages ]);
  let couldShow = false;
  if(Storage.getSession() !== null){
    if (Storage.getSession().permissions && permission) {
      couldShow = Storage.getSession().permissions.includes(permission);
      if (!couldShow) return <PageNotFound />
    } else { couldShow = true; }
  }
  
  const checkPagesValid = (value) => {
    return pages.includes('/'+value.split('/')[1]);
  }
  
  return (
    <>
      <Header />
      <RenewMembershipPopup show={isVisible}/>
      <RenewMembershipPopup show={isVisibleError} pending={true}/>
      
      <Route {...rest} render={matchProps => (
        Storage.getSession() && couldShow ?
        <Component {...matchProps} />
          : checkPagesValid(matchProps.location.pathname) ? 
          <Component {...matchProps} />
          : <Redirect to="/home" />
      )} />
      <Footer />
    </>
  )
};