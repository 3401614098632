import React from 'react';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import news from "../../assets/images/news/news-1.jpg";
const NewsDetail = () => {
   return (
      <>
         <Container className="my-5">
            <Row>
               <Col md={9}>
                  <Row>
                     <Col md={12} className="mb-4">
                        <Card>
                           <Card.Img variant="top" src={news} />
                           <Card.Header className="text-right py-1 border-0"><small>24 Nov, 2020</small></Card.Header>
                           <Card.Body className="py-1">
                              <Card.Title>
                                 <Card.Link className="text-dark" href="#">
                                    <h1>A Coronavirus and diabetes: What you need to know</h1>
                                 </Card.Link>
                              </Card.Title>
                              <Card.Text>
                                 <p> Certain underlying medical conditions can take a toll on your body and complicate health risks. Diabetes is one of them. A lifestyle disorder, when diabetes is not kept under check, it can invite other health problems along the way and prolong recovery and healing time as well. It is also said that with a condition like diabetes, it is far easier to fall prey to diseases. Case in point, the novel coronavirus infection.
                                 While the COVID-19 pandemic can affect any healthy individual, studies say those with a history of chronic illnesses, medical complications (like diabetes, high blood pressure) are deemed to be in a "high-risk" category for developing the infection and showcase moderate to severe symptoms. Hence, the coronavirus scare is all the riskier for those suffering from diabetes.
                  </p>
                                 <h5>Here's why</h5>
                                 <p>While coronavirus, as a disease requires all people to take health into their hands and not risk it, with an acute condition like diabetes, it becomes all the more important to take neccessary precautions.
                                 Your body's as healthy as your immune system is. When a person suffers from diabetes, it doesn't just simply affect the body's blood glucose levels but also compromises the insulin production levels in the body. When it goes for a spin, it can have lasting complications, especially on the immunity. People with high or unmanaged blood sugar levels have less than normal blood flow, which makes it difficult for the body to harness nutrients, natural defences meant to protect the body against several infections and promote healing. Hence, it usually takes a little longer than usual for people with diabetes to recover as well.
                  </p>
                              </Card.Text>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
               </Col>
               <Col md={3} className="p-0">
                  <h5>Related News</h5>
                  <ListGroup variant="flush">
                     <ListGroup.Item className="bg-transparent pl-0">Coronavirus and diabetes: What you need to know...</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0">Relaxation in Visa and Travel Restrictions, All You Need to Know...</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0"> Coronavirus and diabetes: What you need to know...</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0">Coronavirus and diabetes: What you need to know...</ListGroup.Item>
                     <ListGroup.Item className="bg-transparent pl-0">Coronavirus and diabetes: What you need to know...</ListGroup.Item>
                  </ListGroup>
                  <Row className="no-gutter">
                     <Col md={12} className="text-center">
                        <span className="qrataads-300-250 bg-light my-3">
                           300x250 - Advertisement Banner
         </span>
                     </Col>
                     <Col md={12} className="text-center">
                        <span className="qrataads-300-250 bg-light my-3">
                           300x250  - Advertisement Banner
         </span>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Container>
      </>
   )
}
export default NewsDetail;