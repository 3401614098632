import React, { useState, useEffect, useCallback } from 'react';
import { Container, Col, Row, Card, ListGroup, Button } from 'react-bootstrap';
import { history, Storage } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as memberService from '../../services/member';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { PaymentStatus } from '../../helpers/constants';
import { useParams } from 'react-router-dom';

const PaymentSuccess = () => {
   
   const { transactionId } = useParams();
   const dispatch = useDispatch();
   const [packageData, setPackageData] = useState({});
   const savePackage = useCallback(() => {
      if (Storage.getSession() && Storage.getMembership()) {
         dispatch(loaderActions.loader(true));
         const data = {
            transaction_id : transactionId,
            status : 2
         };
         memberService.updateMemberPackage(Storage.getMembership().id ,data).then(([response, error]) => {
            if (response) {
               setPackageData(response);
               if(Storage.getMembership().type === PaymentStatus.UPGRADED.value){
                  let currentSession = Storage.getSession();
                  currentSession.member_package = response;
                  Storage.setMembership(JSON.stringify(response));
                  Storage.setSession(currentSession)
                  window.location.reload(false);
               }
               // Storage.removeMembership();
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
         })
      }else {
          history.push('/home');
      }
   }, [dispatch , transactionId])

   const openlink = useCallback((link) => {
      window.open(link, '_blank');
    },[])

   const pdfAndPrint = useCallback((mode) => {

      const data = {
         "order_id" : packageData.order_id,
         "id" : packageData.id
      }
      dispatch(loaderActions.loader(true));
         memberService.saveAsPdf(mode , data ).then(([response , error]) => {
            if(response){
                  if(mode === 'pdf'){
                     openlink(response);
                  }else if(mode === 'email'){
                     dispatch(alertActions.success('Payment Receipt Emailed Sucessfully.'))
                  }

            }else if(error){
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
         })
   },[packageData.order_id , packageData.id , dispatch , openlink])
  
   useEffect(() => {
      savePackage();
   }, [savePackage])
   
   const print = () => {
      var printContents = document.getElementById("print-area").innerHTML;
      document.getElementById('print-data').innerHTML = printContents;
      window.print();
   }
   return (
      <>
         <div id='print-data' style={{display : 'none'}}></div>
         <Container className="pb-5" id='print-area'>
            <Row className="text-center py-3 mt-4">
               <Col md={12} >
                  <FontAwesomeIcon size="lg" className="icon-green my-3 text-success success-icon" icon="check-circle" />
                  <h1>Payment Successfull.</h1>
               </Col>
            </Row>
            <Row className="justify-content-center">
               <Col md={6}>
                  <Card>
                     <Card.Body>
                        <ListGroup variant="flush">
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              Transactions Id
                  <span className="font-weight-bold"> {packageData.transaction_id ? packageData.transaction_id : '' }</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                             Order Id
                  <span className="font-weight-bold"> {packageData.order_id ? packageData.order_id : '' }</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              Made of Payment
                  <span className="font-weight-bold"> Paypal</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              Payment Status
                  <span className="font-weight-bold"> {packageData.status === PaymentStatus.APPROVED.value ? PaymentStatus.APPROVED.name :  PaymentStatus.PENDING.name }</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              Membership
                  <span className="badge badge-primary badge-pill">{packageData.package === 1 ? 'Silver' : packageData.package === 2 ? 'Gold' : packageData.package === 3 ? 'Platinum' : 'none'}</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              Start Date
                  <span className="font-weight-bold"> {packageData.package_date ? packageData.package_date : ''}</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              End Date
                  <span className="font-weight-bold"> {packageData.package_expiry ? packageData.package_expiry : ''}</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              You have available
                              invite members
                  <span className="font-weight-bold">{packageData.invites_available ? packageData.invites_available : ''}</span>
                           </ListGroup.Item>
                           <ListGroup.Item className="list-group-item d-flex justify-content-between align-items-center">
                              Payment Amount
                  <span className="font-weight-bold">{packageData.amount ? packageData.amount + ' $' : ''} </span>
                           </ListGroup.Item>
                        </ListGroup>
                     </Card.Body>
                  </Card>
               </Col>
               <Col md={12} className="text-center my-2 d-print-none">
                  <Button variant="link" onClick={() => pdfAndPrint('pdf')} className="pr-3 text-decoration-none text-info ">
                     <FontAwesomeIcon size="sm" className="icon-green  text-info mr-2 " icon="file-pdf" />
         Save as PDF
      </Button>
                  <Button variant="link" className="pr-3 text-decoration-none text-info" onClick={() => print()}>
                     <FontAwesomeIcon size="sm" className="icon-green  text-info mr-2 " icon="print" />
         Print Receipt
      </Button>
                  <Button variant="link" onClick={() => pdfAndPrint('email')} className="pr-3 text-decoration-none text-info ">
                     <FontAwesomeIcon size="sm" className="icon-green  text-info mr-2 " icon="envelope" />
         Email Receipt
      </Button>
               </Col>
               <Col md={12} className="text-center d-print-none">
                  <Button className="my-2 px-5 " onClick={() => history.push('/home')} variant="primary">Back to Home</Button>
               </Col>
            </Row>
         </Container>
      </>
   )
}
export default PaymentSuccess;