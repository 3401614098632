// import { useCallback, useContext } from 'react';

/**
 * Creates the function to dispatch all action type derivatives for the given action.
 * @param   {Object}    config          The configuration object.
 * @param   {Function}  config.action   The action function.
 * @param   {Object}    [config.header] The action header.
 * @param   {Object}    config.type     The action type.
 * @returns {Function}
 */
export function createAction({ action, headers = {}, type }) {
    return async(dispatch) => {
      dispatch({ type: type.REQUEST, headers });
      try {
        const payload = await action();
        dispatch({ type: type.SUCCESS, headers, payload });
        return payload.data;
      } catch (error) {
        dispatch({ type: type.FAILURE, headers, error });
        console.log('API ERROR:', error);
        return error;
      }
    };
  }

// export function useBaseAction({ action, headers = {}, type }) {
//   const dispatch = useDispatch();
  
//   const request = useCallback(async () => {
//     dispatch({ type: type.REQUEST, headers });

//     try {
//       dispatch({ type: type.SUCCESS, headers, payload: await action() });
//     } catch (error) {
//       dispatch({ type: type.FAILURE, headers, error });
//       console.log('API ERROR:' + JSON.stringify(error));
//     }
//   }, [action, dispatch, headers, type.FAILURE, type.REQUEST, type.SUCCESS]);

//   return request;
// }
  
  /**
   * Creates the failure, request, and success type derivatives for the given action identifier.
   * @param   {string}  id  The identifier of the action.
   * @returns {Object}
   */
  export function createActionType(id) {
    return {
      FAILURE: `${id}__FAILURE`,
      REQUEST: `${id}__REQUEST`,
      SUCCESS: `${id}__SUCCESS`,
    };
  }
  