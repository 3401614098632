import React from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const TooltipHover = ({ type , btnSize , iconSize , icon , label , btnIcon, linkClass , text , textClass,  message , variant , btnClass , disabled, iconClass , placement , handleEvent}) => {

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {message}
    </Tooltip>
  );

  const handleClickEvent = (e) => {
    if (handleEvent) {
      handleEvent(e)
    }
  }
  
  return (
    <>
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      { type === 'button' ? 
       <Button size={btnSize} disabled={disabled} className={btnClass} onClick={(e) => handleClickEvent(e)} variant={variant}>{label ? label : null}
       { btnIcon ? <FontAwesomeIcon size={iconSize} className={iconClass} icon={btnIcon} /> : null}
       </Button>
       : type === 'icon' ? 
       <FontAwesomeIcon size={iconSize} className={iconClass} onClick={(e) => handleClickEvent(e)} icon={icon} > </FontAwesomeIcon> 
       : type === 'link' ? 
       <span onClick={(e) => handleClickEvent(e)} className={'cursor-pointer  ' + linkClass} ><FontAwesomeIcon size={iconSize} className={iconClass} icon={btnIcon} /></span>
       : type === 'text' ?
       <span onClick={(e) => handleClickEvent(e)} className={textClass}>{text}</span>
       : null
       }
      
    </OverlayTrigger>
    </>
  )
}
TooltipHover.propTypes = {
  type: PropTypes.any.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.any.isRequired,
  textClass: PropTypes.string,
  linkClass: PropTypes.string , 
  text : PropTypes.any,
  variant: PropTypes.string,
  btnClass: PropTypes.string,
  iconClass: PropTypes.string,
  placement: PropTypes.string,
  btnIcon: PropTypes.string,
  iconSize: PropTypes.string,
  btnSize : PropTypes.string,
  handleEvent: PropTypes.func
};

TooltipHover.defaultProps = {
  type: 'button',
  icon:  '',
  label: '',
  textClass: '' , 
  linkClass: '',
  text : '',
  message: 'This is a Tooltip message',
  variant:  'primary',
  btnClass: '',
  iconClass: '',
  placement: 'auto',
  btnIcon: '',
  iconSize: "1x",
  btnSize : '',
  handleEvent: () => undefined
};
export default TooltipHover
