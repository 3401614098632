import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'topic_suggest/';

export async function createTopic(data) {
  return await handleApi(axiosClient.post(`${BASE}create/` , data));
}

export async function topicRequestListing(user = '', limit , offset, ordering, filter, search, status=1) {
  return await handleApi(axiosClient.get(`${BASE}list/?status=${status}&${user !== ''?'user='+user+'&':''}${search !== ''?'search='+search+'&':''}limit=${limit}&offset=${offset}&ordering=${ordering}&${filter}`));
}

export async function createComment(id, data) {
  return await handleApi(axiosClient.post(`${BASE}${id}/create_comment/` , data));
}

export async function getComment(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/comments/`));
}
