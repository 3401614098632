import React from 'react';
import { Col , Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ButtonForm = ({ md, id , sm, lg, xs, xl, col, placeholder, opts,  handleEvent, className  , variant , icon}) => {

    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl} className="align-self-center mt-2 text-center">
           {placeholder || icon ?
           <Button id={id} onClick={handleEvent} type='submit'  {...opts} variant={variant} className={className}>{placeholder} {icon ? <FontAwesomeIcon  icon={icon} /> : ''}</Button>
           : null }
            </Col>
        </>
    )
}

ButtonForm.propTypes = {
    
    placeholder: PropTypes.string,
    className:PropTypes.any,
    variant: PropTypes.any,
    icon: PropTypes.any,
    col: PropTypes.string,
    opts: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func
};

ButtonForm.defaultProps = {
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: '',
    opts: [],
    className: '',
    variant: 'primary',
    icon: '',
};
export default ButtonForm;