import React, { useState, useRef, useCallback , useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FormBlock from '../../shared/FormBlock';
import * as contactService from '../../services/contact';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { Storage } from '../../helpers';
import { QueryType } from '../../helpers/constants';
import Captcha from '../../shared/Captcha';

const ContactForm = () => {

  const dispatch = useDispatch();
   const [contactEnums, setContactEnums] = useState([]);
   const [validated, setValidated] = useState(false);
   const [formData, setFormData] = useState({
      comment: '',
      subject: '',
      query: '',
      attachment: '',
      email: '',
      user: Storage.getSession() ? Storage.getSession().id : '',
      query_type: QueryType.CONTACT.id
   });

   const validateCaptcha = useRef();
   const prevState = useRef(formData);

   useEffect(() => {
      dispatch(loaderActions.loader(true));
      contactService.getContactEnums().then(([response, error]) => {
         if (response) {
            setContactEnums(response);
         } else if (error) {
            dispatch(alertActions.error(error.response));
         }
         dispatch(loaderActions.loader(false));
      });
      return () => {
         setContactEnums([]);
      }
   }, [dispatch])

   const handleChange = useCallback((e) => {
      setFormData({
         ...formData,
         [e.name]: e.value
      });
   }, [formData]);

   const formFields = () => {
      let allFields = [
         [{ label: 'Select Query*', type: 'multi-select', required: true, placeholder: '---Select---', name: 'query', options: contactEnums, sm: 12, md: 12, lg: 12, xl: 12, }],
         [{ label: 'Email address*', type: 'email', required: true, name: 'email', placeholder: 'Email Id', sm: 12, md: 12, lg: 12, xl: 12, }],
         [{ label: 'Subject*', type: 'text', required: true, name: 'subject', placeholder: 'Type more details ...', sm: 12, md: 12, lg: 12, xl: 12, }],
         [{ label: 'Message*', type: 'textarea', required: true, name: 'comment', placeholder: 'Type more details ...', sm: 12, md: 12, lg: 12, xl: 12, }],
         [{ label: 'Attachments(for example , Resume ,  Screenshots)', type: 'file', fileFormat: '.pdf,.jpg,.png,.jpeg', placeholder: 'Attachment', name: 'attachment', sm: 12, md: 12, lg: 12, xl: 12, }]
      ]
      for (var i = 0; i < allFields.length; i++) {
        if (i === 1) {
          if (Storage.getSession() !== null) {
            allFields.splice(i, 1);
          }
        }
      }
      return allFields
    }

   const saveCreateData = useCallback(() => {
      if (formData.query !== '') {
         dispatch(loaderActions.loader(true));
         const form = new FormData();
         form.append('user', formData.user);
         form.append('query', Number(formData.query));
         form.append('email', formData.email);
         form.append('subject', formData.subject);
         form.append('details', formData.comment);
         form.append('query_type', formData.query_type)
         if (formData.attachment) {
            form.append('attachment', formData.attachment, formData.attachment.name);
         } else {
            form.append('attachment', '');
         }
         contactService.createRequest(form).then(([response, error]) => {
            if (response) {
               dispatch(alertActions.success('Send Successfully'));
               setFormData(prevState.current);
               setValidated(false);
            } else if (error) {
               dispatch(alertActions.error(error.response));
            }
            dispatch(loaderActions.loader(false));
         })
      } else {
         dispatch(alertActions.error('Please Select the query.'));
      }
   }, [dispatch, formData.user, formData.query, formData.subject, formData.email, formData.comment, formData.attachment, formData.query_type])


   const Submit = useCallback((event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() && validateCaptcha.current.validated()) {
         saveCreateData();
      }
   }, [saveCreateData])

  return (
    <Row>
      <Col>
        <Form noValidate validated={validated} onSubmit={Submit}>
          <FormBlock fields={formFields()} size={12} getData={(e) => handleChange(e)} formData={formData} />
          <Captcha ref={validateCaptcha} />
          <Button type='submit' >Submit</Button>
        </Form>
      </Col>
    </Row>
  )
}
export default ContactForm;