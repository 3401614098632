import React from "react";
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import LoginPage from "../login/LoginPage";
import { history } from "../../helpers/history";
import "./icons";
import PasswordForgot from "../login/PasswordForget";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import Home from '../layout/Home';
import Register from "../register/RegisterPage";
import Search from "../search/search";
import ComingSoon from "../layout/comingsoon";
import PaymentCheckout from "../payment/PaymentCheckout";
import Membership from "../packages/Membership";
import PaymentSuccess from "../payment/PaymentSuccess";
import PaymentCancel from "../payment/PaymentCancel";
import ChangePassword from "../dashboard/ChangePassword";
import Setting from "../dashboard/Setting";
import DashboardMember from "../dashboard/DashboardMember";
import About from "../about/About";
import { FrontendLayout } from "./FrontendLayout";
import PageNotFound from "../../shared/PageNotFound";
import CreateMemberPassword from "../login/CreateMemberPassword";
import  CreatePasswordConfirmation from '../login/CreatePasswordConfirmation';
import Support from "../support/Support";
import Contact from "../contact/Contact";
import Processing from "../payment/Processing";
import Faq from "../faq/Faq";
import Inquiry  from "../info/InquiryPage";
import News from "../news/News";
import NewsDetail from "../news/NewsDetail";
import PrivacyPolicy from "../legal/PrivacyPolicy";
import PackageUpgrade from "../payment/PackageUpgrade";
import PaymentConfirmation from "../payment/Payment-Confirmation";
import MemberPledge from "../login/MemberPledge";
import VerifyEmail from "../dashboard/VerifyEmail";

function App() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>

        {/* Public Routes */}
        <PublicRoute component={LoginPage} restricted={true} path="/login" exact />
        <PublicRoute component={PasswordForgot} restricted={true} path="/passwordForgot" />
        <PublicRoute component={CreateMemberPassword} restricted={true} path="/invite_member/:token" />
        <PublicRoute component={CreatePasswordConfirmation} restricted={true} path="/verify_email/:id" />
        <PublicRoute component={Register} restricted={true} path="/register" exact />
        <PublicRoute component={Inquiry} path="/inquiry" exact />
        <PublicRoute component={MemberPledge} path="/MemberPledge" exact />
        <PublicRoute component={VerifyEmail} path="/verify-email/:token"/>
        {/* Member Frontend Routes */}
        <FrontendLayout component={Home} path="/home" exact />

        <FrontendLayout component={Search} path="/search" exact />
        <FrontendLayout component={Search} path="/search/?q=:q" exact />

        <FrontendLayout component={DashboardMember} permission='qrata.dashboard_member' path="/dashboard" exact />
        <FrontendLayout component={Membership} path="/membership" exact />

        <FrontendLayout component={ComingSoon} path="/comingsoon" exact />
        <FrontendLayout component={About} path="/about" exact />
        <FrontendLayout component={Support} path="/support" exact />
        <FrontendLayout component={Faq} path="/faq" exact />
       
        <FrontendLayout component={News} path="/news" exact />
        <FrontendLayout component={PrivacyPolicy} path="/privacy-policy" exact />
        <FrontendLayout component={NewsDetail} path="/newsdetail" exact />
        <FrontendLayout component={Contact} path="/contact" exact />
        <FrontendLayout component={ChangePassword} path="/passwordChange" exact />
        <FrontendLayout component={Setting} path="/memberSettings" exact />

        
        <FrontendLayout component={PaymentConfirmation} path="/paymentConfirmation/package=:id" exact />
        <FrontendLayout component={PackageUpgrade} path="/packageUpgrade/package=:id" exact />
        <FrontendLayout component={PaymentCheckout} path="/checkout/package=:id" exact />
        <FrontendLayout component={Processing} path="/payment-suceess" exact />
        <FrontendLayout component={PaymentSuccess} path="/payment-confirm/transactionId=:transactionId" exact />
        <FrontendLayout component={PaymentCancel} path="/payment-cancel" exact />

        <FrontendLayout component={PageNotFound} path="*"  />

      </Switch>
    </ConnectedRouter>
  );
}

export default App;