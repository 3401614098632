import { Storage } from '../helpers/storage';

export const HasPermission = (props) => {
    if (props.permission !== '') {
        if (props.permission && Storage.getSession() ) {
            const couldShow = Storage.getSession().permissions.includes(props.permission);
            return couldShow ? props.children : null;
        } else {
            return null;
        }
    } else {
        return props.children;
    }

};


