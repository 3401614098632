import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";
const BASE = 'package/';

export async function getAllPackages() {
  return await handleApi(axiosClient.get(`${BASE}`));
}

export async function getPackageById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/`));
}
