import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

 const BASE = 'assets/';
// export async function assetsListing(pageSize, pageNumber) {
    // return await handleApi(axiosClient.get(`${BASE}discussed/?limit=${pageSize}&offset=${pageNumber}`));
export async function assetsListing(pageSize, pageNumber, search, ordering) {
    return await handleApi(axiosClient.get(`${BASE}discussed/?${search !== ''?'search='+search+'&':''}limit=${pageSize}&offset=${pageNumber}&ordering=${ordering}`));
  }

export async function createComment(id, data) {
    return await handleApi(axiosClient.post(`${BASE}${id}/create-discussion/` , data));
  }

export async function getComment(id, pageSize, pageNumber ) {
    return await handleApi(axiosClient.get(`${BASE}${id}/discussion/?limit=${pageSize}&offset=${pageNumber}`));
  }
  
export async function deleteComment(assetId, commentId) {
  return await handleApi(axiosClient.delete(`${BASE}${assetId}/discussion/${commentId}/`))
}