import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';

const Setting = () => {
  return (
    <>
      <Header />
      <h2>Setting</h2>
      <Footer />
    </>
  )
}
export default Setting;