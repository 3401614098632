import React from 'react';  
import { useSelector } from 'react-redux';
import { AlertComponent } from './AlertComponent';
import Loader from './Loader';

const AlertAndLoader = () => {
  const alert = useSelector(state => state.alert);
  const { loading } = useSelector((state) => state.loader);
  
  return (
    <>
    <Loader loading={loading} />
    <div aria-live="polite" aria-atomic="true" style={{ position: 'relative' }}>
       <div style={{ position: 'fixed', top: 55, right: '10px', zIndex: 1090, }}>
           <AlertComponent alert={alert} />
     </div>
    </div>
    </>
  )
}
export default AlertAndLoader;