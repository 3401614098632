import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Container, Form, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as topicRequestService from '../../services/topicRequest';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { dateFormat, Storage } from '../../helpers';
import FileField from '../../shared/formComponent/FileField'

const TopicRequestModal = ({ data, isVisible, title, hide }) => {

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [editData, setEditData] = useState([]);
  const [formData, setFormData] = useState({
    comment: '',
    user: Storage.getSession().id,
    attachment: undefined
  });
  const prevState = useRef(formData);

  const replyCommnets = useCallback(() => {
    dispatch(loaderActions.loader(true));
    topicRequestService.getComment(data.id).then(([response, error]) => {
      if (response) {
        setEditData(response.comment);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch, data.id])

  useEffect(() => {
    if (data) {
      replyCommnets()
    }

  }, [dispatch, replyCommnets, data])

  const closeModal = useCallback(() => {
    setEditData({});
    hide();
  }, [hide]);

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

  const saveCreateData = useCallback(() => {
    dispatch(loaderActions.loader(true));
    const form = new FormData();
    form.append('ask_by', formData.user);
    form.append('comment', formData.comment);
    if (formData.attachment) {
      form.append('attachment', formData.attachment)
    };
    topicRequestService.createComment(data.id, form).then(([response, error]) => {
      if (response) {
        setFormData(prevState.current);
        setValidated(false);
        dispatch(alertActions.success('Comment Added Successfully'));
        replyCommnets()
        // hide();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch, formData.user, data.id, formData.attachment, formData.comment, replyCommnets])

  const saveData = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      saveCreateData();
    }
  }, [saveCreateData])

  const openlink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <Container fluid>

      <Form noValidate validated={validated} onSubmit={saveData}>

        <Row>
          <Col md={4}>
            <h2 className="py-3">{title}</h2>
          </Col>
          <Col md={8} className="text-right d-flex align-items-center justify-content-end">
            <Button type="button" className="btn btn-primary" onClick={closeModal}>Back</Button>
          </Col>
        </Row>

        <Card className="border-0" >
          <Card.Body className='pb-0'>
            <Row>
              <Col md={4} className="mb-3">
                <Card.Subtitle className="mb-2 ">
                  <span><small className="text-muted">Ticket Number</small></span>
                  <h4>{editData ? data.ticket : ''}</h4>
                </Card.Subtitle>
              </Col>
              <Col md={4} className="mb-3">
                <Card.Text className="mb-2 ">
                  <span><small className="text-muted">Query</small></span>
                  <span className='d-block'>Topic Request Reply</span>
                </Card.Text>
              </Col>
              <Col md={4} className="mb-3">
                <Card.Text className="mb-2">
                  <span><small className="text-muted">Email</small></span>
                  <span className='d-block'>{data ? data.email : ''}</span>
                </Card.Text>
              </Col>
              {data.subject !== null ?
                <Col md={12}>
                  <Card.Text className="mb-2">
                    <span><small className="text-muted" >Specific Topic</small></span>
                    <span className='d-block'>{data.specific_topic !== '' ? data.specific_topic : ''}</span>
                  </Card.Text>
                </Col> : ''}
                {data.suggestion !== null ?
                      <Col md={12}>
                        <Card.Text className="mb-2">
                          <span><small className="text-muted" >Suggestion</small></span>
                          <span className='d-block'>{data.suggestion !== '' ? data.suggestion : ''}</span>
                        </Card.Text>
                      </Col> : ''}
            </Row>

          </Card.Body>
        </Card>

        <Col md={12} className="bg-white">
          <Card.Text>
            <span><small className="text-muted" >Comments:</small></span>
          </Card.Text>
        </Col>
        <Card className="border-0" style={{ maxHeight: 'calc(60vh - 210px)', overflow: 'auto' }} >
          <Card.Body className='pt-0'>
            <Row>
              {editData.length > 0 ? editData.map((item, index) => (
                <Col md={12} key={'comment' + index} className={editData.length - 1 === index ? 'py-2 position-relative ' : 'py-2 position-relative border border-top-0 border-right-0 border-left-0 border-bottom-primary'}>
                  <span className="guest-user font-weight-bold"><small className="font-weight-bold">{item.first_name + ' ' + item.last_name}</small></span>
                  <p className="mb-0" key={'reply' + index}>
                          {item.attachments.length > 0 ? 
                              <FontAwesomeIcon className='cursor-pointer btn-link' onClick={() => openlink(item.attachments[0].file_url)} icon='download' /> : ""
                              } 
                            {" " + item.comment}
                    <span className="position-absolute date-time text-light" ><small>{dateFormat(item.created_on ,  "DD MMM YYYY HH:mm")}</small></span></p>
                </Col>
              )) : ''}
            </Row>
          </Card.Body>
        </Card>

        <Row className="w-100 no-gutters p-4 bg-white">
          <Col md={10} xs={9}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control type='textarea' className="rounded-0" required placeholder='Reply type here...' name='comment' value={formData.comment} onChange={(e) => handleChange(e.target)} rows={1} />
                <span className="attachment-icon text-right">
                    <FileField key="attachment" icon="download" value={formData.attachment} id="attachment" className="p-0" type="file"   fileFormat=".doc,.docx,.pdf,.jpg,.png,.jpeg" handleEvent={(e) => handleChange(e)} placeholder={"Select File"} name='attachment' />
                  </span>
            </Form.Group>
          </Col>
          <Col md={2} xs={3}>
            <Button variant="primary" className="w-100 rounded-0" type="submit" ><FontAwesomeIcon size="lg" className="icon-green mr-2" icon="paper-plane" />Send</Button>
          </Col>
        </Row>
      </Form>

    </Container>
  )
}
export default TopicRequestModal;