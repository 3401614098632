import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as alertService from '../../actions/alert';
import { HasPermission } from '../HasPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FileField = ({ label, icon, id, permission, md, sm, lg, xs, xl, opts, name, value, handleEvent, fileFormat, className }) => {
    const [file, setFile] = React.useState('');
    const [download, setDownlaod] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (value.name && value.type) {
            setFile(value.name);
        } else if (value) {
            setFile(value.split('/')[4]);
            setDownlaod(value);
        } else { setFile(''); setDownlaod(''); };
    }, [value])


    let errorMessage = 'Select the ' + label + ' file.';
    const handleChange = (e) => {
        e.preventDefault();
        if(e.target.files){
        let file_types = e.target.files[0]?.name?.split('.');
        const type = '.'+file_types[file_types.length - 1];
        const fileType = fileFormat.split(',');
        if (fileType.includes(type)) {
            const name = e.target.name;
            const file = e.target.files[0];
            const data = { name: name, value: file };
            handleEvent(data);
        } else {
            dispatch(alertService.error('Please select ' + fileFormat + ' file format.'));
        }
     }
    }

    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl} className={className}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}> 
                    {icon ?  (<Form.Label><FontAwesomeIcon className='cursor-pointer' icon='paperclip' /></Form.Label>)  :"" }
                        {label ? <Form.Label >{label}</Form.Label> : ''}
                        <Form.File custom  >
                            <Form.File.Input onChange={(e) => handleChange(e)} {...opts} accept={fileFormat} name={name} />
                            <Form.File.Label data-browse="Browse" className="overflow-hidden">
                                {file ? file : icon? "" :'Select File'}
                            </Form.File.Label>
                            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                        </Form.File>
                        {download !== '' ? <a target="_blank" rel="noopener noreferrer" href={download}>Download file </a> : ''}
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

FileField.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    validators: PropTypes.array,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

FileField.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    permission: '',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    type: 'file',
    label: '',
    opts: [],
    validators: []
};
export default FileField;