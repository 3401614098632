import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../../helpers';
import * as membershipService from '../../services/membership';
import { useDispatch, useSelector } from 'react-redux';
import * as loaderActions from '../../actions/loader';
import * as alertActions from '../../actions/alert';
import * as packageService from '../../actions/membership';
import { Storage } from '../../helpers/storage';

const Membership = () => {

  const dispatch = useDispatch();
  const loginData = useSelector(state => state.auth.loginData);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    dispatch(loaderActions.loader(true));
    membershipService.getAllPackages().then(([response, error]) => {
      if (response) {
        setPackages(response.results);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })

    return () => {
      setPackages([]);
    }
  }, [dispatch])

  const buyNow = (id) => {
    // Storage.setMembership(id);
    dispatch(packageService.membership(id));
    if (loginData.id) {
      if(Number(Storage.getSession().member_package.package) < Number(id)){
          history.push('/packageUpgrade/package='+id);
      }else{
        history.push('/checkout/package=' + id);
      }
    } else {
      history.push('/register');
    }
  }

  return (
    <>
      <Container className="pb-5">
        <Row className="text-center py-5">
          <Col md={12} >
            <h2>Membership</h2>
          </Col>
        </Row>
        <Row className=" pb-3">
          <Col md={12}>
            <ul className="list-unstyled">
              <li>you can start using Qrata right away. You are allowed upto <b>20 searches</b> during your trial in the worlds most reliable and fact checked data base curated by the worlds best experts.</li>
            </ul>
            <h4>If you are ready to choose a package, pick one that most suits your needs</h4>
          </Col>
        </Row>
        <Row className="text-center">
          {packages.length > 0 ? packages.map((item, index) =>
            <Col key={'col' + index} md={4} className=" d-inline-flex mb-3">
              <Card className="w-100" >
                <Card.Body className="text-left">
                  <Card.Title className="text-uppercase p-3 mb-0">
                    <h2> {item.name}</h2>
                    <span className="price-tag text-capitalize">{item.monthly_amt}</span> /<small>mo</small>
                    <span className="d-block text-muted text-capitalize"><small>{item.yearly_amt}/yr Paid Yearly in advance</small></span>
                  </Card.Title>
                  <Button className="w-100 my-4" variant="primary" onClick={() => buyNow(item.id)}>BUY MEMBERSHIP</Button>
                  <ListGroup variant="flush" className="mb-4">
                    <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{item.overlay_ads}</b></ListGroup.Item>
                  </ListGroup>
                  
                  <ListGroup variant="flush" className="mb-4">
                    <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>{item.invite_limit}</b> invite in 6 months</ListGroup.Item>
                  </ListGroup>
                  <ListGroup variant="flush" className="mb-4">
                    <ListGroup.Item ><FontAwesomeIcon size="sm" className="icon-green mr-3 text-primary" icon="check" /><b>&#8734;</b> Folders to save Assets</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ) : ''}
        </Row>
        <Row className="no-gutter my-5">
          <Col md={4} className="text-center">
            <span className="qrataads-300-250 bg-light my-3">
              300x250 - Advertisement Banner
            </span>
          </Col>
          <Col md={4} className="text-center">
            <span className="qrataads-300-250 bg-light my-3">
              300x250  - Advertisement Banner
            </span>
          </Col>
          <Col md={4} className="text-center">
            <span className="qrataads-300-250 bg-light my-3">
              300x250  - Advertisement Banner
            </span>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Membership;