import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Container, Row, Col, Button, Card, Form, Jumbotron } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import * as supportService from '../../services/contact';
import FormBlock from '../../shared/FormBlock';
import { Storage } from '../../helpers';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { QueryType } from '../../helpers/constants';
import Captcha from '../../shared/Captcha';
import Faq from '../faq/Faq';


const Support = () => {

  const dispatch = useDispatch();
  const [type , setType] = useState('Faq');
  const [supportEnums, setSupportEnums] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    user: Storage.getSession() ? Storage.getSession().id : '',
    details: '',
    subject: '',
    problem: '',
    email: Storage.getSession() ? Storage.getSession().email : '',
    attachment: '',
    query_type: QueryType.SUPPORT.id
  });
  const prevState = useRef(formData);
  const validateCaptcha = useRef();

  

  useEffect(() => {
    if (Storage.getSession()?.id) {
      dispatch(loaderActions.loader(true));
      supportService.getSupportEnums().then(([response, error]) => {
        if (response) {
          setSupportEnums(response);
        } else if (error) {
          dispatch(alertActions(error.response));
        }
        dispatch(loaderActions.loader(false));
      });
      return () => {
        setSupportEnums([]);
      }
    }
  }, [dispatch])




  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData]);

  const formFields = () => {
    let allFields = [
      [{ label: 'Email Id*', type: 'email', required: true, name: 'email', placeholder: 'Email Id', sm: 12, md: 12, lg: 12, xl: 12, }],
      [{ label: 'Subject*', type: 'text', required: true, name: 'subject', placeholder: 'Subject', sm: 12, md: 12, lg: 12, xl: 12, }],
      [{ label: 'Provide more details*', type: 'textarea', required: true, name: 'details', placeholder: 'Type more details ...', sm: 12, md: 12, lg: 12, xl: 12, }],
      [{ label: 'Attachments(for example , Screenshots)', type: 'file', fileFormat: '.pdf,.jpg,.png,.jpeg', placeholder: 'Attachment', name: 'attachment', sm: 12, md: 12, lg: 12, xl: 12, }]
    ]
    for (var i = 0; i < allFields.length; i++) {
      if (i === 0) {
        if (Storage.getSession() !== null && Storage.getSession().id) {
          allFields.splice(i, 1);
          
        }
      }
    }
    return allFields
  }

  const saveSupportData = useCallback(() => {
    if(formData.problem !== ''){
    dispatch(loaderActions.loader(true));
    const form = new FormData();
    form.append('user', formData.user);
    form.append('problem', Number(formData.problem));
    form.append('email', formData.email);
    form.append('subject', formData.subject);
    form.append('query_type' , formData.query_type)
    form.append('details', formData.details);
    if (formData.attachment) {
      form.append('attachment', formData.attachment, formData.attachment.name);
    } else {
      form.append('attachment', '');
    }
    supportService.createRequest(form).then(([response, error]) => {
      if (response) {
        dispatch(alertActions.success('Send Successfully.'));
        setFormData(prevState.current);
        setValidated(false);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }else {
    dispatch(alertActions.error('Please select the problem .'));
  }
  }, [dispatch, formData.user,  formData.subject ,formData.problem, formData.email, formData.details, formData.attachment , formData.query_type])

  const Submit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() && validateCaptcha.current.validated()) {
      saveSupportData()
    }
  }, [saveSupportData])


  const updatePage = (label, value='') => {
    setType(label)
    setFormData({
      ...formData,
      'problem': value
    });
  }

  return (
    <>
     <Jumbotron className=" min-vh-100 mb-0 bg-grey-gradient">
      <Container className=" bg-dark align-self-center py-2 pb-5 text-white rounded shadow-custom shadow ">
        <Row className="justify-content-center no-gutters my-3">
        <Col md={2} className="text-center pr-2" key='support faq'>
            <Card className="mb-3 border-0" >
              <Card.Body className="p-0">
                  <Button onClick={() => updatePage('Faq')} className="w-100 py-4 rounded-0 text-center pl-4 border-0"  variant={type==='Faq' ? "warning":"info"}>
                    <FontAwesomeIcon size="lg" className="icon-green mr-3" icon="question-circle" />
                   <span className="btn-text d-block py-2"> Frequently Asked Questions</span>
                    </Button>
              </Card.Body>
            </Card>
            </Col>
           
            {supportEnums.length > 0 ? supportEnums.map((item, index) => (
               <Col md={2} className="text-center pr-2" key={'support' + index}>
              <Card className="mb-3 border-0" >
                <Card.Body className="p-0">
                    <Button onClick={() => updatePage(item.label, item.value)} className="w-100 py-4 rounded-0 text-center pl-4 border-0" variant={type===item.label ? "warning":"info"}>
                      <FontAwesomeIcon size="lg" className="icon-green mr-3" icon="envelope" />
                      <span className="text-capitalize btn-text d-block py-2">Email {item.label} Service</span>
                    </Button>
                </Card.Body>
              </Card>
              </Col>
            )) : ''}

          <Col md={2} className="text-left">
            <Card className="mb-3 border-0">
              <Card.Body className="p-0">
                <Link to='/support'>
                  <Button className="w-100 py-4 rounded-0 text-center pl-4 border-0" variant="info">
                    <FontAwesomeIcon size="lg" className="icon-green mr-3" icon="comment-dots" />
                    <span className="btn-text d-block py-2"> Live Chat With Support </span>
                  </Button>
                </Link>
              </Card.Body>
            </Card>
         </Col>
        </Row>
        <Row className="position-relative">
          <Col md={10} className="ml-auto mr-auto">
            <Row>
              <Col md={12}>
                { type === 'Faq' ? <Faq/> : 
                type === 'Technical' || type === 'Payment' || type === 'Membership' ? 
                <>
                <Form noValidate validated={validated} onSubmit={Submit}>
                  <FormBlock fields={formFields()} size={12} getData={(e) => handleChange(e)} formData={formData} />
                  <Captcha ref={validateCaptcha} />
                  <Button type='submit' >Submit</Button>
                </Form>
                </>
                : 
                ''
              }
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>
      </Jumbotron>
    </>
  )
}
export default Support;