import React, { useState } from 'react';
import { Container, Row, Col, Jumbotron,  Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactForm from './ContactForm';
import ChallengesForm from './ChallengesForm';
import TopicRequestForm from './TopicRequestForm';
import { Storage } from '../../helpers';

const Contact = () => {

   const [selectType, setSelectType] = useState(1);

   const sidebarLinks = [
      { id: 1, name: 'Get In Touch', icon: 'comment-dots', access: 5 },
      { id: 2, name: 'Challenges', icon: 'comment-dots', access: 5 },
      { id: 3, name: 'Topic Request', icon: 'comment-dots', access: 5 }
   ]

   return (
      <>
          <Jumbotron className=" min-vh-100 mb-0 bg-grey-gradient ">
         <Container className="my-0">
            <Row className="position-relative bg-dark  align-self-center px-5 py-3  rounded shadow-custom shadow  ">
               <Col md={7} className="text-LEFT ">
                  <Row className="  justify-content-start  no-gutters mr-3  pb-2">
                  <Col md={12} className="pb-2">
                        <h2 className="text-white">{selectType === 1 ? 'Get In Touch' : selectType === 2 ? 'Challenges' : selectType === 3 ?
                           'Topic Request' : ''}</h2>
                     </Col>
                     {/* <Col md={4} className="text-center pr-2">
                  <Card className="my-1 border-0" >
                     <Card.Body className="p-0">
                        <Button className="w-100 py-2 rounded-0 text-left pl-4 border-0" variant="warning">
                           <FontAwesomeIcon size="lg" className="icon-green mr-3" icon='comment-dots' />
                          
                           <span className="btn-text d-inline-block py-2"> Get In Touch </span>
                        </Button>
                     </Card.Body>
                  </Card>

     
                     </Col> */}
                     {sidebarLinks.map((item, index) => Storage.getSession() && Storage.getSession().group.includes(item.access) ? (
                         <Col md={4} className="text-center pr-2"  key={'sidebar' + index}>
                     <Card className="my-1 border-0">
                        <Card.Body className="p-0">
                           <Button onClick={() => setSelectType(item.id)} className="w-100 py-2 rounded-0 text-left pl-4 border-0"  variant={`${ selectType === item.id ? 'warning': 'info'}`}>
                              <FontAwesomeIcon size="lg" className="icon-green mr-3" icon={item.icon} />
                              <span className="btn-text d-inline-block py-2">    {item.name} </span>
                           </Button>
                        </Card.Body>
                     </Card>
                     </Col>
                  ) : '')}


                  </Row>
                  <Row className="mr-3">
                
                     <Col md={12}>
                        {selectType === 1 ?
                           <ContactForm /> :
                           selectType === 2 ?
                              <ChallengesForm /> :
                              selectType === 3 ?
                                 <TopicRequestForm /> : ''}
                     </Col>
                  </Row>
               </Col>
               <Col md={5} className="text-left text-dark">
                  <Col md={12} className="pb-4">
                     <h2 className="text-white">Contact Information</h2>
                  </Col>
                  <Card className="my-3 bg-c-address text-light  shadow-none border-0 rounded-0">
                     <Card.Body className="p-4">
                        <Row>
                           <Col md={12}>
                           </Col>
                        </Row>
                        <Row className="mb-3">
                           <Col md={1}>
                              <FontAwesomeIcon className="text-light nav-icons  pr-3" icon="map-marker-alt" size="lg" />
                           </Col>
                           <Col md={10}>
                              PO Box 15912
                              Honolulu, HI 96830
                            </Col>
                        </Row>
                        <Row className="mb-3">
                           <Col md={1}>
                              <FontAwesomeIcon className="text-light  nav-icons pr-3" icon="envelope" size="lg" />
                           </Col>
                           <Col md={10}>
                              support@qrata.com
                           </Col>
                        </Row>
                        <Row className="">
                           <Col md={1}>
                              <FontAwesomeIcon className="text-light  nav-icons pr-3" icon="phone-alt" size="lg" />
                           </Col>
                           <Col md={10}>
                              +1800-XXXX-XXXX
                           </Col>
                        </Row>
                     </Card.Body>
                  </Card>
                  <Col md={12} className="text-center p-0">
                  <Card className="my-1 border-0" >
                     <Card.Body className="p-0">
                        <Button className="w-100 py-4 rounded-0 text-left pl-4 border-0" variant="secondary">
                           <FontAwesomeIcon size="lg" className="icon-green mr-3" icon='comment-dots' />
                          
                           <span className="btn-text d-inline-block py-2"> Live Chat with Support </span>
                        </Button>
                     </Card.Body>
                  </Card>

     
                     </Col>

               </Col>
            </Row>
         </Container>
         </Jumbotron>
      </>
   )
}
export default Contact;