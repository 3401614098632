import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useFilters,
} from "react-table";
import { Table, Pagination, Dropdown, DropdownButton } from "react-bootstrap";
const defaultPropGetter = () => ({});
const AsyncAppTable = ({
  columns,
  data,
  filtering=false,
  subComponent,
  fetchData,
  loading,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  pageCount: controlledPageCount,
  resultCount,
  callFetch = false,
}) => {
 
  
const getQuery = (data) => {
  console.log('data -> ', data);
  let list = []
  for(var key in data) {
    if (data[key] !== "" && !Number.isInteger(data[key])) {  
      let fieldName = ''
      if (data[key].startsWith("*")) {
        fieldName = `${key}__iendswith`
      }
      else if (data[key].endsWith("*")) {
        fieldName = `${key}__istartswith`
      } else {
        fieldName = `${key}__icontains`
      };
      let field = `${fieldName}=${data[key].replace('*', '')}`
      list.push(field);
    } else if (Number.isInteger(data[key])) {
      let field =  `${key}=${data[key]}`;
      if(data[key] === 0) field =  ``
      list.push(field);
    }
  }
  return list.join('&');

} 
  const [filterData, setFilterDate] = useState({})
  const DefaultColumnFilter = (column) => {
    const filterHandler = (e) => {
      column.setFilterDate({...column.filterData, [column.column.id]:e.target.value})
  }
    return (
      <input
        className="form-control bg-transparent text-white mb-2 border-secondary"
        name={column.column.id}
        onChange={e => filterHandler(e)}
        placeholder={`Search...`}
      />
    )
  }
  
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterData,
      defaultColumn,
      setFilterDate,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const [sortedField, setSortedField] = useState("");

  const setSorting = (field) => {
    field === sortedField ? setSortedField(`-${field}`) : setSortedField(field);
  };

  const handleSorting = (column) => {
    if (!column.sortingOff) {
      setSorting(column.id);
    }
  };

  const fetch = useCallback(()=>{
    let filter = getQuery(filterData)
    fetchData({ pageSize, pageIndex, sortedField, filter });
    
  },[fetchData, pageIndex, pageSize, sortedField, filterData])

  useEffect(() => {
    fetch()
  },[fetch, gotoPage])

  useEffect(() => {
    if (callFetch){
      fetch()
    }
  },[callFetch,fetch])
  // Listen for changes in pagination and use the state to fetch our new data
  // useEffect(() => {
  //   let filter = getQuery(filterData)
  //   fetchData({ pageSize, pageIndex, sortedField, filter });
    
  // }, [fetchData, pageIndex, pageSize, gotoPage, sortedField, filterData,callFetch]);


  useEffect(() => {
    if (page.length === 0) {
      gotoPage(0)
    }
  }, [gotoPage, page.length]);

  useEffect(() => {
    gotoPage(0)
  }, [sortedField, filterData, gotoPage]);
  
  return (
    <>
      <div className="table-scroll table-responsive mb-0">
        <Table striped bordered hover {...getTableProps()}>
          <thead className="border-0 bg-light text-info text-nowrap">
            {headerGroups.map((headerGroup) => (
              <React.Fragment key="headers">
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: `bg-dark  text-white py-1 ${column.className}`,
                        style: column.style,
                      },
                      getColumnProps(column),
                    ])}
                    onClick={() => {
                      handleSorting(column);
                    }}
                  >
                    {column.render("Header")}
                    {sortedField === column.id ||
                    sortedField === `-${column.id}` ? (
                      column.id === sortedField ? (
                        <span className="d-block sorting-up"></span>
                      ) : (
                        <span className="d-block sorting-down"></span>
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
              {filtering ? (<tr {...headerGroup.getHeaderGroupProps()} key="two" className="bg-dark text-white">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps([
                  {
                    className: `bg-dark text-white py-1 ${column.className}` ,
                    style: column.style,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                ])}
                >
                  {column.filtering ?
                  (<div>{column.render('Filter')}</div>):null
                }
                </th>
              ))}
            </tr>) :null}
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <>
                <tr>
                  <td colSpan={columns.length} className="text-center py-1">
                    No data found.
                  </td>
                </tr>
              </>
            ) : null}
            {page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <React.Fragment key={"coloumn" + i}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td className="py-1"
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td className="py-1 px-4 inner-table"
                        colSpan={visibleColumns.length}
                      >
                        {subComponent({ row, rowProps, visibleColumns })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
            <tr>
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <td colSpan="10000">Loading...</td>
              ) : (
                <td colSpan="10000">
                  Showing {pageSize * pageIndex + page.length} of ~{resultCount}{" "}
                  {/* Showing {(pageSize * pageIndex + page.length > resultCount) ? pageSize * (pageIndex-1) + page.length : pageSize * pageIndex + page.length} of ~{resultCount}{" "} */}
                  
                  results
                </td>
              )}
            </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colSpan={columns.length} >

        <Pagination className="mb-0">
          <Pagination.First
            active={pageIndex === 0}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <Pagination.Prev
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />

          {pageIndex > 1 ? (
            <>
              <Pagination.Item key={`page-${0}`} onClick={() => gotoPage(0)}>
                {1}
              </Pagination.Item>
            </>
          ) : null}

          {pageIndex > 2 ? <Pagination.Ellipsis /> : null}

          {pageIndex > 0 ? (
            <Pagination.Item
              key={`page-${pageIndex - 1}`}
              onClick={() => previousPage()}
            >
              {pageIndex}
            </Pagination.Item>
          ) : null}

          <Pagination.Item key={`page-${pageIndex}`} active={true}>
            {pageIndex + 1}
          </Pagination.Item>
          {pageCount - 1 > pageIndex ? (
            <Pagination.Item
              key={`page-${pageIndex + 1}`}
              onClick={() => nextPage()}
            >
              {pageIndex + 2}
            </Pagination.Item>
          ) : null}

          {pageCount - pageIndex > 3 ? <Pagination.Ellipsis /> : null}

          {pageCount - pageIndex > 2 ? (
            <Pagination.Item
              key={`page-${pageCount - 1}`}
              onClick={() => gotoPage(pageCount - 1)}
            >
              {pageCount}
            </Pagination.Item>
          ) : null}

          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
          <DropdownButton variant="light" title={`Show ${pageSize}`}>
            {[5, 10, 15, 20, 30, 50].map((size) => (
              <Dropdown.Item
                key={`pgsize-${size}`}
                onClick={() => setPageSize(size)}
              >
                {size}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Pagination>

         </td>
          </tr>
        </tfoot>
      </Table>
      </div>
    </>
  );
};

export default AsyncAppTable;
