import React, { useState, useRef, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Storage } from '../../helpers';
import Captcha from '../../shared/Captcha';
import FormBlock from '../../shared/FormBlock';
import * as topicRequestService from '../../services/topicRequest';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';

const TopicRequestForm = () => {

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    specific_topic: '',
    suggestion: '',
    attachment: '',
    user: Storage.getSession() ? Storage.getSession().id : '',
  });

  const validateCaptcha = useRef();
  const prevState = useRef(formData);

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData]);

  const formFields = [
    [{ label: 'Specific Topic', type: 'text', required: true, placeholder: 'Specific Topic', name: 'specific_topic', sm: 12, md: 12, lg: 12, xl: 12, }],
    [{ label: 'Suggestion', type: 'textarea', required: true, name: 'suggestion', placeholder: 'Suggestion...', sm: 12, md: 12, lg: 12, xl: 12, }],
    [{ label: 'Attachments(for example , Resume ,  Screenshots)', type: 'file', fileFormat: '.pdf,.jpg,.png,.jpeg', placeholder: 'Attachment', name: 'attachment', sm: 12, md: 12, lg: 12, xl: 12, }]
  ]

  const saveCreateData = useCallback(() => {
       dispatch(loaderActions.loader(true));
       const form = new FormData();
       form.append('user', formData.user);
       form.append('specific_topic', formData.specific_topic);
       form.append('suggestion', formData.suggestion);
       if (formData.attachment) {
          form.append('attachment', formData.attachment, formData.attachment.name);
       } else {
          form.append('attachment', '');
       }
       topicRequestService.createTopic(form).then(([response, error]) => {
          if (response) {
             dispatch(alertActions.success('Send Successfully'));
             setFormData(prevState.current);
             setValidated(false);
          } else if (error) {
             dispatch(alertActions.error(error.response));
          }
          dispatch(loaderActions.loader(false));
       })
 }, [dispatch, formData.user, formData.specific_topic, formData.suggestion, formData.attachment])

  const Submit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() && validateCaptcha.current.validated()) {
      saveCreateData();
    }
  }, [saveCreateData])


  return (
    <Row>
      <Col>
        <Form noValidate validated={validated} onSubmit={Submit}>
          <FormBlock fields={formFields} size={12} getData={(e) => handleChange(e)} formData={formData} />
          <Captcha ref={validateCaptcha} />
          <Button type='submit' >Submit</Button>
        </Form>
      </Col>
    </Row>
  )
}
export default TopicRequestForm;