import React from "react";
import { useTable, useSortBy, useExpanded, usePagination } from "react-table";
import { Table } from "react-bootstrap";
// import { Table, Pagination, DropdownButton, Dropdown } from "react-bootstrap";
const defaultPropGetter = () => ({});
const SubTable = ({ columns, data, subComponent, getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter, getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter, }) => {


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    visibleColumns,
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    useExpanded,

    usePagination
  );

  return (
    <>
      <Table className="bg-white mb-0" responsive bordered hover size="sm"  {...getTableProps()}>
        <thead className="border-0 bg-light text-info">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="bg-light text-dark">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                  column.getSortByToggleProps()
                ])}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>

                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
         
         {page.length === 0 ? <><tr ><td colSpan={columns.length} className="text-center">No data found.</td></tr></> : null}
          {page.map((row, i) => {
            prepareRow(row);
            
            return (
              
              <React.Fragment key={'coloumn' + i}>
                <tr key={'row' + i} {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td  {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
                {
                  row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {subComponent({ row })}
                      </td>
                    </tr>
                  ) : null
                }
              </React.Fragment>
            )
          })}
        </tbody> 
        {/* <tfoot>
          <tr>
            <td colSpan={columns.length} className="pb-0">
              <Pagination>
                <Pagination.First active={pageIndex === 0} onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />

                {pageOptions.map((number) => (
                  <Pagination.Item key={`page-${number}`} active={number === pageIndex} onClick={() => gotoPage(number)}>
                    {number + 1}
                  </Pagination.Item>))}
                <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                <DropdownButton variant="light" title={`Show ${pageSize}`}>
                  {[5, 10, 15, 20, 30, 50].map((size) => (
                    <Dropdown.Item key={`pgsize-${size}`} onClick={() => setPageSize(size)}>{size}</Dropdown.Item>
                  ))}
                </DropdownButton>
              </Pagination>
            </td>
          </tr>
        </tfoot> */}
      </Table>
    </>
  );
};

export default SubTable;
