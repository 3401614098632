import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faDatabase,
    faQuestionCircle,
    faSearch,
    faArrowDown,
    faSortDown,
    faStarHalfAlt,
    faCalendarAlt,
    faAngleDown,
    faAngleUp,
    faKey,
    faCopy,
    faUser,
    faMinus,
    faLink,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faCaretRight,
    faCaretDown,
    faEdit,
    faTrash,
    faPlusSquare,
    faLock,
    faCog,
    faSignOutAlt,
    faChevronRight,
    faChevronDown,
    faTimes,
    faHome,
    faComment,
    faBriefcase,
    faImage,
    faQuestion,
    faPaperPlane,
    faAngleRight,
    faUsers,
    faUserFriends,
    faServer,
    faTachometerAlt,
    faStar,
    faTrashAlt,
    faBell,
    faCheckCircle,
    faCheck,
    faSpinner,
    faDotCircle,
    faInfoCircle,
    faSave,
    faPlus,
    faExchangeAlt,
    faPeopleArrows,
    faAt,
    faAngleLeft,
    faEye,
    faExclamationTriangle,
    faExclamationCircle,
    faDownload,
    faCamera,
    faBook,
    faAward,
    faUpload,
    faPen,
    faUserEdit,
    faWindowClose,
    faUserCheck,
    faFileInvoice,
    faUserGraduate,
    faEnvelope,
    faPhone,
    faMobileAlt,
    faUserCircle,
    faExclamation,
    faBriefcaseMedical,
    faVideo,
    faHeartbeat,
    faCoins,
    faRoute,
    faChartLine,
    faUniversity,
    faEllipsisV,
    faUserClock,
    faUserLock,
    faBookReader,
    faLongArrowAltUp,
    faCommentDots,
    faPaperclip,
    faMapMarkerAlt,
    faPhoneAlt,
    faFilePdf,
    faPrint,
    faArrowCircleLeft,
    faFolder,
    faBookmark,
    faExternalLinkAlt,
    faEyeSlash
    
} from '@fortawesome/free-solid-svg-icons';

import {fab , faFacebookSquare , faTwitterSquare , faInstagramSquare } from "@fortawesome/free-brands-svg-icons";

library.add(
    faFolder,
    fab,
    faBookmark,
    faExternalLinkAlt,
    faFacebookSquare,
    faTwitterSquare ,
    faInstagramSquare,
    faBars,
    faUserGraduate,
    faFileInvoice,
    faDatabase,
    faQuestionCircle,
    faSearch,
    faLink,
    faArrowDown,
    faSortDown,
    faStarHalfAlt,
    faCalendarAlt,
    faAngleDown,
    faAngleUp,
    faKey,
    faAngleLeft,
    faUser,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faCaretRight,
    faCaretDown,
    faEdit,
    faTrash,
    faPlusSquare,
    faPlus,
    faMinus,
    faLock,
    faCog,
    faSignOutAlt,
    faChevronRight,
    faChevronDown,
    faTimes,
    faHome,
    faCopy,
    faBriefcase,
    faImage,
    faQuestion,
    faPaperPlane,
    faAngleRight,
    faUsers,
    faUserFriends,
    faServer,
    faTachometerAlt,
    faStarHalfAlt,
    faStar,
    faTrashAlt,
    faBell,
    faCheckCircle,
    faCheck,
    faSpinner,
    faDotCircle,
    faInfoCircle,
    faSave,
    faExchangeAlt,
    faPeopleArrows,
    faAt,
    faEye,
    faComment,
    faExclamationTriangle,
    faExclamationCircle,
    faDownload,
    faCamera,
    faBook,
    faBriefcase,
    faAward,
    faUpload,
    faPen,
    faUserEdit,
    faWindowClose,
    faPeopleArrows,
    faUserCheck,
    faEnvelope,
    faPhone,
    faMobileAlt,
    faUserCircle,
   faExclamation,
   faBriefcaseMedical,
   faVideo,
   faHeartbeat,
   faCoins,
   faRoute,
   faChartLine,
   faUniversity,
   faEllipsisV,
   faUserClock,
   faUserLock,
   faBookReader,
   faPaperPlane,
   faCheckCircle,
   faDotCircle,
   faLongArrowAltUp,
   faCommentDots,
   faQuestionCircle,
   faPaperclip,
   faMapMarkerAlt,
   faPhoneAlt,
   faFilePdf,
   faPrint,
  faArrowCircleLeft,
  faEyeSlash

    
);