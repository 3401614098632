import React , { useRef, useState, useCallback, useEffect} from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { REGEXES } from '../../helpers/constants';
import { useBoolean } from '../../hooks/basic';
import FormBlock from '../../shared/FormBlock';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import * as memberService from '../../services/member';

const PasswordAndSecurity = () => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    password: '',
    new_password: '',
    re_password: ''
  });

  const prevState = useRef(formData);
  const { password, new_password, re_password } = formData;
  const [validated, setValidated, revokeValidated] = useBoolean(false);
  const [showPasswordObj, setShowPasswordObj] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }

  const validateForm = useCallback(() => {

    let retVal = true;
    let setMessage = '';
    const validRegex = REGEXES.PASSWORD.test(new_password);

    if (!validRegex) {
      setMessage = 'New Password does not match the required password pattern';
      retVal = false;
    } else if (password === new_password) {
      setMessage = 'New Password cannot be same as Old Password';
      retVal = false;
    } else if (new_password !== re_password) {
      setMessage = 'New Password & Confirm Password does not match';
      retVal = false;
    } 
    if(!retVal) {
      dispatch(alertActions.error(setMessage));
    }
    return retVal;
  },[password, new_password, re_password , dispatch]);

  const passwordChange = useCallback(() => {
    dispatch(loaderActions.loader(true));
    memberService.changePassword(formData).then(([response , error]) => {
      if (response){
        dispatch(alertActions.success('Password Changed Successfully.'));
        setFormData(prevState.current);
        revokeValidated();
      } else if(error){
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  },[dispatch , formData , revokeValidated])

  const handleSubmit = useCallback((event) => {
    setValidated();
    event.preventDefault();
    const form = event.currentTarget;
    if ((form.checkValidity() === true) && validateForm()) {
      passwordChange();
    } else {
      event.stopPropagation();
    }
  }, [validateForm, setValidated , passwordChange]);

    const fields = [
      { label: 'Old Password*', name: 'password', type: 'password', required: true, sm: 12, md: 12, lg: 12, xl: 12, showPassword:  showPasswordObj.password, handleShowPassword: () => handleShowPassword('password') },
      { label: 'New Password*', name: 'new_password', type: 'password', required: true, sm: 12, md: 12, lg: 12, xl: 12, showPassword:  showPasswordObj.new_password, handleShowPassword: () => handleShowPassword('new_password') },
      { label: 'Confirm Password*', name: 're_password', type: 'password', required: true, sm: 12, md: 12, lg: 12, xl: 12, showPassword:  showPasswordObj.re_password, handleShowPassword: () => handleShowPassword('re_password') }
    ];

  const handleShowPassword = (name) => {
    setShowPasswordObj({
      ...showPasswordObj,
      [name]: !showPasswordObj[name]
    })
  }

  return (
    <Container fluid>
    <Row>
      <Col md={8}>
        <Card>
          <h2 className="pl-3 pt-2">Password Change</h2>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <FormBlock fields={fields} getData={(e) => handleChange(e)} formData={formData} />
              <Card.Text><i>** Password must be 6 to 14 charaters long. Must Include lowercase and uppercase alphabetic characters,
                   numbers and special characters.</i><br />
              </Card.Text>
              <Button className="btn-green" variant="primary" type="submit">
                Submit
                </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  )
}
export default PasswordAndSecurity;