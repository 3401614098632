import { Status, DateConsts } from './constants';
import { Storage } from './storage';
import moment from 'moment';
import { useLocation } from "react-router-dom";

export const handleApi = (promise) => {
  return promise
    .then(result => ([result.data, undefined]))
    .catch(error => Promise.resolve([undefined, error]));
}

export const HEADERS = {
  NOAUTH: () => {
    return {
      'Accept': 'application/json,text/plain',
      'Content-Type': 'application/json',
    }
  },
  BASIC: () => {
    return {
      'Accept': 'application/json,text/plain',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Storage.getToken()}`,
    }
  },
  FILE: () => {
    return {
      'content-type': 'multipart/form-data',
      'Authorization': `Bearer ${Storage.getToken()}`,
    }
  }

}

export const convertDateYear = (date) => {
  return date.substr(5, 2) + '/' + date.substr(0, 4);
}

export const convertFullDate = (date) => {
  return moment(date).format("DD-MM-YYYY hh:mm:ss A");
}

export const dateFormat = (date , format) => {
  if(date) return moment(date).format(format);
  return '';
}


export const dateOnlyFormat = (date) => {
  if(date) return moment(date).format(DateConsts.DATE_ONLY_FORMAT);
    return '';
}

export const dateWithTimeFormat = (date) => {
  if(date) return moment(date).format(DateConsts.DATE_AND_TIME_FORMAT);
    return '';
}

export const monthYearFormat = (date) => {
  if(date) return moment(date).format(DateConsts.MONTH_YEAR_FORMAT);
    return '';
}

export const getStatus = (status) => {
  return Status.getStatus(status).name;
}

export const convertArrayToObject = (arrayData) => {
  let objData = '';
  arrayData.forEach((element, index) => {
    objData += element;
  });
  return JSON.parse(objData);
}

export const capitalizeFirstLetter = (string) => {
  const str = string.toString();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const checkSearches = (values = '') => {
  let i = 0;
  let uuid = uuidv4();
  if (Storage.getSearchCount()) i = Storage.getSearchCount().value;
  if (Storage.getSession() === null) {
    if (Storage.getSearchCount()) {
      const searchData = Storage.getSearchCount();
      if (values && values !== searchData.search) {
        if(searchData.value < 20) {
          const data = {
            id: searchData.id,
            value: searchData.value + 1,
            search: values
          }
          Storage.setSearchCount(JSON.stringify(data));
          return true;
        }else {
          return false;
        }
      }else {
        return true;
      } 
    } else {
      const data = {
        id: uuid,
        value: i + 1,
        search: values !== '' ? values : ''
      }
      Storage.setSearchCount(JSON.stringify(data));
    }
    return true;
  } else if (Storage.getSession() && Storage.getSession().membership_plan) {
    return true;
  }else {
    return false;
  }
}

export const Paginate = ( totalItems, currentPage = 1, pageSize = 10, maxPages = 10 ) => {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
      currentPage = 1;
  } else if (currentPage > totalPages) {
      currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
  } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
      } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
      }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

  // return object with all pager properties required by the view
  return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
  };
}