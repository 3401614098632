import { handleApi, HEADERS } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'users/';
const BASE_API = 'user/';

// UPDATE USER
export async function updateUser(id , data) {
  return await handleApi(axiosClient.post(`${BASE}${id}/update_username/` , data));
}

// UPDATE USER
export async function updateUserPatch(id , data) {
  return await handleApi(axiosClient.patch(`${BASE}${id}/` , data));
}

// DELETE USER BY ID
export async function deleteById(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/`));
}

// GET GROUPS LIST
export async function getGroups() {
  return await handleApi(axiosClient.get(`groups/`));
}

// RESET USER PASSWORD
export async function resetUserPassword(data) {
  return await handleApi(axiosClient.post(`${BASE}reset_user_password/` , data));
}

// GET USER INFO BY ID IN PROFILE
export async function fetchUserProfile(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/user_profile/`));
}

// UPDATE USER INFO IN PROFILE
export async function updateUserProfile(data) {
  return await handleApi(axiosClient.post(`${BASE}update/` , data ));
}


// UPLOAD PROFILE PIC 
export async function uploadProfilePic(data) {
  return await handleApi(axiosClient.post(`/profile_pic/upload/` , data ));
}

export async function updateProfilePic(id , data) {
  return await handleApi(axiosClient.post(`/profile_pic/${id}/` , data , {headers : HEADERS.FILE}));
}

export async function getLog(id) {
  return await handleApi(axiosClient.get(`/log/?user=${id}` ));
}

export async function updateLog(id , data) {
  return await handleApi(axiosClient.patch(`/log/${id}/` , data , {headers: HEADERS.NOAUTH()}));
}

export async function deleteLog(id) {
  return await handleApi(axiosClient.delete(`/log/${id}/` ));
}

// ADD NEW EMAIL
export async function saveNewEmail(email) {
  return handleApi(axiosClient.post(`${BASE_API}emails/`, email));
}

export async function getUserEmailsList() {
  return await handleApi(axiosClient.get(`${BASE_API}emails/`));
}

export async function verfyEmailWithToken(token) {
  return await handleApi(axiosClient.post(`${BASE_API}emails/verify/`, token));
}

export async function setAsPrimaryEmail(id) {
  return await handleApi(axiosClient.post(`${BASE_API}emails/${id}/set-as-primary/`));
}

export async function deleteSecondaryEmail(id) {
  return await handleApi(axiosClient.delete(`${BASE_API}emails/${id}/`));
}


