import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HasPermission } from '../HasPermission';

const RadiobuttonField = ({ type, id, permission, md, sm, xs, xl, lg, col, opts, label, value, name, checked, handleEvent }) => {

    const handleData = (e) => {
        const { name, value } = e.target;
        const data = { name, value };
        handleEvent(data);
    }
    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl} className="d-flex align-items-center">
                <HasPermission permission={permission}>
                    <Form.Group className='mb-0 pt-1'>
                        <Form.Check size={col} type={type} id={id} {...opts} feedback={label} label={label} name={name} defaultValue={value} defaultChecked={checked} onChange={(e) => handleData(e)} />
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}
RadiobuttonField.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

RadiobuttonField.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    permission: '',
    type: 'radio',
    label: '',
    opts: {}
};
export default RadiobuttonField;