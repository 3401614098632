import React , { useState } from 'react';
import {  Container, Row,  Col } from 'react-bootstrap';
import Account from './Account';
import AssetPage from './AssetPage';
import Challenges from './Challenges';
import Logs from './Logs';
import MembershipInvites from './MembershipInvites';
import PasswordAndSecurity from './PasswordAndSecurity';
import TopicRequest from './TopicRequest';
import MessageMain from './MessageMain';
import AssetDiscussionMain from './AssetDiscussionMain';

const DashboardMember = (props) => {
  const [menu , setMenu] = useState('account');

  return (
    <>
      <Container className="py-5">
        <Row className="">
          <Col md={3}>
            <ul className="list-unstyled">
              <li className={menu === 'account' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('account')}>Contact Info</li>
              <li className={menu === 'membershipInvites' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('membershipInvites')}>Membership & Invites</li>
              <li className={menu === 'asset' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('asset')}>My Assets</li>
              <li className={menu === 'challenges' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('challenges')}>Challenges</li>
              <li className={menu === 'topicRequest' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('topicRequest')}>Topic Request</li>
              <li className={menu === 'password' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('password')}>Password & security</li>
              <li className={menu === 'log' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('log')}>Login History</li>
              <li className={menu === 'messages' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('messages')}>Messages</li>
              <li className={menu === 'discussion' ? ' bg-light  font-weight-bold py-3 px-3 cursor-pointer ' : ' font-weight-bold py-3 px-3 cursor-pointer' } onClick={()=> setMenu('discussion')}>Asset Discussion</li>
            </ul>
          </Col>
          <Col md={9}>
            {menu === 'account' ? 
            <Account /> : menu === 'membershipInvites' ?
            <MembershipInvites/> : menu === 'asset' ?
            <AssetPage /> : menu === 'password' ? 
            <PasswordAndSecurity /> : menu === 'log' ? 
            <Logs /> : menu === 'topicRequest' ? 
            <TopicRequest />  :  menu === 'challenges' ? 
            <Challenges /> : menu === 'messages' ?
            <MessageMain /> : menu === 'discussion' ?
            <AssetDiscussionMain />  : ''}
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default DashboardMember;