import React, { useRef, useState, useCallback } from "react";
import { Container, Col, Row, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import TooltipHover from "../../shared/TooltipHover";
import * as challengesServices from '../../services/challenges';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import AsyncAppTable from "../../shared/AsyncAppTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoolean } from "../../hooks/basic";
import { Storage } from "../../helpers";
import ChallengeModal from "./ChallengeModal";
import { dateOnlyFormat } from "../../helpers";
import SearchField from '../../shared/SearchField';
import { QueriesStatus } from "../../helpers/constants";

const Challenges = () => {

  const dispatch = useDispatch();
  const fetchIdRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [listingData, setListingData] = useState([]);
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [editData, setEditData] = useState('');
  const [resultCount, setResultCount] = useState(0)
  const [search , setSearch] = useState('');
  const [status , setStatus] = useState(1);
  const [callFetch, setCallFetch] = useState(false);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortedField='', filter }) => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const limit = pageSize;
        let offset = pageIndex * pageSize;
        dispatch(loaderAction.loader(true));
        challengesServices.RequestListing(Storage.getSession().id , limit, offset, sortedField, filter, search, status ).then(([response, error]) => {
          if (response) {
            setResultCount(response.count)
            setListingData(response.results);
            setPageCount(Math.ceil(response.count / pageSize))
          } else if (error) {
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
        setCallFetch(false);
      }
    }, 1000)
    return () => {
      setListingData([]);
    }
  }, [dispatch, search, status])

  const openlink = (link) => {
    window.open(link, '_blank');
  }


  const openModal = useCallback((data) => {
    if (data) setEditData(data);
    showModal();
  }, [showModal]);


  const closeModal = useCallback(() => {
    setEditData('');
    // setCallFetch(true);
    hideModal();
  }, [hideModal])


  const columns = [
    {
      Header: "Ticket Id",
      accessor: "ticket",
      sortType: "basic",
      filtering:true,
    },
    {
      Header: "Name",
      accessor: "user__first_name",
      sortType: "basic",
      className: 'text-capitalize',
      filtering:true,
      Cell: (cell) => (
        <div>
          {cell.row.original.first_name || cell.row.original.last_name ? cell.row.original.first_name + ' ' + cell.row.original.last_name : 'Guest user'}
        </div>
      )
    },
    {
      Header: "Email ID",
      accessor: "email",
      id: "user__email",
      sortType: "basic",
      filtering:true,
      className: 'text-capitalize'
    },
    {
      Header: "Asset",
      accessor: "specific_topic",
      id: 'asset__specific_topic',
      filtering:true,
      sortType: "basic"
    },
    {
      Header: "Created On",
      accessor: "created_on",
      sortType: "basic",
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original.created_on)}</div>)
    },
    {
      Header: "File",
      className: 'text-center',
      sortingOff: true,
      Cell: (cell) => (
        <div>
          {cell.row.original.file ? <FontAwesomeIcon className='cursor-pointer' onClick={() => openlink(cell.row.original.file[0]?.file_url)} icon='download' /> : 'No file'}
        </div>
      )
    },
    {
      Header: "Action",
      accessor: "action",
      sortingOff: true,
      className: 'text-center',
      Cell: (cell) => (
        <div>
          <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='eye' iconClass='default' message='View Message' handleEvent={() => openModal(cell.row.original)} />
        </div>
      ),
    },
  ];


  return (
    <>
    {isVisible ?
      <ChallengeModal data={editData} title='Challenges Reply' isVisible={isVisible} hide={closeModal} refreshData={() => setCallFetch(true)} />
      :
      <Container fluid>
         <Row className="py-3">
          <Col md={5}>
          <h2 >Challenges</h2>
          </Col>
          <Col md={3}>
              <Form.Control as="select" size={'md'} value={status} onChange={(e) => setStatus(e.target.value)} >
              {Object.values(QueriesStatus).map((item, i) => { return item.value ?  <option value={item.value} key={i}>{item.label}</option> : null }
              )}
              </Form.Control>
          </Col>
          <SearchField md={4} action={setSearch}/>
      </Row>
        
        <AsyncAppTable
          columns={columns}
          data={listingData}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          resultCount={resultCount}
          filtering={true} 
          callFetch={callFetch}/>
      </Container>}
    </>  
  );
}

export default Challenges;