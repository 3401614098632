import { createActionType } from '../actions/actionBase';

export const ACCOUNT = {
    CHANGE_PASSWORD: createActionType('ACCOUNT__CHANGE_PASSWORD'),
    LOGIN: createActionType('ACCOUNT__LOGIN'),
    LOGOUT: createActionType('ACCOUNT__LOGOUT'),
    REFRESH__TOKEN: createActionType('REFRESH__TOKEN'),
    PASSWORD_FORGOT: createActionType('ACCOUNT__PASSWORD_FORGOT'),
    UPDATE_SESSION: createActionType('UPDATE__USER_SESSION')
}


export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    INFO: 'ALERT_INFO',
    WARNING: 'ALERT_WARNING',
    CLEAR: 'ALERT_CLEAR'
};

export const Loader = {
    LOADER : 'LOADER_APP'
}

export const Membership = {
    PACKAGE : 'PACKAGE_APP'
}


export const USER = {
    FETCH_ALL: createActionType('USER__FETCH_ALL'),
    ADD: createActionType('USER__ADD'),
    UPDATE: createActionType('USER__UPDATE'),
    DELETE: createActionType('USER__DELETE'),
    USER_GROUP: createActionType('USER__USER_GROUP'),
}
