import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HasPermission } from '../HasPermission';

const CheckboxField = ({ label, permission, md, id, className, sm, lg, xs, xl, type, col, placeholder, value, name, opts, handleEvent }) => {

    const onChange = (e) => {
        const { name, checked } = e.target;
        const data = { name, value: checked };
        handleEvent(data);
    }
    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl} className="d-flex align-items-center" >
                <HasPermission permission={permission}>
                    <Form.Group controlId={id} className={className}>
                        <Form.Check type={type} label={label} size={col} {...opts} feedback={label} onChange={(e) => onChange(e)} checked={value} name={name} placeholder={placeholder} />
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

CheckboxField.propTypes = {
    checked: PropTypes.any,
    name: PropTypes.string,
    className: PropTypes.any,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

CheckboxField.defaultProps = {
    checked: false,
    name: '',
    className: 'mt-4',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: '',
    type: 'checkbox',
    permission: '',
    label: '',
    opts: []
};
export default CheckboxField;