import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = 'member_folder/';


export async function createFolder( data) {
  return await handleApi(axiosClient.post(`${BASE}create/` , data));
}

export async function deleteFolder(id) {
  return await handleApi(axiosClient.delete(`${BASE}${id}/` ));
}

export async function getFolderById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/` ));
}

export async function updateFolder(data) {
  return await handleApi(axiosClient.post(`${BASE}update-folder/` , data));
}

export async function addAsset(data) {
  return await handleApi(axiosClient.post(`${BASE}add_asset/` , data));
}

export async function getAllData(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_folder/`));
}

export async function getAssetById(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/get_asset/`));
}

export async function deleteAsset(id) {
  return await handleApi(axiosClient.get(`${BASE}${id}/delete_asset/`));
}




