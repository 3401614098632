import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useBoolean } from '../../hooks/basic'
import ModalForm from '../../shared/ModalForm';
import ReactTree from '../../shared/ReactTree';
import * as bookmarkService from '../../services/bookmark';
import { Storage } from '../../helpers';
import * as alertAction from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import { useDispatch } from 'react-redux';
import TooltipHover from '../../shared/TooltipHover';

const AssetPage = () => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState({ folder: '' });
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [assetData, setAssetData] = useState({});
  const [folderId, setFolderId] = useState('');
  const [assetListing, setAssetListing] = useState([]);
  const [titleModal ,  setTitleModal] = useState('');
  const [type , setType] = useState('create');
  const prevState = useRef(editData);

  // FORM FIELDS
  const fields = [
    [{ label: 'Folder Name', name: 'folder', value: editData.folder, type: 'text', required: true, placeholder: 'Folder Name', sm: 12, md: 12, lg: 12, xl: 12 }],
  ];

  const getAssetDataById = useCallback((id) => {
      bookmarkService.getFolderById(id).then(([response , error ]) => {
        if(response){
          setEditData({
            folder : response.title
          })
          showModal();
        }else if(error){
          dispatch(alertAction.error(error.response));
        }
      })
  },[dispatch , showModal])

  const openModal = useCallback((id , type) => {
    setType(type);
    if (id !== '0-0' && type === 'create') {
      setTitleModal('Create Bookmark Folder');
      setFolderId(id);
      showModal();
    }else if(id !== '0-0' && type === 'rename'){
      setTitleModal('Rename Bookmark Folder');
       getAssetDataById(id);
       setFolderId(id);
    }else{
      setTitleModal('Create Bookmark Folder');
      showModal();
    }
    
  }, [showModal , getAssetDataById]);


  const getAllData = useCallback(() => {
    if (Storage.getSession().id) {
      bookmarkService.getAllData(Storage.getSession().id).then(([response, error]) => {
        if (response) {
          setAssetData(response);
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
      })
    }
  }, [dispatch])


  useEffect(() => {
    getAllData()
  }, [getAllData])


  const addFolder = useCallback((e) => {
    if (e.folder !== '') {
      const data = {
        title: e.folder,
        member: Storage.getSession().id,
        parent: folderId !== '' ? folderId : null
      }
      dispatch(loaderAction.loader(true));
      bookmarkService.createFolder(data).then(([response, error]) => {
        if (response) {
          dispatch(alertAction.success('Added New Folder.'));
          setFolderId('');
          getAllData();
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, getAllData, folderId])

  const renameFolderName = useCallback((e) => {
    if (e.folder !== '') {
      const data = {
        title: e.folder,
        id : folderId
      }
      dispatch(loaderAction.loader(true));
      bookmarkService.updateFolder(data).then(([response, error]) => {
        if (response) {
          dispatch(alertAction.success('Updated Successfully.'));
          setFolderId('');
          getAllData();
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
    }
  }, [dispatch, getAllData,folderId ])


  const closeModal = useCallback((e) => {
    if (e && type === 'create') {
      addFolder(e);
    }else if((e && type === 'rename') ){
      renameFolderName(e)
    }
    setEditData(prevState.current);
    hideModal();
  }, [hideModal, type , addFolder , renameFolderName])

  const getAssetListing = useCallback((id) => {
    if (id && id !== '' && id !== '0-0') {
      bookmarkService.getAssetById(id).then(([response, error]) => {
        if (response) {
          setAssetListing(response);
        } else if (error) {
          dispatch(alertAction.error(error.response));
        }
      })
    }
  }, [dispatch])

  const deleteAsset = useCallback((id) => {
    bookmarkService.deleteAsset(id).then(([response, error]) => {
      if (response) {
        const assets = assetListing.filter(obj => obj.id !== id);
        setAssetListing(assets);
        dispatch(alertAction.success('Asset Successfully Deleted.'))
      } else if (error) {
        dispatch(alertAction.error(error.response));
      }
    })
  }, [assetListing, dispatch])

  return (
    <>
      {isVisible ?
        <ModalForm
          show={isVisible}
          onHide={(e) => closeModal(e)}
          data={editData}
          fields={fields}
          title={titleModal}
          button={'Save'}
        /> : null}
      <Row>
        <Col md={9} className='pb-2'>
          <h2>My Assets</h2>
        </Col>
        <Col md={3} className='pb-2'>
          <Button className='float-right' onClick={() => openModal('' , type)}>Add New Folder</Button>
        </Col>
        <Col md={12}>
          <Card style={{minHeight:'325px'}}>
            <Card.Body>
              <Row>
              <Col md={6}>
                <ReactTree openModal={(e , type) => openModal(e , type)} getData={() => getAllData()} data={assetData} getAsset={(e) => getAssetListing(e)} />
              </Col>
              <Col md={6}>
                {assetListing.length > 0 ?
                  <>
                          <h4>Assets Listings</h4>
                          {assetListing.map((item, index) => (
                            <div key={'asset'+index} className='d-flex justify-content-between'>
                              <a className='d-block text-secondary text-truncate' target="_blank" rel="noopener noreferrer" key={index + 'links'} href={item.url} >{item.title}</a>
                              <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Asset' handleEvent={() => deleteAsset(item.id)} />
                            </div>
                          ))}
                  </> : ''
                }
              </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>

  )
}
export default AssetPage;