import React , { useState, useCallback } from 'react';
import * as userService from '../../services/user';
import * as alertActions from '../../actions/alert';
import * as loaderAction from '../../actions/loader';
import TooltipHover from '../../shared/TooltipHover';
import { useDispatch } from 'react-redux';
import AsyncAppTable from '../../shared/AsyncAppTable';
import { Container, Modal } from 'react-bootstrap';
import useConfirm from '../../hooks/useConfirm.jsx';

const EmailsListing = ({isVisible, hide}) => {

const dispatch = useDispatch();
const [emailsData , setEmailsData] = useState([]);
const [loading , setLoading ] = useState(false);
const [pageCount, setPageCount] = useState(0);
const [resultCount, setResultCount] = useState(0);
const {confirm} = useConfirm();

 const fetchData = React.useCallback(() => {
   const pageSize = 10;
  setLoading(true);
  setTimeout(() => {
      dispatch(loaderAction.loader(true));
      userService.getUserEmailsList().then(([response , error]) => {
        if(response){
          setEmailsData(response.results);
          setResultCount(response.results.length)
          setPageCount(Math.ceil(response.results.length / pageSize))
        }else if(error){
          dispatch(alertActions.error(error.response));
        }
        dispatch(loaderAction.loader(false));
      })
      setLoading(false);
  }, 100)
}, [dispatch])

const handleDeleteEmail = useCallback(async (data) => {
  const isConfirmed = await confirm('Are you sure you want to delete this email?');
  if (isConfirmed) {
    setLoading(true);
        dispatch(loaderAction.loader(true));
        userService.deleteSecondaryEmail(data.id).then(([response , error]) => {
          if(!error){
            dispatch(alertActions.success('Email Deleted Successfully'));
            fetchData();
          }else if(error){
            dispatch(alertActions.error(error.response));
          }
          dispatch(loaderAction.loader(false));
        })
        setLoading(false);
  }
    
  }, [dispatch, fetchData, confirm])

  const handleSetAsPrimaryEmail = useCallback((data) => {
    setLoading(true);
    dispatch(loaderAction.loader(true));
    userService.setAsPrimaryEmail(data.id).then(([response , error]) => {
      if(response){
        dispatch(alertActions.success('Email has been set as a primary email Successfully'));
        fetchData();
      }else if(error){
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderAction.loader(false));
    })
    setLoading(false);
  }, [dispatch, fetchData])

  const closeModal = useCallback(() => {
    hide();
  }, [hide]);

const emailsDataColumns = [
  {
    Header: "ID",
    accessor: "id",
    sortType: "basic"
  },
  {
    Header: "Email",
    accessor: "email",
    sortType: "basic"
  },
  {
    Header: "Status",
    accessor: "verified",
    sortType: "basic",
    className: 'text-center',
    Cell: (cell) => (
      <div>
        {cell.row.original.verified ? <span>Verified</span>
        : <span>Not-Verified</span>} 
      </div> ),
  },
  {
    Header: "Action",
    accessor: "action",
    sortingOff: true,
    className: 'text-center',
    Cell: (cell) => (
      <div>
        {cell.row.original.verified && !cell.row.original.primary ? 
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='check-circle' iconClass='default'  message='Set as primary Email' handleEvent={() => handleSetAsPrimaryEmail(cell.row.original)} />
        : ''} 
        {!cell.row.original.primary ?
        <TooltipHover type='button' btnSize='sm' variant='link' btnIcon='trash' iconClass='text-danger'  message='Delete' handleEvent={() => handleDeleteEmail(cell.row.original)} /> : '' }
        {cell.row.original.primary &&
        <TooltipHover type='icon' size='sm'  icon='lock' iconClass='default'  message='Primary Email' />}
      </div>
    ),
  }
];

  return (
    <>
      <Container fluid className="px-0">
        <Modal show={isVisible} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              List of Emails
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AsyncAppTable
            columns={emailsDataColumns}
            data={emailsData}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            resultCount={resultCount} 
            filtering={true} />
          </Modal.Body>
      </Modal>
    </Container>
  </>
  )
}
export default EmailsListing;