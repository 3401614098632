import React, { useEffect, useCallback, useState } from 'react';
import {  Button, Row, Card, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoolean } from '../../hooks/basic';
import InviteMember from './InviteMember';
import * as memberPackageService from '../../services/member';
import { history, Storage,dateOnlyFormat } from '../../helpers';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import TooltipHover from '../../shared/TooltipHover';
import * as alertActions from '../../actions/alert';
import { PaymentStatus  } from '../../helpers/constants';
import AsyncAppTable from '../../shared/AsyncAppTable';
import RenewMembershipPopup from '../../shared/RenewMembershipPopup';

const MembershipInvites = () => {

  const dispatch = useDispatch();
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [members , setMembers] = useState([]);
  const [currentPackage , setCurrentPackage] = useState('');
  const [invites , setInvites] = useState('')
  const [count , setCount] = useState()
  const [pageCount , setPageCount] = useState()
  const [memberPackage , setMemberPackage] = useState([]);

  const getPackageList = useCallback(({ pageSize, pageIndex}) => {
    dispatch(loaderActions.loader(true));
    memberPackageService.getMemberPackageById(Storage.getSession().id, pageSize, pageIndex).then(([response, error]) => {
      if (response) {
        if((response?.current_package).length>0){
          setCurrentPackage(response.current_package[0]?.package.name);
          setInvites(response.current_package[0]?.invites_available);
        }
        if(response?.member_package_list.count>0){
          setMemberPackage(response.member_package_list?.results);
          setCount(response.member_package_list?.count);
          setPageCount(Math.ceil(response.member_package_list?.count / pageSize))
        }
        if( ((response?.current_package).length===0) && ((response?.member_package_list).length===0) ){
          setCurrentPackage('');
          setInvites('');
          setMemberPackage([]);
          setCount();
          setPageCount()  
          Storage.setMembership(null)
          let currentSession = Storage.getSession()
          Storage.setSession(currentSession)
        }
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch])

  const getMemberList = useCallback((id) => {
    dispatch(loaderActions.loader(true));
    memberPackageService.getMemberListById(id , 1).then(([response, error]) => {
      if (response) {
        setMembers(response);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  },[dispatch])

  useEffect(() => {
    if (Storage.getSession().id) {
      getPackageList(Storage.getSession().id);
      getMemberList(Storage.getSession().id);
    }
    return () => {
      setCurrentPackage({});
      setMemberPackage([]);
      setMembers([]);
    }
  }, [ getMemberList, getPackageList])


  const openModal = useCallback(() => {
    showModal();
  }, [showModal]);

  const closeModal = useCallback(() => {
    hideModal();
  }, [hideModal])

  const deleteInviteUser = useCallback((id) => {
    dispatch(loaderActions.loader(true));
    memberPackageService.deleteMember(id).then(([response , error]) => {
      if(response){
        dispatch(alertActions.success('Deleted Member Successfully.'));
        getMemberList(Storage.getSession().id);
        getPackageList(10,1)
      }else if(error){
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
     })
  },[dispatch , getMemberList , getPackageList])


  const resendInvite = useCallback((id) => {
    dispatch(loaderActions.loader(true));
    memberPackageService.resendInvite(id).then(([response , error]) => {
      if(response){
        dispatch(alertActions.success('Resend Invite Successfully.'));
      }else if(error){
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  },[dispatch])

  const deletePackage = useCallback((id) => {
    if(id !== null){
      memberPackageService.deleteMemberPackage(id).then(([response , error]) => {
          getPackageList(10,0);
          Storage.removeMembership()       
        if(error){
          dispatch(alertActions.error(error.response));
        }
      })
    }
  },[dispatch, getPackageList]);

  useEffect(() => {
    getPackageList(10,0)
  }, [deletePackage, getPackageList])

  const columns = [
    {
      Header: "Plan",
      accessor: "ticket",
      sortType: "basic",
      Cell: (cell) => (<div>{cell.row.original?.package.name}</div>),
      sortingOff: true
    },
    {
      Header: "Start Date",
      accessor: "package_date",
      sortType: "basic",
      sortingOff: true,
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original?.package_date)}</div>)
    },
    {
      Header: "End Date",
      accessor: "package_expiry",
      sortType: "basic",
      sortingOff: true,
      Cell: (row) => (<div>{dateOnlyFormat(row.row.original?.package_expiry)}</div>)
    },
    {
      Header: "Status",
      accessor: "specific_topic",
      sortType: "basic",
      sortingOff: true,
      Cell: (row) => (<div>{row.row.original?.status && PaymentStatus.getPaymentStatus(row.row.original?.status).name}</div>)
    },
    
    {
      Header: "Action",
      accessor: "action",
      className: 'text-center',
      sortingOff: true,
      Cell: (row) => (
        <div>
          {row.row.original?.status !== PaymentStatus.PENDING.value ?
              <FontAwesomeIcon size="lg" className={row.row.original.active_package === true? "icon-green text-success mr-1" : "mr-1"} icon="check-circle" />
              :
              <>
              {row.row.original.canPay && <TooltipHover disabled={!row.row.original.canPay} type='button' btnSize='sm'  variant='link' iconClass='default' btnIcon='paper-plane' message='Pay now' handleEvent={() => history.push('/paymentConfirmation/package='+row.row.original.id)} />}
              <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Plan' handleEvent={() => deletePackage(row.row.original.id)}/>
              </>
            }
        </div>
      ),
    },
  ];

  return (
    <>
      <InviteMember isVisible={isVisible} hide={closeModal} getPackage={() => getPackageList()}  getMembers={() => getMemberList(Storage.getSession().id)} />
      <h2 className="pb-3">Membership & Invites</h2>
      <Card className="mb-3">
      {currentPackage !== '' ? 
      <>
        <Card.Body className="px-5">
          <Row>
            <Col md={12}>
              <Row>
                <Col xs={6} md={4}>
                <label className="text-dark font-weight-bold">Current Membership</label>
               <p>{currentPackage !== '' ? currentPackage : 'No Plan'}</p>
             <Button variant="outline-secondary" onClick={() => history.push('/membership')} ><FontAwesomeIcon size="sm" className="icon-green  mr-1" icon="long-arrow-alt-up" />Upgrade Plan</Button>
                </Col>
                <Col xs={6} md={4} className="">
                <Col md={12}>
              <label className="text-dark font-weight-bold">Available Invites</label>
              <p>{invites ? invites : 0}</p>
           
              <Button variant="primary" disabled={invites ? invites > 0 ? false : true : true} onClick={() => openModal()}><FontAwesomeIcon size="sm" className="icon-green mr-1" icon="paper-plane" />Send Invite</Button>
            </Col>
                </Col>
              </Row>
             
             
            </Col>

          </Row>
        </Card.Body>
         <hr />
         </>
        : ''}
       
        <Card.Body className="px-5">
          <Row>
          <Col md={12}>
              <Row className="py-1">
              <Col md={6}> <label className="text-dark font-weight-bold d-flex align-itens-center">Membership History</label></Col>
               
              </Row>
              <AsyncAppTable
              columns={columns}
              data={memberPackage}
              fetchData={getPackageList}
              pageCount={pageCount}
              resultCount={count} 
              />
            </Col>
           
            <Col md={12}>
            <hr/>
              <Row className="py-3">
              <Col md={6}> <label className="text-dark font-weight-bold d-flex align-itens-center">Invites History</label></Col>
              </Row>
              
                <Table responsive="sm">
                <thead>
                  <tr>
                    <th className="border-bottom-0">Name</th>
                    <th className="border-bottom-0">Username</th>
                    <th className="border-bottom-0">Email</th>
                    <th className="border-bottom-0">Status</th>
                    <th className="border-bottom-0 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {members?.length > 0 ? members?.map((item,index) => (
                    <tr  key={'memberList'+index}>
                      <td>{item.invited_user_first_name+' ' +item.invited_user_last_name}</td>
                      <td>{item.invited_user_username}</td>
                      <td>{item.invited_user_email}</td>
                      <td>{item.status}</td>
                      <td className='text-center'>
                        {item.status === 'Success' ?
                         <FontAwesomeIcon icon='check' />
                        :
                        <>
                      <TooltipHover type='button' btnSize='sm'  variant='link' iconClass='default' btnIcon='paper-plane' message='Resend Invite' handleEvent={() => resendInvite(item.id)} />
                      <TooltipHover type='button' btnSize='sm' placement='bottom' variant='link' iconClass='text-danger' btnIcon='trash' message='Delete Member' handleEvent={() => deleteInviteUser(item.id)}/>
                        </>
                      }
                      
                      </td>
                    </tr>
                  )) :
                   <>
                  <tr>
                    <td className='text-center' colSpan='5'>No Member Found.</td>
                    </tr>
                    </>}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
export default MembershipInvites;