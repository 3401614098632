import React from 'react';
import { Card, Row, Col, Image } from 'react-bootstrap';
import Glogo from "../../assets/images/google-logo.png";
import GSlogo from "../../assets/images/gs.png";
const GoogleSearch = (props) => {

   return (
    <>
      {props.searchArrGoogle.length > 0 ? props.searchArrGoogle.map((item, index) => (
                     <Row className="mb-2 Q-serach-data" key={'seachData' + index}>
                        <Col md={2} xs={2} sm={2} className="text-center">
                                    <span className="d-block p-2 mt-4 score-box bg-secondary">
                                    <Image className="b-logo" width="25px" src={GSlogo} alt="google-logo" rounded />
                                       {/* <p className="s-number text-white">{startIndex !== undefined ?   startIndex + index  + 1 : ''}</p> */}
                                    </span>
                        </Col>
                        <Col md={10} xs={10} sm={10} className="text-left px-0">
                           <Card>
                              <span className="Glogo-s"><Image src={Glogo} alt="google-logo" rounded /></span>
                              <Card.Header className="border-bottom-0 mb-0 pb-0 bg-white">
                                 <p className="mb-0  d-block text-truncate w-95"><a className="text-info " rel="noopener noreferrer" target="_blank" href={item.link}>{item.link}</a></p>
                                 <h5 className="mb-0"><a href={item.url ? item.url : ''} rel="noopener noreferrer" target="_blank">{item.title}</a></h5>
                              </Card.Header>
                              {/* <Card.Body className="py-2">
                                 <Card.Text>
                                    {item.description}
                                 </Card.Text>
                              </Card.Body> */}
                              <Card.Footer className="text-muted p-1 bg-white border-top-0">
                              </Card.Footer>
                           </Card> 
                        </Col>
                     </Row>
                    )) : props.renderNoResultsBlock
                  }  
    </>
   )
}
export default GoogleSearch;