import React from 'react';
import { Col, Row, Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormBlock from './FormBlock';
import DragDropCustom from './DragDropCustom';
import { HasPermission } from './HasPermission';


const AccordionForm = ({ data }) => {

    return (
        <>
            {data.map((item, i) =>
                <HasPermission key={i} permission={item.permission ? item.permission : ''}>
                    <Accordion key={i} defaultActiveKey={item.open ? i : ''} className="mb-4">
                        <Card key={'Card' + i} className="border-0">
                            <Card.Header className="border-0" >
                                <Row className="no-gutters">
                                    <Accordion.Toggle as='div' eventKey={i} className="d-content cursor-pointer Link-tabs text-dark pl-0  p-0 text-decoration-none " variant="link dark">
                                        <Col md={4} xs={10}>
                                            <h5 className="font-weight-bold"> {item.name} </h5>
                                        </Col>

                                        <Col md={7} xs={10} className="text-md-right text-center  pt-1 d-none d-md-block d-lg-block">
                                            <b>{item.label ? item.label : ''}</b>
                                        </Col>
                                        <Col md={1} xs={2} className="text-md-right p-0 px-md-3 pt-1">
                                            <FontAwesomeIcon className="text-grey nav-icons" icon="chevron-down" size="sm" />
                                        </Col>
                                    </Accordion.Toggle>
                                </Row>
                            </Card.Header>
                            <Accordion.Collapse eventKey={i} >
                                <Card.Body className='border-bottom-0 border-secondary'>
                                    {item.type === 'form' ?
                                        <FormBlock fields={item.fields} getData={(e) => item.func(e)} formData={item.formData} />

                                        : item.type === 'component' ?
                                            <><Col >{item.component}</Col>
                                                {item.component1 ? <Col>{item.component1}</Col> : ''}</>
                                            : item.fields.length > 0 ? <DragDropCustom data={item.fields} onDataChange={(e) => item.func(e)} editable={item.editable} isDragDisabled={item.isDragDisabled} rating={item.rating} /> : <p className='text-center'>No Rating Found.</p>}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </HasPermission>
            )}
        </>
    )
}
export default AccordionForm;
