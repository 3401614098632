import { ACCOUNT } from '../helpers/actionTypes';
import { Storage } from '../helpers/storage';

const initState = {
    isFetching: false,
    authenticated: false,
    loginData: Storage.getSession() ? Storage.getSession() : {},
    error: {},
    data: {}
}

export function auth(state, { payload, type, error }) {
    if (!state) {
        state = initState
    }
    switch (type) {
        case ACCOUNT.LOGIN.REQUEST:
        case ACCOUNT.REFRESH__TOKEN.REQUEST:
            return {
                ...state,
                isFetching: true,
                authenticated: false
            }
        case ACCOUNT.LOGIN.SUCCESS:
        case ACCOUNT.REFRESH__TOKEN.SUCCESS:
            return {
                ...state,
                authenticated: true,
                loginData: payload.data,
                isFetching: false,
            };
        case ACCOUNT.LOGIN.FAILURE:
            // showAlert('Invalid Credentials');
            return {
                ...state,
                authenticated: false,
                isFetching: false,
                error: error.response.data
            };
        case ACCOUNT.LOGOUT.SUCCESS:
            return {
                ...state,
                loginData: {},
                authenticated: false
            }
        case ACCOUNT.PASSWORD_FORGOT.REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case ACCOUNT.PASSWORD_FORGOT.SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: payload.data
            };
        case ACCOUNT.PASSWORD_FORGOT.FAILURE:
            return {
                ...state,
                isFetching: false,
                error: error,
            };
        case ACCOUNT.UPDATE_SESSION.SUCCESS:
            return {
                ...state,
                loginData: Storage.getSession()
            };
        default:
            return state;
    }

}