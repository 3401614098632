import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../actions/auth';
import * as userService from '../../services/user';
import { Storage } from '../../helpers/storage';

import logo from '../../assets/images/qratalogo.png';
import { Container, Row, Col, Form, Button, Spinner, Jumbotron,  Alert } from "react-bootstrap";

import { Link } from 'react-router-dom';
import AlertAndLoader from '../../shared/AlertAndLoader';
import * as alertActions from '../../actions/alert';
import { useQuery } from '../../helpers/utility';
// import Captcha from '../../shared/Captcha';

function LoginPage({ history }) {

    const [inputs, setInputs] = useState({ username: '', password: '' });
    const [validated, setValidated] = useState(false);

    const { username, password } = inputs;
    // const [captcha , setCaptcha ] = useState(false);
    const dispatch = useDispatch();

    // const validateCaptcha = useRef();
    const loggingIn = useSelector(state => state.auth.isFetching)
    const authenticated = useSelector(state => state.auth.authenticated)
    const loginData = useSelector(state => state.auth.loginData)
    const errors = useSelector(state => state.auth.error);
    const query = useQuery()

    // useEffect(() => {
    //     if(errors && errors.detail){
    //         Storage.setLoginState(false);
    //         setCaptcha(true);
    //     }else if(Storage.getLoginState() === false){
    //         setCaptcha(true);
    //     }
    // },[errors])

    const getLog = useCallback(() => {
        if(Storage.getSession()){
            userService.getLog(Storage.getSession().id).then(([response , error ]) => {
                if(response){
                    Storage.setLog(response.results[response.count - 1].id);
                }else if(error){
                    dispatch(alertActions.error(error.response));
                }
            })
        }
    },[dispatch])

    useEffect(() => {
        if (authenticated && loginData) {
            Storage.setSession(loginData);
            getLog();
            if(loginData.permissions.includes('qrata.front_office_access')){
                if(loginData.membership_plan) Storage.removeSearchCount();
                Storage.removeLoginState();
                localStorage.setItem("isExistingUser", true)
                let next = query.get('next') || '/home'
                history.push(next);
                // history.push('/home');
            }else {
            Storage.removeAll();
            dispatch(alertActions.error('Invalid Credentials.'));
            }
        }
    }, [authenticated, loginData, history , getLog , dispatch, query]);

    // on textfield change
    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }, []);
    
    // on form submit
    const handleSubmit = useCallback((event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if(inputs.username !== '' && inputs.password){
            if (form.checkValidity() === false) {
                event.stopPropagation();
            }
            setValidated(true);
            dispatch(authActions.login(inputs))
            /*if(captcha){
                if(form.checkValidity() && validateCaptcha.current.validated()){
                    dispatch(authActions.login(inputs)) 
                }else {
                    dispatch(alertActions.error('Please Enter the Valid Captcha Code.'))
                }
            }else {
                dispatch(authActions.login(inputs)) 
            }*/
            
        }else {
            setValidated(true);
        }
    }, [inputs, dispatch]);

    return (
        <>
            <AlertAndLoader />
            <Jumbotron className="d-flex align-items-center min-vh-100 mb-0 bg-grey-gradient">
                <Container>

                <Row className="justify-content-center">
                        <Col md={6}>
                            <a href="/home">   <img className="mb-2" width="300px" src={logo} alt="er" /></a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                    
                    <Col md={6} sm={12} className="bg-dark align-self-center px-5 py-2 text-white rounded shadow-custom shadow  " >
                            <Row className="text-center no-gutters my-4">
                      
                                <Col md={12}>

                                    {/* <h5 className="login-title"></h5> */}
                                </Col>
                            </Row>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                {errors.detail ? <Alert variant="danger">{errors.detail}</Alert> : null}
                      
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="12">
                                        User Name
                                    </Form.Label>
                                    <Col sm="12">
                                    <Form.Control type="text" autoComplete="username" placeholder="Enter username" name="username" required value={username} onChange={handleChange} />
                                    <small className="d-block pt-1">(Username) is case-sensitive.</small>
                                    <Form.Control.Feedback type="invalid">This field may not be blank.</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="12">
                                       Password
    </Form.Label>
                                    <Col sm="12">
                                    <Form.Control type="password" autoComplete="current-password" placeholder="Password" name="password" required value={password} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">This field may not be blank.</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                         
                                {/*{captcha ? <Captcha ref={validateCaptcha} login={true} styleCaptcha={'login-captcha'} /> : ''} */}
                                <Row className=" my-4">
                                    <Col md={8} className="text-left">
                                        <span ><Link className="text-white" to="/passwordForgot">Forgot Password ?</Link></span>
                                    </Col>
                                    <Col md={4} className="text-right">
                                        <Button className="btn-green" variant="primary" type="submit">
                                            {loggingIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                    Login
                                    </Button>
                                    </Col>
                                    {/* <span>If you don't have account then Register now . <Link to='/register'>Register</Link></span> */}
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </>
    );


}

export default LoginPage;