import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Container, Form, Row, Col, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as messageService from '../../services/message';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import { dateWithTimeFormat, Storage } from '../../helpers';
import FileField from '../../shared/formComponent/FileField'
const MessageModal = ({ data, isVisible, title, hide }) => {

  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [editData, setEditData] = useState([]);
  const [messageEnums, setMessageEnums] = useState([]);
  const [formData, setFormData] = useState({
    comment: '',
    user: Storage.getSession().id,
    attachment: undefined
  });
  const prevState = useRef(formData);

  useEffect(() => {
    dispatch(loaderActions.loader(true));
    messageService.getMessageEnums().then(([response, error]) => {
      if (response) {
        setMessageEnums(response);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch])

  const replyCommnets = useCallback(() => {
    dispatch(loaderActions.loader(true));
    messageService.getComment(data.id, data.first_name ? true: false ).then(([response, error]) => {
      if (response) {
        setEditData(response.comment);
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch, data.id, data.first_name])

  useEffect(() => {
    if (data) {
      replyCommnets()
    }
  }, [dispatch, replyCommnets, data])

  const displayMessageQuery = (value) => {
    if (messageEnums.length > 0) {
     const arr = messageEnums.filter((obj) => obj.value === value);
     return arr[0].label;
    }
  }

  const closeModal = useCallback(() => {
    setEditData({});
    hide();
  }, [hide]);

  const handleChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.name]: e.value
    });
  }, [formData])

  const saveCreateData = useCallback(() => {
    dispatch(loaderActions.loader(true));
    const form = new FormData();
    form.append('ask_by', formData.user);
    form.append('comment', formData.comment);
    form.append('is_member_query', data.first_name ? true: false);
    if (formData.attachment) {
      form.append('attachment', formData.attachment)
   };
    messageService.createComment(data.id, form).then(([response, error]) => {
      if (response) {
        setFormData(prevState.current);
        setValidated(false);
        dispatch(alertActions.success('Comment Added Successfully'));
        replyCommnets()
        // hide();
      } else if (error) {
        dispatch(alertActions.error(error.response));
      }
      dispatch(loaderActions.loader(false));
    })
  }, [dispatch, formData.user, data.id, formData.comment, formData.attachment, data.first_name, replyCommnets])

  const saveData = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      saveCreateData();
    }
  }, [saveCreateData])

  const openlink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <Container fluid>
      {isVisible ?
        <Modal show={isVisible} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Form noValidate validated={validated} onSubmit={saveData}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card className="border-0" >
                <Card.Body className='pb-0'>
                  <Row>
                    <Col md={4} className="mb-3">
                      <Card.Subtitle className="mb-2 ">
                        <span><small className="text-muted">Ticket Number</small></span>
                        <h4>{editData ? data.query_ticket : ''}</h4>
                      </Card.Subtitle>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Card.Text className="mb-2 ">
                        <span><small className="text-muted">Query</small></span>
                        <span className='d-block'>{data.query !== null ? displayMessageQuery(data.query) : ''}</span>
                      </Card.Text>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Card.Text className="mb-2">
                        <span><small className="text-muted">Email</small></span>
                        <span className='d-block'>{data ? data.email : ''}</span>
                      </Card.Text>
                    </Col>
                    {data.subject !== null ?
                      <Col md={12}>
                        <Card.Text className="mb-2">
                          <span><small className="text-muted" >Subject</small></span>
                          <span className='d-block'>{data.subject !== '' ? data.subject : ''}</span>
                        </Card.Text>
                      </Col> : ''}
                      {data.subject !== null ?
                      <Col md={12}>
                        <Card.Text className="mb-2">
                          <span><small className="text-muted" >Details</small></span>
                          <span className='d-block'>{data.details !== '' ? data.details : ''}</span>
                        </Card.Text>
                      </Col> : ''}
                  </Row>

                </Card.Body>
              </Card>

              <Col md={12}>
                <Card.Text className="mb-2">
                  <span><small className="text-muted" >Comments:</small></span>

                </Card.Text>
              </Col>
              <Card className="border-0" style={{ maxHeight: 'calc(60vh - 210px)', overflow: 'auto' }} >
                <Card.Body className='pt-0'>
                  <Row>
                    {editData.length > 0 ? editData.map((item, index) => (
                      <Col md={12} key={'comment'+index} className={editData.length - 1 === index ? 'py-2 position-relative ' : 'py-2 position-relative border border-top-0 border-right-0 border-left-0 border-bottom-primary'}>
                        <span className="guest-user font-weight-bold"><small className="font-weight-bold">{item.first_name + " " + item.last_name}</small></span>
                        <p className="mb-0" key={'reply' + index}>
                        {item.attachments.length > 0 ? 
                          <FontAwesomeIcon className='cursor-pointer btn-link' onClick={() => openlink(item.attachments[0].file_url)} icon='download' /> : ""
                          } 
                        {" " + item.comment}
                          <span className="position-absolute date-time text-light" ><small>{dateWithTimeFormat(item.created_on)}</small></span></p>
                      </Col>
                    )) : ''}
                  </Row>
                </Card.Body>
              </Card>

            </Modal.Body>
            <Modal.Footer>
              <Row className="w-100 no-gutters">
                <Col md={10} xs={9}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control type='textarea'  className="rounded-0" required placeholder='Reply type here...' name='comment' value={formData.comment} onChange={(e) => handleChange(e.target)} rows={1} />
                    <span className="attachment-icon text-right">
                  <FileField key="attachment" icon="download" value={formData.attachment} id="attachment" className="p-0" type="file"   fileFormat=".doc,.docx,.pdf,.jpg,.png,.jpeg" handleEvent={(e) => handleChange(e)} placeholder={"Select File"} name='attachment' />
              </span>
                  </Form.Group>
                </Col>
             
                <Col md={2} xs={3}>
                  <Button variant="primary" className="w-100 rounded-0" type="submit" ><FontAwesomeIcon size="lg" className="icon-green mr-2" icon="paper-plane" />Send</Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal>
        : null}
    </Container>
  )
}
export default MessageModal;