import React from 'react';
import Select from 'react-select';
import { REACT_SELECT_BASIC_CUSTOM_STYLE } from './SelectStyle';
import PropTypes from 'prop-types';

const SelectOption = ({ data, multi , value , handleEvent , placeholder , style , disabled}) => {
    return (
        <>
            <Select
                value={value}
                placeholder={placeholder}
                onChange={(value) => handleEvent(value)}
                options={data}
                styles={style}
                isMulti={multi}
                isDisabled={disabled}
            />
        </>
    );
}

SelectOption.propTypes = {
    data: PropTypes.array,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
    value : PropTypes.any , 
    placeholder : PropTypes.string,
    style : PropTypes.any,
    handleEvent: PropTypes.func
};

SelectOption.defaultProps = {
    data: [],
    multi: false,
    disabled:false,
    value : '', 
    placeholder : 'Select',
    style : REACT_SELECT_BASIC_CUSTOM_STYLE,
    handleEvent: PropTypes.func
};
export default SelectOption;