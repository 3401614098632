import React from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HasPermission } from '../HasPermission';
// import { validateInput } from '../../utility/Validators';


const SelectField = ({ label, id, permission, md, sm, lg, xs, xl, col, opts, placeholder, name, value, options, handleEvent }) => {
    const errorMessage = 'Please select  the ' + label;
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const data = { name, value };
        handleEvent(data);
    }

    return (
        <>
            <Col md={md} sm={sm} lg={lg} xs={xs} xl={xl}>
                <HasPermission permission={permission}>
                    <Form.Group controlId={id}>
                        {label ? <Form.Label >{label}</Form.Label> : ''}
                        <Form.Control as="select" name={name} {...opts} required size={col} value={value} onChange={(e) => handleChange(e)} >
                            <option value=''>{placeholder}</option>
                            {options.map((item, i) =>
                                <option value={item.value ? item.value : item.id} key={i}>{item.name}</option>
                            )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                    </Form.Group>
                </HasPermission>
            </Col>
        </>
    )
}

SelectField.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    col: PropTypes.string,
    opts: PropTypes.any,
    options: PropTypes.any,
    permission: PropTypes.any,
    md: PropTypes.number,
    sm: PropTypes.number,
    lg: PropTypes.number,
    xs: PropTypes.number,
    xl: PropTypes.number,
    handleEvent: PropTypes.func.isRequired
};

SelectField.defaultProps = {
    id: '',
    value: '',
    name: '',
    col: 'md',
    md: 12,
    sm: 12,
    lg: 12,
    xs: 12,
    xl: 12,
    placeholder: 'Select',
    permission: '',
    type: 'select',
    label: '',
    opts: {},
    options: []
};
export default SelectField;