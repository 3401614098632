import React  from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';

const Loader = ({ loading}) => {
  
  if (loading) {
    return (
      <Row>
        <Col className="loading">
          <Spinner className="page-loader" animation="border" variant="light" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    )
  } else {
    return null;
  }
}

export default Loader;