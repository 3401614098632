import { alertConstants } from "../helpers/actionTypes";

export function success(message) {
    return dispatch => { 
      dispatch ({
        type: alertConstants.SUCCESS, 
        message
      }) ;
    };
}

export function error(message) {
    return dispatch => { 
      dispatch ({
        type: alertConstants.ERROR, 
        message
      }) 
    };
}

export function info(message) {
  return dispatch => { 
    dispatch ({
      type: alertConstants.INFO, 
      message
    }) 
  };
}

export function warning(message) {
  return dispatch => { 
    dispatch ({
      type: alertConstants.WARNING, 
      message
    }) 
  };
}

export function clear() {
    return dispatch => { 
      dispatch ({
        type: alertConstants.CLEAR
      }) 
    };
}

