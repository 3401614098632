import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SearchField from '../../shared/SearchField';
import AssetDiscussionListing from './AssetDiscussionListing';


const AssetDiscussionMain = () => {
    const [search , setSearch] = useState('');

    return (
        <>
        <Container fluid className="px-0">
            <Row className="py-3">
                <Col md={8} className="mb-md-0 mb-3"><h2>Asset Discussion</h2></Col>
                <SearchField md={4} action={setSearch} />
            </Row>
            
            {/* <AssetDiscussionListing /> */}
            <AssetDiscussionListing search={search}/>
        </Container>
        </>
    )
}
export default AssetDiscussionMain;